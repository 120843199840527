import React, { useEffect, useRef } from 'react'
import { StoriesVideo, StoryCardLogoContainer, StoryCardLogoPosition, StoryCardTitle, StoryCardTitlePosition, StoryDetailsContainer, StoryDetailsHeading, StoryDetailsHeadingContainer, StoryDetailsInfoContainer, StoryDetailsInfoWrapper, StoryDetailsPosition, StoryDetailsSubHeading, StoryDetailsSubHeadingText } from '../style/stories.styles';
import { Layer } from '../../../global/components/layer';
import { Container, FlexBox, FlexBoxSA, FlexBoxSB, SandwichHalf, SandwichRealHalf, SubTitleMediumWhite } from '../../../global/style/global.styles';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { SpacerLeftRightSmall, SpacerTopBottomXXXL } from '../../../design/spacer/spacer';
import { ImgHolder } from '../../../global/components/imgHolder';
import { StoryServiceBlock } from './storyServiceBlock';
import { StoryLandingLink } from './storyLandingLink';
import { Stories } from '../../../assets/data/stories';
import { StoryHighlights } from './storyHighlights';
import { StoryProblem } from './storyProblem';
import { ClientInfo } from './clientInfo';
import { Grid } from '@mui/material';
import { StorySolution } from './storySolution';
import { StoryUSP } from './storyUSP';
import { StoryOutcome } from './storyOutcome';
import { StoryContext } from './storyContext';
import { MasteryRecommend } from '../../mastery/components/masteryRecommend';
import { Footer } from '../../../global/components/footer';

export const Story = (props) => {

  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  })

  return (
    <>
      <div className='card'>
        <StoriesVideo
          ref={videoRef}
          loop
          autoPlay
          muted
          playsInline
          poster={props.poster}>
          <source
            src={props.dp.vid}
            type="video/mp4"
          />
        </StoriesVideo>
        <Layer
          shade={"rgba(0,0,0,0.2)"}
          borderRadius={"1rem"}
        />
        <StoryCardTitlePosition>
          <StoryCardTitle>
            {props.title}
          </StoryCardTitle>
        </StoryCardTitlePosition>
        <StoryCardLogoPosition>
          <ImgHolder
            img={props.logo}
            width={160}
          />
        </StoryCardLogoPosition>
      </div>
      {
        props.index === props.active
        &&
        <StoryDetailsPosition style={{ left: props.width / 2 * 0.6 + (-props.width / 2) }}>
          <StoryDetailsContainer>
            <StoryDetailsInfoWrapper>
              <Container>
                <FlexBoxSB>
                  {
                    props.services.map((item, index) => (
                      <StoryServiceBlock
                        key={index}
                        mastery={item.mastery}
                        serviceName={item.serviceName}
                      />
                    ))
                  }
                  <StoryLandingLink
                    width={props.width}
                    href={props.landingPage}
                    target='_blank'
                    logo={props.logo}
                    storyLinkEnter={props.storyLinkEnter}
                    storyLinkLeave={props.storyLinkLeave}
                  />
                </FlexBoxSB>
              </Container>
              <FlexBoxSA>
                <StoryHighlights
                  width={props.width}
                  data={props.highlights}
                />
              </FlexBoxSA>
              <StoryContext
                width={props.width}
                context={props.context}
                client={props.client}
              />
              <StorySolution
                width={props.width}
                data={props.solution}
              />
              {
                props.outcome
                &&
                <StoryOutcome
                  width={props.width}
                  data={props.outcome}
                />
              }
              <StoryUSP
                width={props.width}
                data={props.usp}
              />
              <MasteryRecommend
                location={props.location}
                title="Get to know how we do what we do!"
                blockEntered={props.compEntered}
                blockLeave={props.compLeave}
              />
              <Footer
                variant="flipWhite"
                footerEntered={props.compEntered}
                footerLeave={props.compLeave}
                backToTopBtnEntered={props.compEntered}
                backToTopBtnLeave={props.compLeave}
                inputFieldEntered={props.compEntered}
                inputFieldLeft={props.compLeave}
              />
            </StoryDetailsInfoWrapper>
          </StoryDetailsContainer>
        </StoryDetailsPosition>
      }
    </>
  )
}

