import React, { useEffect, useState } from 'react'
import { AbsoluteCenter, FlexBoxSA, LoadingScreenWrapper } from '../style/global.styles'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { withStyles } from "@material-ui/core/styles";
import { ImgHolder } from './imgHolder';
import logoWhite from '../../assets/images/logoWhite.png'
import { SpacerTopBottomLarge, SpacerTopBottomXXXL } from '../../design/spacer/spacer';
import '../style/global.css'

// function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
//     return (
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <Box sx={{ width: '100%', mr: 1 }}>
//                 <LinearProgress variant="determinate" {...props} />
//             </Box>
//             <Box sx={{ minWidth: 35 }}>
//                 <Typography variant="body2" sx={{ color: "white" }}>{`${Math.round(
//                     props.value,
//                 )}%`}</Typography>
//             </Box>
//         </Box>
//     );
// }

// const StyledLinearProgress = withStyles({
//     colorPrimary: {
//         backgroundColor: "grey"
//     },
//     barColorPrimary: {
//         backgroundColor: "white"
//     }
// })(LinearProgress);

export const LoadingScreen = (props) => {

    document.body.style.overflow = 'hidden';

    setTimeout(() => {
        document.body.style.overflow = 'auto'
    }, 3000);

    // const [progress, setProgress] = useState(10);

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    //     }, 300);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    // const [progress, setProgress] = useState(0);

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((oldProgress) => {
    //             if (oldProgress === 100) {
    //                 return 0;
    //             }
    //             const diff = Math.random() * 10;
    //             return Math.min(oldProgress + diff, 100);
    //         });
    //     }, 100);

    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    return (
        <>
            <LoadingScreenWrapper>
                <AbsoluteCenter>
                    <Box sx={{ width: 100 }}>
                        <FlexBoxSA>
                            <div className='rotateAnim'>
                                <ImgHolder
                                    img={logoWhite}
                                    width={80}
                                    height={80}
                                />
                            </div>
                        </FlexBoxSA>
                        {/* <SpacerTopBottomXXXL />
                        <StyledLinearProgress
                            variant="determinate"
                            value={progress}
                        /> */}
                        {/* <LinearProgressWithLabel
                            value={progress}
                        /> */}
                    </Box>
                </AbsoluteCenter>
            </LoadingScreenWrapper>
        </>
    )
}