import React, { useEffect, useRef, useState } from 'react'
import { ExpertiseSectionContainer } from '../style/landing.styles'
import useIsVisible from '../../../global/components/useIsVisible';
import { AbsoluteBottomRight, AbsoluteCenter, AbsoluteCenterTop, Container } from '../../../global/style/global.styles';
import { Info3DElements } from '../../../global/components/info3DElements';
import { Alert, AlertTitle, Button, CircularProgress, IconButton, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useOnScreen } from '../../../global/components/useOnScreen';
import ExpertiseSpline from './expertiseSpline'

export const ExpertiseSection = (props) => {

    // console.log(window.scrollY)

    const [loading, setLoading] = useState(true)
    const expertiseRef = useRef();
    const isVisible = useIsVisible(expertiseRef);

    const expertiseSplineRef = useRef();
    const isOnScreen = useOnScreen(expertiseSplineRef);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 3000);
    }, [])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>

            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    useEffect(() => {
        if (isOnScreen) {
            setTimeout(() => {
                setLoading(false)
            }, 2000);
        }
    }, [isOnScreen])

    return (
        <>
            <ExpertiseSectionContainer
                onMouseEnter={props.expertiseSplineEntered}
                onMouseLeave={props.expertiseSplineLeave}
                ref={expertiseSplineRef}>
                {
                    loading
                    &&
                    <AbsoluteCenterTop>
                        <Container>
                            <CircularProgress 
                                color='inherit'
                            />
                        </Container>
                    </AbsoluteCenterTop>
                }
                {/* {window.scrollY > 1400 && <ExpertiseSpline />} */}
                {/* {isVisible && <ExpertiseSpline />} */}
                {
                    isOnScreen
                    &&
                    <ExpertiseSpline
                        width={props.width}
                    />
                }
                <AbsoluteCenter>
                    <div ref={expertiseRef} />
                </AbsoluteCenter>
                {/* <Info3DElements /> */}
            </ExpertiseSectionContainer>
            {
                props.width < 850
                &&
                isVisible
                &&
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    action={action}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}>
                    <Alert severity="info" onClose={handleClose}>
                        <AlertTitle>3D Element</AlertTitle>
                        Use 2 fingers to interact
                    </Alert>
                </Snackbar>
            }
        </>
    )
}
