import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../../global/components/screenSize';
import Lottie from 'react-lottie';
import notFoundGif from '../../assets/animations/404page.json'
import { Navbar } from '../../global/components/navigation/navbar';
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { AbsoluteCenter } from '../../global/style/global.styles';
import { Button } from '@mui/material';

const NotFound = (props) => {

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorVariant, setCursorVariant] = useState("default")

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove)
    }

    setCursorVariant("default")
  }, [])

  const variants = {
    default: {
      x: mousePosition.x - 10,
      y: mousePosition.y - 10
    },
    diffCursor: {
      width: 100,
      height: 100,
      x: mousePosition.x - 50,
      y: mousePosition.y - 50,
      backgroundColor: "white",
      mixBlendMode: "difference"
    },
    normalCursor: {
      width: 0,
      height: 0,
    }
  }

  const compEntered = () => setCursorVariant("diffCursor")
  const compLeave = () => setCursorVariant("default")
  const normalCompEntered = () => setCursorVariant("normalCursor")

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFoundGif,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <NotFoundWrapper style={{ backgroundColor: "white" }}>
      <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
        <Navbar
          color="black"
          logoEntered={compEntered}
          logoLeave={compLeave}
          navBarEntered={compEntered}
          navBarLeave={compLeave}
        />
      </div>
      <AbsoluteCenter>
        <Lottie
          options={defaultOptions}
        />
      </AbsoluteCenter>
      <a
        href='javascript:history.back()'
        style={{
          position: "absolute",
          left: "50%",
          bottom: "20%",
          transform: "translate(-50%,-50%)",
          textDecoration: "none"
        }}>
        <a href='/' style={{ textDecoration: "none" }}>
          <Button
            variant='contained'
            color='warning'
            size='large'
            onMouseEnter={compEntered}
            onMouseLeave={compLeave}>
            Go Home
          </Button>
        </a>
      </a>
      {
        props.width > 850
        &&
        <motion.div
          style={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: "black",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 2,
            pointerEvents: "none"
          }}
          variants={variants}
          animate={cursorVariant}
        />
      }
    </NotFoundWrapper>
  )
}

const NotFoundWrapper = styled.div`
    position: relative;
    height: 100vh;
`;

export default NotFound