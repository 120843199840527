import React, { useState } from 'react'
import { ServiceLinkBtnContainer } from '../style/services.styles'
import { AbsoluteCenter } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import arrowLinkB from '../../../assets/icons/arrowLink-b.png'
import arrowLinkW from '../../../assets/icons/arrowLink-w.png'

export const ServiceLinkBtn = (props) => {

    const [hover, setHover] = useState(false)

    return (
        <>
            <ServiceLinkBtnContainer onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <AbsoluteCenter>
                    <ImgHolder
                        img={hover ? arrowLinkW : arrowLinkB}
                        width={32}
                    />
                </AbsoluteCenter>
            </ServiceLinkBtnContainer>
        </>
    )
}
