import { Grid } from '@mui/material'
import React from 'react'
import { Container, FlexBox, FlexBoxSE, FlexColumn, FlexColumnSE, RelativeContainer, SandwichHalf } from '../../../global/style/global.styles'
import { AboutHeroHScrollContainer, AboutTagline, AboutTaglineContainer, AboutTaglinesPosition, AboutVisionContainer, AboutVisionContainerPosition, AboutVisionLeftContainer, AboutVisionSubTitle, AboutVisionTitle } from '../style/about.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { SpacerLeftRightMedium, SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import mtEverest from '../../../assets/images/mtEverestv2.png'
import { Layer } from '../../../global/components/layer'

export const AboutVision = (props) => {
    return (
        <AboutVisionContainer>
            <Grid container>
                {
                    props.width > 850
                    &&
                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12}
                        style={{ backgroundColor: "#585858" }}>
                        <AboutVisionLeftContainer>
                            <div>
                                <AboutVisionTitle>
                                    MISSION
                                </AboutVisionTitle>
                                <SpacerTopBottomMedium />
                                <AboutVisionSubTitle>
                                    Empowering growth and quality of life through cutting edge digital excellence
                                </AboutVisionSubTitle>
                            </div>
                            <SandwichHalf />
                            <div>
                                <AboutVisionTitle>
                                    VISION
                                </AboutVisionTitle>
                                <SpacerTopBottomMedium />
                                <AboutVisionSubTitle>
                                    Pioneering tomorrow's digital landscapes today
                                </AboutVisionSubTitle>
                            </div>
                            <SandwichHalf />
                            <div>
                                <AboutVisionTitle>
                                    VALUES
                                </AboutVisionTitle>
                                <SpacerTopBottomMedium />
                                <AboutVisionSubTitle>
                                    Innovation, Quality, User-Focused
                                </AboutVisionSubTitle>
                            </div>
                        </AboutVisionLeftContainer>
                    </Grid>
                }
                <Grid item xl={5} lg={5} md={5} sm={12} xs={12}
                    style={{ backgroundColor: "white" }}>
                    <RelativeContainer>
                        <ImgHolder
                            img={mtEverest}
                            width="100%"
                            height="100vh"
                        />
                        {
                            props.width < 850
                            &&
                            <>
                                <Layer
                                    shade="rgba(0, 0, 0, 0.4)"
                                />
                                <AboutVisionContainerPosition>
                                    <AboutVisionLeftContainer>
                                        <FlexColumnSE style={{ height: "100vh" }}>
                                            <div>
                                                <AboutVisionTitle>
                                                    MISSION
                                                </AboutVisionTitle>
                                                <SpacerTopBottomMedium />
                                                <AboutVisionSubTitle>
                                                    Empowering growth and quality of life through cutting edge digital excellence
                                                </AboutVisionSubTitle>
                                            </div>
                                            <div>
                                                <AboutVisionTitle>
                                                    VISION
                                                </AboutVisionTitle>
                                                <SpacerTopBottomMedium />
                                                <AboutVisionSubTitle>
                                                    Pioneering tomorrow's digital landscapes today
                                                </AboutVisionSubTitle>
                                            </div>
                                            <div>
                                                <AboutVisionTitle>
                                                    VALUES
                                                </AboutVisionTitle>
                                                <SpacerTopBottomMedium />
                                                <AboutVisionSubTitle>
                                                    Innovation, Quality, User-Focused
                                                </AboutVisionSubTitle>
                                            </div>
                                        </FlexColumnSE>
                                    </AboutVisionLeftContainer>
                                </AboutVisionContainerPosition>
                            </>
                        }
                    </RelativeContainer>
                </Grid>
            </Grid>
        </AboutVisionContainer>
    )
}
