import Spline from '@splinetool/react-spline'
import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Button, IconButton, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const HeroSpline = (props) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 3000);
    }, [])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>

            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            {
                props.width > 1800
                    ?
                    <Spline
                        scene="https://prod.spline.design/1gry6bOh4uCUWGSa/scene.splinecode"
                        onLoad={props.heroSplineLoading}
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            width: 500,
                            height: 500,
                            zIndex: 1,
                            cursor: "grab"
                        }}
                    />
                    :
                    props.width > 850
                        ?
                        <Spline
                            scene="https://prod.spline.design/cPuGGBdxXoXMQ0Ll/scene.splinecode"
                            onLoad={props.heroSplineLoading}
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                                width: 400,
                                height: 400,
                                zIndex: 1,
                                cursor: "grab"
                            }}
                        />
                        :
                        props.width > 370
                            ?
                            <Spline
                                scene="https://prod.spline.design/UmcrGxqq-JPh0MoN/scene.splinecode"
                                onLoad={props.heroSplineLoading}
                                style={{
                                    position: "absolute",
                                    top: "42%",
                                    left: "50%",
                                    transform: "translate(-50%,-50%)",
                                    width: 400,
                                    height: 400,
                                    zIndex: 1,
                                    cursor: "grab"
                                }}
                            />
                            :
                            <Spline
                                scene="https://prod.spline.design/14t-8-K3PZAeUeYD/scene.splinecode"
                                onLoad={props.heroSplineLoading}
                                style={{
                                    position: "absolute",
                                    top: "42%",
                                    left: "50%",
                                    transform: "translate(-50%,-50%)",
                                    width: 300,
                                    height: 300,
                                    zIndex: 1,
                                    cursor: "grab"
                                }}
                            />
            }
            {
                props.width < 850
                &&
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    action={action}
                    sx={{ height: "100%" }}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}>
                    <Alert
                        severity="info"
                        onClose={handleClose}>
                        <AlertTitle>3D Element</AlertTitle>
                        Use 2 fingers to interact
                    </Alert>
                </Snackbar>
            }
        </>
        // <div
        //     style={{
        //         position: "absolute",
        //         top: "50%",
        //         left: "50%",
        //         transform: "translate(-50%,-50%)",
        //         width: 400,
        //         height: 400,
        //         zIndex: 1,
        //         backgroundColor: "red"
        //     }}
        // />
    )
}

export default HeroSpline