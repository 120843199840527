import React from 'react'
import { EcommerceServiceTitle, ServiceWrapper, ServicesWrapper, WebsiteServiceTitle, WebsiteServiceWrapper } from '../style/services.styles'
import { Sandwich } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { Layer } from '../../../global/components/layer'
import { ServiceLinkBtn } from './serviceLinkBtn'
import ecomMob from '../../../assets/images/ecomMob.png'
import ecomProd from '../../../assets/images/ecomProd.png'

export const EcommerceService = (props) => {
    return (
        <Sandwich>
            <ServiceWrapper style={{ height: props.width > 850 ? 1252 : props.width > 650 ? 1100 : props.width > 450 ? 800 : props.width > 370 ? 700 : 660 }}>
                <EcommerceServiceTitle
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: 0,
                        transform: "translate(-50%, 0)",
                        width: props.width > 850 ? 810 : props.width > 650 ? 700 : props.width > 450 ? 510 : props.width > 370 ? 364 : 325
                    }}>
                    ECOMMERCE
                </EcommerceServiceTitle>
                <EcommerceServiceTitle
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: props.width > 850 ? 152 : props.width > 650 ? 136 : props.width > 450 ? 96 : props.width > 370 ? 77 : 62,
                        transform: "translate(-50%, 0)",
                        width: props.width > 850 ? 770 : props.width > 650 ? 670 : props.width > 450 ? 480 : props.width > 370 ? 345 : 310,
                        zIndex: 3
                    }}>
                    PLATFORMS
                </EcommerceServiceTitle>
                <div style={{
                    position: "absolute",
                    left: 0,
                    top: props.width > 650 ? 230 : 160,
                    zIndex: 2
                }}>
                    <ImgHolder
                        img={ecomMob}
                        width={props.width > 850 ? 931 : props.width > 650 ? 720 : props.width > 450 ? 500 : 420}
                    />
                </div>
                <div style={{
                    position: "absolute",
                    right: props.width > 1100 ? 0 : props.width > 650 ? -200 : props.width > 450 ? -300 : -100,
                    top: props.width > 650 ? 550 : 360
                }}>
                    <ImgHolder
                        img={ecomProd}
                        width={props.width > 850 ? 1100 : props.width > 650 ? 900 : props.width > 450 ? 700 : props.width > 370 ? 540 : 480}
                    />
                </div>
                <a
                    href='/mastery/design'
                    target='_blank'
                    style={{
                        position: "absolute",
                        right: props.width > 450 ? 112 : 40,
                        bottom: 0
                    }}
                    onMouseEnter={props.serviceLinkEntered}
                    onMouseLeave={props.serviceLinkLeave}>
                    <ServiceLinkBtn />
                </a>
            </ServiceWrapper>
        </Sandwich>
    )
}
