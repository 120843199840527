import React from 'react'
import { StoryCardLogoPosition, WorkMobBlockContainer, WorkMobBlockFocusContainer } from '../style/stories.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { AbsoluteCenter, FlexColumn } from '../../../global/style/global.styles'
import { FeaturedBlogTitle } from '../../landing/style/landing.styles'

export const WorkMobBlock = (props) => {
    return (
        <>
            <WorkMobBlockContainer href={`/work/${props.data?.id}`} style={{ textDecoration: 'none' }}>
                {/* <Layer
                    shade={"rgba(0, 0, 0, 0.3)"}
                /> */}
                <ImgHolder
                    img={props.data?.client?.banner.banner450}
                    width="100%"
                    height="100%"
                    borderRadius={8}
                />
                <AbsoluteCenter>
                    <WorkMobBlockFocusContainer>
                        <FlexColumn style={{ height: "100%", justifyContent: "flex-end" }}>
                            <FeaturedBlogTitle>
                                {props.data?.name}
                            </FeaturedBlogTitle>
                        </FlexColumn>
                    </WorkMobBlockFocusContainer>
                </AbsoluteCenter>
                <StoryCardLogoPosition>
                    <ImgHolder
                        img={props.data?.client?.logo}
                        width={120}
                    />
                </StoryCardLogoPosition>
            </WorkMobBlockContainer>
        </>
    )
}
