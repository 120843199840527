import React from 'react'
import { FeaturedBlogBlock } from './featuredBlogBlock'

export const FeaturedBlogSlider = (props) => {

    return (
        <>
            <div className='slider'>
                <div className='slideTrack'>
                    <div className='slide'>
                        {
                            props.content?.map((item, index) => (
                                <FeaturedBlogBlock
                                    key={index}
                                    id={item.id}
                                    thumbnail={item.dp?.filter((x) => x.type === "dp23")[0].url}
                                    title={item.heading}
                                    blogPostEntered={props.blogPostEntered}
                                    blogPostLeave={props.blogPostLeave}
                                />
                            ))
                        }
                    </div>
                    {/* Duplicated */}
                    <div className='slide'>
                        {
                            props.content?.map((item, index) => (
                                <FeaturedBlogBlock
                                    key={index}
                                    id={item.id}
                                    thumbnail={item.dp?.filter((x) => x.type === "dp23")[0].url}
                                    title={item.heading}
                                    blogPostEntered={props.blogPostEntered}
                                    blogPostLeave={props.blogPostLeave}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}