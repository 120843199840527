import React from 'react'
import { BodyWhite, Container, FlexBoxSA, RelativeContainer, SandwichHalf, Squeezer } from '../../../global/style/global.styles'
import { MasterySectionTitle } from '../style/mastery.styles'
import { Grid } from '@mui/material'
import { ImgHolder } from '../../../global/components/imgHolder'
import instagramTaImg from '../../../assets/images/Component 65.png'
import linkedinTaImg from '../../../assets/images/Component 77.png'
import lineTaImg from '../../../assets/images/Component 73.png'
import xTaImg from '../../../assets/images/Component 74.png'
import facebookTaImg from '../../../assets/images/Component 75.png'
import pintrestTaImg from '../../../assets/images/Component 76.png'
import { SpacerTopBottomLarge, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { MarkHeading } from './markHeading'

export const TargetAudience = (props) => {
    return (
        <>
            {/* <MasterySectionTitle>
                    Do you wish to easily reach your target audience? We got you covered.
                </MasterySectionTitle> */}
            <RelativeContainer style={{ height: 89 }}>
                <MarkHeading
                    heading="Target Audience"
                />
            </RelativeContainer>
            <SpacerTopBottomXXXL />
            <Squeezer>
                <Grid container spacing={8} alignItems="baseline">
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <FlexBoxSA>
                            <ImgHolder
                                img={instagramTaImg}
                                width={300}
                            />
                        </FlexBoxSA>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <FlexBoxSA>
                            <ImgHolder
                                img={facebookTaImg}
                                width={300}
                            />
                        </FlexBoxSA>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <FlexBoxSA>
                            <ImgHolder
                                img={linkedinTaImg}
                                width={300}
                            />
                        </FlexBoxSA>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <FlexBoxSA>
                            <ImgHolder
                                img={pintrestTaImg}
                                width={300}
                            />
                        </FlexBoxSA>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <FlexBoxSA>
                            <ImgHolder
                                img={xTaImg}
                                width={300}
                            />
                        </FlexBoxSA>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <FlexBoxSA>
                            <ImgHolder
                                img={lineTaImg}
                                width={300}
                            />
                        </FlexBoxSA>
                    </Grid>
                </Grid>
                <SandwichHalf />
                <BodyWhite style={{ color: "rgba(226, 226, 227, 0.6)" }}>
                    *Please note the above refresentation is only for marketing purposes. The target audience with respect to social media platforms may vary according to the product or service
                </BodyWhite>
            </Squeezer>
        </>
    )
}
