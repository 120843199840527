import React, { useEffect, useRef, useState } from 'react'
import { Hero3DIconPosition, HeroCTAContainer, HeroCTAHeading, HeroCTAPosition, HeroCTAText, HeroSectionContainer, HeroTitlePosition, HeroVideo, InterBoxPosition } from '../style/landing.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import heroBg from "../../../assets/images/heroBg.png"
import { AbsoluteBottomRight, AbsoluteCenter, AbsoluteCenterBottom, Caption, CaptionWhiteMedium, Container, FlexBox, RelativeContainer, SubBody, SubBodyMediumWhite } from '../../../global/style/global.styles'
import { Navbar } from '../../../global/components/navigation/navbar'
import impasseTitle from "../../../assets/icons/impasseTitle.png"
import { SpacerRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import { HeroCTABtn } from './heroCTABtn'
import { InfoEdge } from '../../../global/components/infoEdge'
import { Icon3D } from '../../../global/components/icon3D'
import { Info3DElements } from '../../../global/components/info3DElements'
import { ScrollIndicator } from '../../../global/components/scrollIndicator'
import { Alert, AlertTitle, Button, IconButton, Snackbar, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import interactAnim from "../../../assets/animations/Interact.json"
import Lottie from 'react-lottie'
import { useOnScreen } from '../../../global/components/useOnScreen'
import HeroSpline from '../components/heroSpline'

export const HeroSection = (props) => {

    const [showInfoMenu, setShowInfoMenu] = useState(false)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: interactAnim,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const heroSplineRef = useRef();
    const isOnScreen = useOnScreen(heroSplineRef);

    return (
        <>
            <HeroSectionContainer ref={heroSplineRef}>
                <Navbar
                    color="black"
                    logoEntered={props.logoEntered}
                    logoLeave={props.logoLeave}
                    navBarEntered={props.navBarEntered}
                    navBarLeave={props.navBarLeave}
                />
                {
                    isOnScreen
                    &&
                    <div
                        onMouseEnter={props.heroSplineEntered}
                        onMouseLeave={props.heroSplineLeave}
                    >
                        <HeroSpline
                            heroSplineLoading={props.heroSplineLoading}
                            width={props.width}
                        />
                    </div>
                }
                <AbsoluteCenter>
                    <ImgHolder
                        img={heroBg}
                        width={props.width * 0.60}
                    />
                </AbsoluteCenter>
                <HeroTitlePosition>
                    <ImgHolder
                        img={impasseTitle}
                        width={props.width > 370 ? 164 : 140}
                    />
                </HeroTitlePosition>
                <HeroCTAPosition>
                    <HeroCTAContainer>
                        <HeroCTAHeading>
                            Inspiring Innovations.
                        </HeroCTAHeading>
                        <SpacerTopBottomLarge />
                        <HeroCTAText>
                            We craft modern digital experiences,
                        </HeroCTAText>
                        <HeroCTAText>
                            enhanced with
                        </HeroCTAText>
                        <HeroCTAText>
                            sublime digital marketing,
                        </HeroCTAText>
                        <HeroCTAText>
                            to reach your target audience
                        </HeroCTAText>
                        <SpacerTopBottomLarge />
                        <HeroCTABtn
                            ctaBtnEntered={props.ctaBtnEntered}
                            ctaBtnLeave={props.ctaBtnLeave}
                            link="/contact"
                        />
                    </HeroCTAContainer>
                </HeroCTAPosition>
                {
                    props.width > 650
                    &&
                    <ScrollIndicator />
                }
                {/* <InfoEdge
                    bottom={64}
                    right={showInfoMenu ? -16 : -270}
                    infoIcon={dIcon}
                    infoMenuEntered={props.infoMenuEntered}
                    infoMenuLeave={props.infoMenuLeave}
                    infoMenuClicked={() => setShowInfoMenu(!showInfoMenu)}
                >
                    <SubBodyMediumWhite>
                        Some info
                    </SubBodyMediumWhite>
                </InfoEdge> */}
                {/* <Hero3DIconPosition>
                    <Icon3D />
                </Hero3DIconPosition> */}
                {/* <Info3DElements /> */}
                {
                    props.width > 850
                    &&
                    <AbsoluteBottomRight style={{ padding: 80 }}>
                        <Tooltip title="The cube is an interactable 3D element">
                            <RelativeContainer>
                                <Lottie
                                    options={defaultOptions}
                                    width={40}
                                    height={40}
                                    speed={0.8}
                                    isClickToPauseDisabled={true}
                                />
                                {/* <AbsoluteCenter>
                        <Cube3DText>
                            3D
                        </Cube3DText>
                    </AbsoluteCenter> */}
                            </RelativeContainer>
                        </Tooltip>
                    </AbsoluteBottomRight>
                }
            </HeroSectionContainer>
        </>
    )
}
