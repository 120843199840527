import { Grid } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { Container, FlexBox, Sandwich, SandwichHalf, SubTitle, Title, TitleSemiBold } from '../../../global/style/global.styles'
import { InspirationSectionWrapper, MasterySectionSubTitle, MasterySectionTitle, MasteryVideo } from '../style/mastery.styles'
import { SpacerTopBottomLarge, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { ImgHolder } from '../../../global/components/imgHolder'
import spaceImg from '../../../assets/images/jamesWebb.jpg'
import babyHandImg from '../../../assets/images/babyHand.jpg'

export const InspirationSection = (props) => {

    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

    return (
        <>
            <Container>
                <Grid container alignItems="center" spacing={8}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        {
                            props.width < 850
                            &&
                            <>
                                <MasterySectionTitle>
                                    Inspiration,
                                    <br />
                                    the Cornerstone of Great Design
                                </MasterySectionTitle>
                                <SpacerTopBottomXXXL />
                            </>
                        }
                        <FlexBox style={{ justifyContent: props.width > 650 ? "right" : "center" }}>
                            <ImgHolder
                                img={babyHandImg}
                                width={300}
                                height={300}
                                borderRadius={4}
                            />
                        </FlexBox>
                        {
                            props.width > 850
                                ?
                                <SandwichHalf />
                                :
                                <SpacerTopBottomXXXL />
                        }
                        <FlexBox style={{ justifyContent: props.width > 650 ? "normal" : "center" }}>
                            <MasteryVideo
                                ref={videoRef}
                                loop
                                autoPlay
                                muted
                                playsInline>
                                <source
                                    src={"https://res.cloudinary.com/dz3yljetu/video/upload/v1707805368/bloomingFlowers_sokwhc.mp4"}
                                    type="video/mp4"
                                />
                            </MasteryVideo>
                            {/* <div
                                style={{
                                    backgroundColor: "red",
                                    width: 300,
                                    height: 300
                                }}
                            /> */}
                        </FlexBox>
                        {
                            props.width > 850
                                ?
                                <SandwichHalf />
                                :
                                <SpacerTopBottomXXXL />
                        }
                        <FlexBox style={{ justifyContent: props.width > 650 ? "right" : "center" }}>
                            <ImgHolder
                                img={spaceImg}
                                width={300}
                                height={300}
                                borderRadius={4}
                            />
                        </FlexBox>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            {
                                props.width > 850
                                &&
                                <>
                                    <MasterySectionTitle>
                                        Inspiration,
                                        <br />
                                        the Cornerstone of Great Design
                                    </MasterySectionTitle>
                                    <SpacerTopBottomLarge />
                                </>
                            }
                            <MasterySectionSubTitle style={{ textAlign: props.width > 850 ? "justify" : "left" }}>
                                In the vast tapestry of existence, inspiration reveals itself across diverse dimensions. It requires a heightened awareness, an awakening of the senses, to explore the tranquil recesses of the mind where the intricate ballet of ideas gracefully unfolds.
                                <br />
                                Much like a lingering melody in the hushed air, the resonance of dreams under the moonlit night, or the subtle fragrance emanating from blossoming flowers, inspiration manifests in the spaces between heartbeats and the rhythmic convergence of the soul.
                                <br />
                                It is a kaleidoscope of colors within the mind's eye, a cosmic dance of creativity, and a celestial rhyme that transcends the boundaries of space and time. In its essence, inspiration is a force that defies temporal constraints, weaving through the fabric of existence to illuminate the path of profound creativity and thought.
                            </MasterySectionSubTitle>
                            {/* <MasterySectionSubTitle style={{ textAlign: "right" }}>
                                Inspiration can be found in all aspects of life.
                                <br />
                                One has to awaken all their senses, and seek out the quiet corner of one’s mind where the delicate dance of ideas diffuse.
                                <br />
                                It is like a melody lingering in the silent air, the echo of dreams in the moonlit night, it can be found in the fragrance of blooming flowers, in the silence between heartbeats, in the rhythm where the soul meets.
                                <br />
                                It is a kaleidoscope of colors in the mind's eye, a cosmic dance, a celestial rhyme.
                                <br />
                                Inspiration, transcends space and time
                            </MasterySectionSubTitle> */}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
