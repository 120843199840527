import React, { useState } from 'react'
import { FlexBoxSB } from '../../style/global.styles'
import { useLocation, useNavigate } from 'react-router-dom'
import useWindowDimensions from '../screenSize'
import { IconHolder } from '../iconHolder'
import NavDrawer from './navDrawer'
import { NavbarContainer } from './navigation.styles'
import { LogoBuild } from '../logoBuild'

export const Navbar = (props) => {

  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [scroll, setScroll] = useState(false)

  return (
    <NavbarContainer>
      <FlexBoxSB>
        <LogoBuild
          color={props.variant === "altWB" ? "white" : props.color}
          width={40}
          height={40}
          logoEntered={props.logoEntered}
          logoLeave={props.logoLeave}
        />
        <NavDrawer
          navBarEntered={props.navBarEntered}
          navBarLeave={props.navBarLeave}
          color={props.color}
          width={width}
          location={location}
        />
      </FlexBoxSB>
    </NavbarContainer>
  )
}
