import React from 'react'
import AnimatedCursor from "react-animated-cursor"

export const CustomCursor = (props) => {
    return (
        <AnimatedCursor
            innerSize={16}
            outerSize={16}
            color='0, 0, 0'
            outerAlpha={0.2}
            innerScale={0.7}
            outerScale={5}
            clickables={[
                'a',
                'input[type="text"]',
                'input[type="email"]',
                'input[type="number"]',
                'input[type="submit"]',
                'input[type="image"]',
                'label[for]',
                'select',
                'textarea',
                'button',
                '.link',
                {
                    target: '.custom',
                    options: {
                        innerSize: 12,
                        outerSize: 12,
                        color: '255, 255, 255',
                        outerAlpha: 0.3,
                        innerScale: 0.7,
                        outerScale: 5
                    }
                }
            ]}
        />
    )
}
