import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { NavSection } from './navSection';
import { NavIconBuild } from './navIconBuild';
import { FlexColumnSA } from '../../style/global.styles';

export default function NavDrawer(props) {

    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'right' ? 'auto' : 250 }}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <NavSection
                closeDrawer={toggleDrawer(anchor, false)}
                width={props.width}
                location={props.location}
            />
        </Box>
    );

    return (
        <div>
            {['right'].map((anchor, index) => (
                <React.Fragment key={index}>
                    <div
                        onClick={toggleDrawer(anchor, true)}
                        onMouseEnter={props.navBarEntered}
                        onMouseLeave={props.navBarLeave}
                        style={{ cursor: "pointer", height: 40 }}
                    >
                        <FlexColumnSA style={{ height: "100%" }}>
                            <NavIconBuild
                                color={props.color}
                            />
                        </FlexColumnSA>
                    </div>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        transitionDuration={{ enter: 800, exit: 300 }}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}