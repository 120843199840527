import React, { useContext, useRef, useState } from 'react'
import { ContactFormInput, ContactFormLabel, ContactFormSubLabel, ContactFormTextArea, ContactFormV2Container } from '../style/contact.styles'
import { SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { FlexBox } from '../../../global/style/global.styles'
import { Alert, Button, IconButton, Snackbar } from '@mui/material'
import { ArchiveContext } from '../../../context/archive/archive.provider'
import { ApiContext } from '../../../context/api/api.provider'
import emailjs from '@emailjs/browser';
import CloseIcon from '@mui/icons-material/Close';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import '../style/contact.css'
import { addDoc } from 'firebase/firestore'

export const ContactFormV2 = (props) => {

    const form = useRef();

    const { leadsCollectionRef, loading, setLoading } = useContext(ApiContext);
    const {
        name,
        setName,
        phone,
        setPhone,
        email,
        setEmail,
        comName,
        setComName,
        req,
        setReq
    } = useContext(ArchiveContext);

    const notifyCXO = () => {

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>

            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const handleSubmit = async () => {
        setLoading(true);

        const data = {
            name: name,
            phone: phone,
            email: email,
            comName: comName,
            req: req,
            captured: props.location.pathname,
            createdAt: new Date()
        }

        await addDoc(leadsCollectionRef, data);

        setLoading(false);
        notifyCXO(data);
        setName("");
        setEmail("");
        setPhone("");
        setReq("");
        setComName("");
        handleClick();
    }

    return (
        <>
            <ContactFormV2Container>
                <FlexBox style={{ justifyContent: props.width > 850 ? "right" : "left" }}>
                    <form ref={form} style={{ width: props.width > 850 ? "inherit" : "100%" }}>
                        <ContactFormLabel>
                            NAME*
                        </ContactFormLabel>
                        <SpacerTopBottomLarge />
                        <ContactFormInput
                            name='name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <SpacerTopBottomXXXL />
                        <FlexBox>
                            <ContactFormLabel>
                                PHONE
                            </ContactFormLabel>
                            <SpacerLeftRightSmall />
                            <ContactFormSubLabel>
                                (quicker response)
                            </ContactFormSubLabel>
                        </FlexBox>
                        <SpacerTopBottomMedium />
                        {/* <ContactFormInput
                            style={{ width: "100%", height: 40 }}
                            name='phone'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        /> */}
                        <PhoneInput
                            defaultCountry="IN"
                            keyboardType="phone-pad"
                            name="phone"
                            value={phone}
                            onChange={setPhone} />
                        <SpacerTopBottomXL />
                        <ContactFormLabel>
                            EMAIL*
                        </ContactFormLabel>
                        <SpacerTopBottomMedium />
                        <ContactFormInput
                            style={{ width: "100%", height: 40 }}
                            name='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <SpacerTopBottomXXXL />
                        <FlexBox>
                            <ContactFormLabel>
                                COMPANY
                            </ContactFormLabel>
                            <SpacerLeftRightSmall />
                            <ContactFormSubLabel>
                                (optional)
                            </ContactFormSubLabel>
                        </FlexBox>
                        <SpacerTopBottomLarge />
                        <ContactFormInput
                            name="comName"
                            value={comName}
                            onChange={(e) => setComName(e.target.value)}
                        />
                        <SpacerTopBottomXXXL />
                        <ContactFormLabel>
                            REQUIREMENTS
                        </ContactFormLabel>
                        <SpacerTopBottomLarge />
                        <ContactFormTextArea
                            rows={5}
                            name="req"
                            value={req}
                            onChange={(e) => setReq(e.target.value)}
                        />
                        <SpacerTopBottomXXXL />
                        <Button
                            variant='contained'
                            color='success'
                            fullWidth
                            size='large'
                            sx={{
                                textTransform: "none",
                                width: props.width > 1100 ? 400 : props.width > 850 ? 350 : "100%",
                                "&:disabled": {
                                    backgroundColor: '#6E6D70'
                                }
                            }}
                            onMouseEnter={props.compEntered}
                            onMouseLeave={props.compLeave}
                            disabled={!name || !email}
                            onClick={handleSubmit}>
                            Submit
                        </Button>
                    </form>
                </FlexBox>
            </ContactFormV2Container>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Thank you!, One of our associates will get back to you shortly.
                </Alert>
            </Snackbar>
        </>
    )
}
