import React, { useState } from 'react'
import { TiChevronLeftOutline, TiChevronRightOutline } from 'react-icons/ti'

const MAX_VISIBILITY = 3;

export const Carousel = ({ children, ...props }) => {

    const count = React.Children.count(children);

    return (
        <div className='carousel'>

            <button
                className='nav left'
                onMouseEnter={props.arrowEntered}
                onMouseLeave={props.arrowLeave}
                onClick={() => props.setActive(i => i - 1)}
                disabled={props.active === 0}>
                {
                    props.active > 0
                        ?
                        <TiChevronLeftOutline />
                        :
                        <div style={{
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                            margin: 20,
                            backgroundColor: "#E2E2E3"
                        }} />
                }
            </button>

            {
                React.Children.map(children, (child, i) => (
                    <div
                        className='card-container'
                        style={{
                            '--props.active': i === props.active ? 1 : 0,
                            '--offset': (props.active - i) / 3,
                            '--direction': Math.sign(props.active - i),
                            '--abs-offset': Math.abs(props.active - i) / 3,
                            'pointer-events': props.active === i ? 'auto' : 'none',
                            'opacity': Math.abs(props.active - i) >= MAX_VISIBILITY ? '0' : '1',
                            'display': Math.abs(props.active - i) > MAX_VISIBILITY ? 'none' : 'block',
                        }}>
                        {child}
                    </div>
                ))
            }

            <button
                className='nav right'
                onMouseEnter={props.arrowEntered}
                onMouseLeave={props.arrowLeave}
                onClick={() => props.setActive(i => i + 1)}
                disabled={props.active === count - 1}>
                {props.active < count - 1
                    ?
                    <TiChevronRightOutline />
                    :
                    <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        margin: 20,
                        backgroundColor: "#E2E2E3"
                    }} />
                }
            </button>

        </div>
    );
};
