
export const fontWeights = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const fontSizes = {
  headline: "136px",
  subHeadline: "96px",
  childHeadline: "80px",
  subChildHeadline: "64px",
  heading: "40px",
  subHeading: "32px",
  title: "24px",
  subTitle: "20px",
  body: "16px",
  subBody: "12px",
  caption: "10px",
  subCaption: "8px",
};