import React from 'react'
import { ReadMoreStoriesBtn, StoryHeading, StorySubHeading, SuccessStoryPreviewBlockContainer, SuccessStoryPreviewBlockWrapper, ViewMoreStoriesBtn, ViewMoreStoriesBtnText } from '../style/landing.styles'
import { Layer } from '../../../global/components/layer'
import { AbsoluteBottomRight, AbsoluteCenter, AlignCenter, Container, FlexBox, FlexBoxSB, FlexColumn, FlexColumnSB, SubHeading, TitleBold } from '../../../global/style/global.styles'
import { SpacerTopBottomLarge, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { ImgHolder } from '../../../global/components/imgHolder'

export const SuccessStoryPreviewBlock = (props) => {

    return (
        <SuccessStoryPreviewBlockWrapper style={
            props.client.banner
                ?
                {
                    backgroundImage: `url(${props.width > 850 ? props.client.banner.banner1500 : props.client.banner.banner450})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat"
                }
                :
                { backgroundColor: props.client.accent }
        }>
            <Layer
                shade={"rgba(0,0,0,0.5)"}
            />
            <SuccessStoryPreviewBlockContainer>
                <Container style={{ height: "100%" }}>
                    <FlexColumnSB style={{ height: "100%" }}>
                        <FlexBox style={{ justifyContent: "end", zIndex: 1 }}>
                            <ImgHolder
                                img={props.client.logo}
                                width={140}
                            />
                        </FlexBox>
                        <FlexBoxSB style={{ position: "relative", alignItems: props.width > 450 ? "end" : "center" }}>
                            <div>
                                <StoryHeading>
                                    {props.name}
                                </StoryHeading>
                                <SpacerTopBottomLarge />
                                <div style={{ width: props.width > 450 ? "90%" : "100%" }}>
                                    <StorySubHeading>
                                        {props.description}
                                    </StorySubHeading>
                                </div>
                                <SpacerTopBottomXL />
                                <a href={props.width > 850 ? '/work' : `/work/${props.id}`} target='_blank'>
                                    <ReadMoreStoriesBtn
                                        onMouseEnter={props.readMoreStoryEntered}
                                        onMouseLeave={props.readMoreStoryLeave}
                                        style={{ backgroundColor: props.client.accent }}>
                                        <AbsoluteCenter>
                                            <ViewMoreStoriesBtnText style={{ color: "white" }}>
                                                Read More
                                            </ViewMoreStoriesBtnText>
                                        </AbsoluteCenter>
                                    </ReadMoreStoriesBtn>
                                </a>
                            </div>
                            {
                                props.width > 450
                                    ?
                                    <a href='/work'>
                                        <ViewMoreStoriesBtn
                                            style={{ flexShrink: 0 }}
                                            onMouseEnter={props.viewAllStoryEntered}
                                            onMouseLeave={props.viewAllStoryLeave}>
                                            <AbsoluteCenter>
                                                <AlignCenter>
                                                    <ViewMoreStoriesBtnText>
                                                        View all
                                                    </ViewMoreStoriesBtnText>
                                                </AlignCenter>
                                            </AbsoluteCenter>
                                        </ViewMoreStoriesBtn>
                                    </a>
                                    :
                                    <div />
                            }
                        </FlexBoxSB>
                    </FlexColumnSB>
                </Container>
            </SuccessStoryPreviewBlockContainer>
        </SuccessStoryPreviewBlockWrapper>
    )
}
