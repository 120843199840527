export const sizes = [
    "2px",
    "4px",
    "8px",
    "10px",
    "12px",
    "16px",
    "20px",
    "24px",
    "32px",
    "40px",
    "56px",
    "64px",
    "72px",
    "80px",
    "88px",
    "128px",
    "200px",
    "256px",
    "512px",
    "1024px"
];