import styled from 'styled-components'
import contactBg from '../../../assets/images/contactBg.jpg'
import contactBgFull from '../../../assets/images/contactBgFullv2.png'

export const ContactWrapper = styled.div`
    height: 100vh;
    position: relative;
    background-image: url(${contactBg});
    background-size: cover;
    background-repeat: repeat;
`;

export const ContactContainer = styled.div`
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 16px;
    height: 80vh;
`;

export const ContactFormWrapper = styled.div`
    
`;

export const ContactFormContainer = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[6]};
    padding-right: ${(props) => props.theme.space[6]};
`;

export const ContactInfoWrapper = styled.div`
    background-color: rgba(255, 255, 255, 0.3);
    height: 80vh;
    width: 500px;
    position: relative;
`;

export const ContactInfoContainer = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[6]};
    padding-right: ${(props) => props.theme.space[6]};
    height: 100%;
`;

export const ContactFormHeading = styled.div`
    font-family: 'Pacifico', cursive;
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.black};
    letter-spacing: 0.04em; 
`;

export const ContactInfoText = styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 154%;
    letter-spacing: 0.08em;
    color: white;
`;

export const ContactInfoSubText = styled.div`
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: white;
`;

export const ContactInfoMidText = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: white;
`;

export const ContactMapPosition = styled.div`
    position: absolute;
    right: -50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

export const ContactMapContainer = styled.div`
    width: 100%;
    height: 200px;
    border-radius: 8px;
    box-shadow: 0px 3px 5px 0px rgba(106,106,106,0.75);
-webkit-box-shadow: 0px 3px 5px 0px rgba(106,106,106,0.75);
-moz-box-shadow: 0px 3px 5px 0px rgba(106,106,106,0.75);

    @media only screen and (max-width: 450px) {
        height: 300px;
    }
`;

export const ContactV2Wrapper = styled.div`
    position: relative;
    background-image: url(${contactBgFull});
    background-size: cover;
    background-repeat: repeat;
`;

export const ContactHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.headline};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.black};
    line-height: 150px;

    @media only screen and (min-width: 1800px) {
        font-size: 160px;
    }
    @media only screen and (max-width: 1100px) {
        font-size: 120px;
        line-height: 136px;
    }
    @media only screen and (max-width: 850px) {
        font-size: ${(props) => props.theme.fontSizes.subHeadline};
        line-height: 112px;
        margin-top: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.childHeadline};
        line-height: 88px;
    }
`;

export const ContactSubHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: #C9C9C9;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.04em;
    line-height: 40px;
`;

export const ContactInfoTileText = styled.div`

    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.02em;
    line-height: 40px;

    @media only screen and (min-width: 1800px) {
        font-size: 48px;
        line-height: 56px;
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.title};
        line-height: 32px;
    }
`;

export const ContactInfoV2Container = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[12]};
    height: 100%;
`;

export const ContactFormV2Container = styled.div`

    @media only screen and (max-width: 850px) {
        padding-top: ${(props) => props.theme.space[8]};
    }
`;

export const ContactFormLabel = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.02em;
`;

export const ContactFormSubLabel = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.light};
    letter-spacing: 0.02em;
    font-style: italic;
`;

export const ContactFormInput = styled.input`
    border: 1px solid #D8D9D9;
    width: 400px;
    height: 48px;
    padding: 4px 8px;
    border-radius: 2px;

    @media only screen and (max-width: 1100px) {
        width: 350px;
    }
    @media only screen and (max-width: 850px) {
        width: 100%;
    }
`;

export const ContactFormTextArea = styled.textarea`
    border: 1px solid #D8D9D9;
    width: 400px;
    padding: 4px 8px;
    border-radius: 2px;
    resize: none;
    vertical-align: top;
    line-height: 24px;

    @media only screen and (max-width: 1100px) {
        width: 350px;
    }
    @media only screen and (max-width: 850px) {
        width: 100%;
    }
`;

export const AppointmentContainer = styled.div`
    background-color: rgba(108, 108, 108, 0.6);
    padding: 56px 0;
`;
