import React from 'react'
import { ServiceWrapper, ServicesWrapper, WebsiteServiceTitle, WebsiteServiceWrapper } from '../style/services.styles'
import { Sandwich } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { Layer } from '../../../global/components/layer'
import { ServiceLinkBtn } from './serviceLinkBtn'
import webpage1 from '../../../assets/images/Metaverse.png'
import webpage2 from '../../../assets/images/pulseSync.png'

export const WebsiteService = (props) => {
    return (
        <Sandwich>
            <ServiceWrapper style={{ height: props.width > 1100 ? 1035 : props.width > 650 ? 960 : props.width > 450 ? 700 : props.width > 370 ? 680 : 640 }}>
                <WebsiteServiceTitle
                    style={{
                        position: "absolute",
                        left: props.width > 850 ? 112 : props.width > 450 ? 40 : 20,
                        width: props.width > 850 ? 560 : props.width > 650 ? 480 : 340
                    }}>
                    MODERN
                </WebsiteServiceTitle>
                <WebsiteServiceTitle
                    style={{
                        position: "absolute",
                        left: props.width > 850 ? 320 : props.width > 450 ? 150 : 40,
                        top: props.width > 850 ? 152 : props.width > 650 ? 136 : props.width > 450 ? 96 : props.width > 370 ? 77 : 62,
                        width: props.width > 850 ? 640 : props.width > 650 ? 560 : 400,
                        zIndex: 2
                    }}>
                    WEBSITES
                </WebsiteServiceTitle>
                <div style={{
                    position: "absolute",
                    right: props.width > 1100 ? 0 : props.width > 450 ? -200 : 120,
                    top: props.width > 650 ? 120 : 80
                }}>
                    {
                        props.width < 850
                        &&
                        <Layer
                            shade="rgba(0,0,0,0.4)"
                        />
                    }
                    <ImgHolder
                        img={webpage1}
                        width={props.width > 1100 ? 931 : props.width > 650 ? 731 : props.width > 450 ? 531 : 440}
                    />
                </div>
                <div style={{
                    position: "absolute",
                    left: props.width > 1100 ? 112 : 40,
                    top: props.width > 650 ? 500 : props.width > 450 ? 360 : 340
                }}>
                    <ImgHolder
                        img={webpage2}
                        width={props.width > 1100 ? 1000 : props.width > 650 ? 800 : 600}
                    />
                </div>
                <a
                    href='/mastery/design'
                    target='_blank'
                    style={{
                        position: "absolute",
                        right: props.width > 450 ? 112 : 40,
                        bottom: 0
                    }}
                    onMouseEnter={props.serviceLinkEntered}
                    onMouseLeave={props.serviceLinkLeave}>
                    <ServiceLinkBtn />
                </a>
            </ServiceWrapper>
        </Sandwich>
    )
}
