import React from 'react'
import { StoryLandingLinkContainer, StoryLandingLinkWrapper } from '../style/stories.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { AbsoluteCenter, Body, BodyMedium, BodyWhite, FlexBox } from '../../../global/style/global.styles'
import { SpacerLeftRightLarge, SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer'

export const StoryLandingLink = (props) => {
    return (
        <StoryLandingLinkWrapper
            href={props.href}
            target={props.target}
            onMouseEnter={props.storyLinkEnter}
            onMouseLeave={props.storyLinkLeave}>
            <StoryLandingLinkContainer>
                <AbsoluteCenter>
                    <FlexBox style={{ display: props.width > 650 ? "block" : "flex" }}>
                        <div>
                            <ImgHolder
                                img={props.logo}
                                width={props.width > 650 ? 120 : 80}
                            />
                        </div>
                        {
                            props.width < 650
                                ?
                                <SpacerLeftRightLarge />
                                :
                                <SpacerTopBottomMedium />
                        }
                        <BodyMedium style={{ textAlign: "center", color: "#C9C9C9" }}>
                            Visit Site
                        </BodyMedium>
                    </FlexBox>
                </AbsoluteCenter>
            </StoryLandingLinkContainer>
        </StoryLandingLinkWrapper>
    )
}
