import React from 'react'
import { Container, FlexBoxSA, SandwichHalf, Squeezer } from '../../../global/style/global.styles'
import { MasterySectionSubTitle, MasterySectionTitle } from '../style/mastery.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { SpacerTopBottomLarge, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import emvCardsImg from '../../../assets/images/emvcards.png'

export const EmvTesting = (props) => {
    return (
        <>
            <SandwichHalf />
            <ImgHolder
                img={emvCardsImg}
                width="100%"
            />
            <Container>
                <MasterySectionTitle>
                    Our team of experts are specialized in performing EMV L3 Certification Testing,
                    <br />
                    at the highest standards
                </MasterySectionTitle>
                <SpacerTopBottomLarge />
                <MasterySectionSubTitle>
                    In preparation for the market launch of any Point of Sale (POS) device, such as Verifone, PineLabs, Ingenico, etc., a comprehensive testing protocol is imperative. This process involves meticulous testing of the device's compatibility with various payment card types and adherence to the regulatory requirements of the countries in which it is intended for deployment.
                    <br />
                    Our approach encompasses the utilization of certification testing tools combined with our proficiency in EMV standards. These tools are meticulously configured to align with the specific attributes of the targeted devices. Subsequently, we generate a suite of test cases tailored to assess and validate the device's functionality across diverse payment card types.
                    <br />
                    The testing process is executed methodically, and each test case is scrutinized against predetermined expected outcomes. This rigorous certification and testing procedure ensures that the POS device not only supports a wide array of payment cards but also aligns with the regulatory and security standards of the designated countries. Through this meticulous testing and certification process, we ascertain the reliability, compliance, and seamless performance of the POS device in diverse operational scenarios, contributing to its successful market entry.
                </MasterySectionSubTitle>
            </Container>
        </>
    )
}
