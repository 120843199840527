export const colors = {
  primary: {
    
  },
  bg: {
    black: "#000000",
    white: "#FFFFFF",
    grey: "#F5F5F9",
  },
  text: {
    black: "#000000",
    grey80: "#373738",
    grey60: "#6E6D70",
    grey50: "#B8B7BA",
    grey40: "#E2E2E3",
    grey20: "#F0F0F1",
    white: "#FFFFFF",
    link: "#2998FF",
    highlight: "#FFE83A",
    green: "#00EC22",
    red: "#FF2929"
  }
};