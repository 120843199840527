import React, { useContext } from 'react'
import { Container, FlexBox, Sandwich, SandwichHalf } from '../../../global/style/global.styles'
import { Grid } from '@mui/material'
import { ContactHeading, ContactSectionWrapper } from '../style/contact.styles'
import { ImgHolder } from '../../../global/components/imgHolder'

import whatsappIcon from '../../../assets/icons/whatsapp-o.png'
import telegramIcon from '../../../assets/icons/telegram-o.png'
import discordIcon from '../../../assets/icons/discord-o.png'
import lineIcon from '../../../assets/icons/line-o.png'

import facebookIcon from '../../../assets/icons/facebook-o.png'
import instagramIcon from '../../../assets/icons/instagram-o.png'
import xIcon from '../../../assets/icons/x-o-w.png'
import linkedInIcon from '../../../assets/icons/linkedIn-o.png'
import pinterestIcon from '../../../assets/icons/pinterest-o.png'
import youtubeIcon from '../../../assets/icons/youtube-o.png'
import tumblrIcon from '../../../assets/icons/tumblr-o.png'
import redditIcon from '../../../assets/icons/reddit-o.png'
import quoraIcon from '../../../assets/icons/quora-o.png'

import { SpacerLeftRightLarge, SpacerLeftRightXL, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { ArchiveContext } from '../../../context/archive/archive.provider'
import { IconHolder } from '../../../global/components/iconHolder'

export const SocialNetwork = (props) => {

    const {
        youTubeLink,
        linkedInLink,
        facebookLink,
        twitterLink,
        whatsappLink,
        instagramLink,
        tumblrLink,
        redditLink,
        quoraLink,
        telegramLink,
        discordLink,
        lineLink,
        pinterestLink
    } = useContext(ArchiveContext)

    return (
        <>
            <Container>
                <Grid container>
                    {
                        props.width > 850
                        &&
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FlexBox>
                                <a href={facebookLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={facebookIcon}
                                        width={props.width > 1300 ? 80 : props.width < 850 ? 80 : 64}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                <a href={instagramLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={instagramIcon}
                                        width={props.width > 1300 ? 80 : props.width < 850 ? 80 : 64}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                <a href={twitterLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={xIcon}
                                        width={props.width > 1300 ? 80 : props.width < 850 ? 80 : 64}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                <a href={linkedInLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={linkedInIcon}
                                        width={props.width > 1300 ? 80 : props.width < 850 ? 80 : 64}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                            </FlexBox>
                            <SpacerTopBottomXXXL />
                            <FlexBox>
                                <a href={youTubeLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={youtubeIcon}
                                        width={props.width > 1300 ? 80 : props.width < 850 ? 80 : 64}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                {/* <a href={tumblrLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={tumblrIcon}
                                        width={props.width > 1300 ? 80 : props.width < 850 ? 80 : 64}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL /> */}
                                <a href={redditLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={redditIcon}
                                        width={props.width > 1300 ? 80 : props.width < 850 ? 80 : 64}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                <a href={quoraLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={quoraIcon}
                                        width={props.width > 1300 ? 80 : props.width < 850 ? 80 : 64}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                <a href={pinterestLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={pinterestIcon}
                                        width={props.width > 1300 ? 80 : props.width < 850 ? 80 : 64}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                            </FlexBox>
                        </Grid>
                    }
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ContactHeading style={{ textAlign: "right" }}>
                            Social
                            <br />
                            Network
                        </ContactHeading>
                        {
                            props.width > 450
                                ?
                                <SandwichHalf />
                                :
                                <div style={{ marginBottom: 56 }} />
                        }
                        <FlexBox style={{ justifyContent: "right" }}>
                            <a href={whatsappLink} style={{ textDecoration: "none" }} target='_blank'>
                                <IconHolder
                                    icon={whatsappIcon}
                                    width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                    onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                />
                            </a>
                            <SpacerLeftRightXL />
                            <a href={telegramLink} style={{ textDecoration: "none" }} target='_blank'>
                                <IconHolder
                                    icon={telegramIcon}
                                    width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                    onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                />
                            </a>
                            <SpacerLeftRightXL />
                            <a href={discordLink} style={{ textDecoration: "none" }} target='_blank'>
                                <IconHolder
                                    icon={discordIcon}
                                    width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                    onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                />
                            </a>
                            <SpacerLeftRightXL />
                            <a href={lineLink} style={{ textDecoration: "none" }} target='_blank'>
                                <IconHolder
                                    icon={lineIcon}
                                    width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                    onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                />
                            </a>
                        </FlexBox>
                    </Grid>
                    {
                        props.width < 850
                        &&
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div style={{ marginBottom: 48 }} />
                            <FlexBox style={{ justifyContent: "right" }}>
                                <a href={facebookLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={facebookIcon}
                                        width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                <a href={instagramLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={instagramIcon}
                                        width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                <a href={twitterLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={xIcon}
                                        width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                <a href={linkedInLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={linkedInIcon}
                                        width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                            </FlexBox>
                            <div style={{ marginBottom: 48 }} />
                            <FlexBox style={{ justifyContent: "right" }}>
                                <a href={youTubeLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={youtubeIcon}
                                        width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                {/* <SpacerLeftRightXL />
                                <a href={tumblrLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={tumblrIcon}
                                        width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a> */}
                                <SpacerLeftRightXL />
                                <a href={redditLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={redditIcon}
                                        width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                <a href={quoraLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={quoraIcon}
                                        width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                                <SpacerLeftRightXL />
                                <a href={pinterestLink} style={{ textDecoration: "none" }} target='_blank'>
                                    <IconHolder
                                        icon={pinterestIcon}
                                        width={props.width > 450 ? 80 : props.width > 350 ? 48 : 35}
                                        onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}
                                    />
                                </a>
                            </FlexBox>
                        </Grid>
                    }
                </Grid>
            </Container>
        </>
    )
}
