import React from 'react'
import { StoryHeading, StoryPara } from '../style/stories.styles'
import { SpacerTopBottomLarge, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { Container } from '../../../global/style/global.styles'

export const StorySolution = (props) => {
    return (
        <Container>
            <StoryHeading>
                OUR SOULTION
            </StoryHeading>
            <SpacerTopBottomXL />
            {
                props.data?.statement?.map((item, index) => (
                    <StoryPara key={index}>
                        {item}
                    </StoryPara>
                ))
            }
        </Container>
    )
}
