import styled from 'styled-components'

export const LandingWrapper = styled.div`
`;

export const HeroSectionContainer = styled.div`
    height: 100vh;
    position: relative;
    background-color: white;
    overflow: hidden;
`;

export const HeroTitlePosition = styled.div`
    position: absolute;
    left: 50%;
    top: 14%;
    transform: translate(-50%,-14%);
`;

export const HeroCTAPosition = styled.div`
    position: absolute;
    bottom: 160px;

    @media only screen and (max-width: 1100px) {
        bottom: 40px;
    }
    @media only screen and (max-width: 850px) {
        bottom: 0;
    }
`;

export const HeroCTAContainer = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
        padding-bottom: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-top: ${(props) => props.theme.space[4]};
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const HeroCTAText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    line-height: 154%;
    letter-spacing: 0.05em;
`;

export const HeroCTABtnContainer = styled.div`
    padding: 8px;
    border-radius: 4px;
    background-color: #B2B2B2;
    ${'' /* background-color: rgba(129, 129, 132, 0.6); */}
    box-shadow: 0px 1px 2px 0px rgba(178,178,178,0.75);
-webkit-box-shadow: 0px 1px 2px 0px rgba(178,178,178,0.75);
-moz-box-shadow: 0px 1px 2px 0px rgba(178,178,178,0.75);
    cursor: pointer;

    ${'' /* &:hover {
        transform: scale(1.03);
        transition: all 0.2s ease;
        box-shadow: 0px 2px 4px 0px rgba(132,132,132,0.75);
-webkit-box-shadow: 0px 2px 4px 0px rgba(132,132,132,0.75);
-moz-box-shadow: 0px 2px 4px 0px rgba(132,132,132,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.4s ease;
    } */}
`;

export const HeroCTABtnText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    letter-spacing: 0.04em;

    &:hover {
        transition: all 0.2s ease;
        color: ${(props) => props.theme.colors.text.black};
    }
    &:not(:hover) {
        transition: all 0.4s ease;
    }
`;

export const HeroCTAHeading = styled.div`
    font-family: 'Pacifico', cursive;
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.black};
    letter-spacing: 0.02em; 
`;

export const BriefAboutWrapper = styled.div`
    position: fixed;
    top: 0;
    z-index: -2;
    
`;

export const BriefAboutContainer = styled.div`
    padding-left: ${(props) => props.theme.space[20]};
    height: 100vh;

    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
    }
`;

export const BriefAboutText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeadline};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.black};

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.headline};
    }
    @media only screen and (max-width: 850px) {
        font-size: ${(props) => props.theme.fontSizes.subHeadline};
    }
    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.childHeadline};
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.subChildHeadline};
    }
    @media only screen and (max-width: 370px) {
        font-size: 56px;
    }
`;

export const BriefAboutVideo = styled.video`
    position: absolute;
    object-fit: cover;
    z-index: -3;
    width: 100vw;
    height: 100vh;
`;

export const BriefAboutWindow = styled.div`
    height: 100vh;
`;

export const SuccessStoryPreviewWrapper = styled.div`
    height: 300vh;
    position: relative;
`;

export const SuccessStoryPreviewContainer = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow: hidden;
`;

export const SuccessStoryPreviewNormalContainer = styled.div`
    height: 100vh;
    overflow: hidden;
`;

export const SuccessStoryPreviewBlockWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: relative;
`;

export const SuccessStoryPreviewBlockContainer = styled.div`
    height: 100%;
`;

export const ViewMoreStoriesBtn = styled.div`
    background-color: rgba(255,255,255,0.33);
    border-radius: 8px;
    width: 96px;
    height: 96px;
    border-radius: 48px;
    position: relative;
`;

export const ViewMoreStoriesBtnText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    line-height: 138%;
    letter-spacing: 0.04em;
`;

export const ReadMoreStoriesBtn = styled.div`
    background-color: rgba(0, 0, 0, 0.33);
    border-radius: 8px;
    width: 128px;
    height: 48px;
    position: relative;
`;

export const StoryHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const StorySubHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 154%;
    letter-spacing: 0.08em;
`;

export const ExpertiseSectionContainer = styled.div`
    background-color: white;
    min-height: 100vh;
    position: relative;
`;

export const ExpertiseSplineContainer = styled.div`
    position: relative;
`;

export const BlogSectionWrapper = styled.div`
    background-color: white;
`;

export const BlogSectionContainer = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: 164px;
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {
        padding-bottom: 120px;
    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
        padding-bottom: 72px;
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const BlogHeading = styled.div`
    font-size: 220px;
    color: black;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 0.02em;
    position: absolute;

    @media only screen and (max-width: 650px) {
        font-size: 160px;
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.subHeadline};
    }
`;

export const FeaturedBlogBlockContainer = styled.div`
    width: 350px;
    height: 525px;
    margin: 0 40px;
    box-shadow: 10px 10px 11px 0px rgba(167,167,167,0.75);
-webkit-box-shadow: 10px 10px 11px 0px rgba(167,167,167,0.75);
-moz-box-shadow: 10px 10px 11px 0px rgba(167,167,167,0.75);
    cursor: pointer;
    position: relative;

    @media only screen and (max-width: 450px) {
        width: 308px;
        height: 461px;
    }
`;

export const FeaturedBlogFocusContainer = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[4]};
    padding-right: ${(props) => props.theme.space[4]};
    width: 350px;
    height: 525px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(50,58,66,0) 100%);

    @media only screen and (max-width: 450px) {
        width: 308px;
        height: 461px;
    }
`;

export const FeaturedBlogTitle = styled.div`
    font-family: 'Roboto Condensed', sans-serif;    
    font-size: ${(props) => props.theme.fontSizes.subHeading};;
    font-weight: ${(props) => props.theme.fontWeights.light};
    color: ${(props) => props.theme.colors.text.white};
    text-transform: uppercase; 
    letter-spacing: -0.02em;
`;

export const Hero3DIconPosition = styled.div`
    position: absolute;
    bottom: 100px;
    right: 200px;
`;

export const Cube3DText = styled.div`
    font-family: 'Pacifico', cursive;
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.black};
    letter-spacing: 0.02em; 
    font-weight: 900;
`;