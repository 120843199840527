import React from 'react'
import { Container, FlexColumnSA } from '../../../global/style/global.styles'
import { Grid } from '@mui/material'
import { ContactHeading, ContactSectionWrapper } from '../style/contact.styles'
import { ContactInfoV2 } from './contactInfoV2'

export const GetInTouch = (props) => {
    return (
        <>
            <Container>
                <Grid container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FlexColumnSA style={{ height: props.width > 850 ? "100vh" : "100%" }}>
                            <ContactHeading>
                                Get in
                                <br />
                                touch.
                            </ContactHeading>
                        </FlexColumnSA>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ContactInfoV2
                            width={props.width}
                            compEntered={props.compEntered}
                            compLeave={props.compLeave}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
