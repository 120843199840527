import React from 'react'
import { Container, FlexBox, Sandwich, SandwichHalf } from '../../../global/style/global.styles'
import { MasterySectionMinititle, MasterySectionSubTitle, MasterySectionTitle } from '../style/mastery.styles'
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { Grid } from '@mui/material'
import { ImgHolder } from '../../../global/components/imgHolder'
import laptopIcon from '../../../assets/images/laptop.png'
import iPhoneIcon from '../../../assets/images/iPhone.png'
import tabIcon from '../../../assets/images/tab.png'

export const AppDeploy = (props) => {
    return (
        <>
            <Container>
                <MasterySectionTitle>
                    Application Deployment
                </MasterySectionTitle>
                {
                    props.width > 850
                        ?
                        <SandwichHalf />
                        :
                        <SpacerTopBottomXXXL />
                }
                <Grid container spacing={props.width > 850 ? 2 : 4} alignItems="center" >
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FlexBox style={{ justifyContent: props.width > 850 ? "right" : "center" }}>
                            <ImgHolder
                                img={laptopIcon}
                                width={props.width > 1100 ? 720 : props.width > 650 ? 600 : props.width > 450 ? 500 : 340}
                            />
                        </FlexBox>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FlexBox style={{ justifyContent: props.width > 850 ? "right" : "left" }}>
                            <div style={{ width: props.width > 850 ? "60%" : "100%" }}>
                                <MasterySectionMinititle>
                                    Search Engine Optimized
                                </MasterySectionMinititle>
                                <SpacerTopBottomMedium />
                                <MasterySectionSubTitle>
                                    SEO enhances website visibility, driving organic traffic and increasing chances of reaching target audiences effectively. By optimizing content and structure, SEO boosts search engine rankings, fostering greater brand recognition and credibility in the digital landscape
                                </MasterySectionSubTitle>
                            </div>
                        </FlexBox>
                    </Grid>
                </Grid>
                {
                    props.width > 850
                        ?
                        <Sandwich />
                        :
                        <SandwichHalf />
                }
                <Grid container spacing={props.width > 850 ? 2 : 4} alignItems="center" >
                    {
                        props.width < 850
                        &&
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FlexBox style={{ justifyContent: props.width > 850 ? "right" : "center" }}>
                                <ImgHolder
                                    img={iPhoneIcon}
                                    width={props.width > 1100 ? 280 : props.width > 650 ? 240 : props.width > 450 ? 200 : 160}
                                />
                            </FlexBox>
                        </Grid>
                    }
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FlexBox>
                            <div style={{ width: props.width > 850 ? "60%" : "100%" }}>
                                <MasterySectionMinititle>
                                    Responsive Design
                                </MasterySectionMinititle>
                                <SpacerTopBottomMedium />
                                <MasterySectionSubTitle>
                                    It ensures optimal user experience across various devices, increasing engagement and reducing bounce rates. By adapting seamlessly to different screen sizes and resolutions, it enhances accessibility and user satisfaction, ultimately driving conversions and bolstering brand reputation
                                </MasterySectionSubTitle>
                            </div>
                        </FlexBox>
                    </Grid>
                    {
                        props.width > 850
                        &&
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FlexBox style={{ justifyContent: props.width > 850 ? "right" : "center" }}>
                                <ImgHolder
                                    img={iPhoneIcon}
                                    width={props.width > 1100 ? 280 : 240}
                                />
                            </FlexBox>
                        </Grid>
                    }
                </Grid>
                {
                    props.width > 850
                        ?
                        <Sandwich />
                        :
                        <SandwichHalf />
                }
                <Grid container spacing={props.width > 850 ? 2 : 4} alignItems="center" >
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FlexBox style={{ justifyContent: props.width > 850 ? "right" : "center" }}>
                            <ImgHolder
                                img={tabIcon}
                                width={props.width > 1100 ? 420 : props.width > 650 ? 380 : props.width > 450 ? 320 : 280}
                            />
                        </FlexBox>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FlexBox style={{ justifyContent: props.width > 850 ? "right" : "left" }}>
                            <div style={{ width: props.width > 850 ? "60%" : "100%" }}>
                                <MasterySectionMinititle>
                                    Compatibility, Accessibility, Scalability
                                </MasterySectionMinititle>
                                <SpacerTopBottomMedium />
                                <MasterySectionSubTitle>
                                    It ensure seamless user experiences across devices and platforms, enhancing reach and inclusivity. By accommodating diverse user needs and enabling growth without sacrificing performance, these attributes bolster user engagement, satisfaction, and long-term success.
                                </MasterySectionSubTitle>
                            </div>
                        </FlexBox>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
