import React from 'react'
import { MarketingServiceTitle, MobileAppServiceTitle, ServiceWrapper, ServicesWrapper, WebAppServiceTitle, WebsiteServiceTitle, WebsiteServiceWrapper } from '../style/services.styles'
import { Sandwich } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import rocketLaunch from '../../../assets/images/rocketLauch.png'
import cloud2 from '../../../assets/images/C2.png'
import cloud1 from '../../../assets/images/C1.png'
import socialMediaIcon from '../../../assets/images/5395107-01.png'
import { Layer } from '../../../global/components/layer'
import { ServiceLinkBtn } from './serviceLinkBtn'

export const MarketingService = (props) => {
    return (
        <Sandwich>
            <ServiceWrapper style={{ height: props.width>650?1000:900 }}>
                <MarketingServiceTitle
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        width: props.width > 850 ? 750 : props.width > 650 ? 660 : props.width > 450 ? 470 : props.width > 370 ? 340 : 300,
                        zIndex: 2
                    }}>
                    MARKETING
                </MarketingServiceTitle>
                <div style={{
                    position: "absolute",
                    left: props.width > 1300 ? 112 : props.width > 1100 ? 0 : props.width > 850 ? -80 : props.wdith > 450 ? -200 : -240,
                    top: 0,
                    zIndex: 2
                }}>
                    <ImgHolder
                        img={rocketLaunch}
                        width={620}
                    />
                </div>
                <div style={{
                    position: "absolute",
                    left: "50%",
                    top: "45%",
                    transform: "translate(-50%, -50%)"
                }}>
                    <ImgHolder
                        img={cloud1}
                        width={220}
                    />
                </div>
                <div style={{
                    position: "absolute",
                    left: "62%",
                    top: "73%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 2
                }}>
                    <ImgHolder
                        img={cloud2}
                        width={300}
                    />
                </div>
                <div style={{
                    position: "absolute",
                    right: props.width > 1300 ? 0 : props.width > 1100 ? -80 : props.width > 850 ? -160 : props.width > 650 ? -240 : props.width > 450 ? -300 : props.width > 370 ? -370 : -390,
                    top: "56%",
                    transform: "translate(0, -50%)"
                }}>
                    <ImgHolder
                        img={socialMediaIcon}
                        width={550}
                    />
                </div>
                {/* <div style={{
                    position: "absolute",
                    left: "50%",
                    bottom: 0,
                    transform: "translate(-50%, 0)"
                }}>
                    <ImgHolder
                        img={analyticsMock}
                        width={800}
                    />
                </div> */}
                <a
                    href='/mastery/marketing'
                    target='_blank'
                    style={{
                        position: "absolute",
                        right: props.width > 450 ? 112 : 40,
                        bottom: 0
                    }}
                    onMouseEnter={props.serviceLinkEntered}
                    onMouseLeave={props.serviceLinkLeave}>
                    <ServiceLinkBtn />
                </a>
            </ServiceWrapper>
        </Sandwich>
    )
}
