import React from 'react'
import { AlignCenter, Container, FlexBox, FlexBoxSA, SandwichHalf } from '../../../global/style/global.styles'
import { MasterySectionMinititle, MasterySectionSubTitle, MasterySectionTitle } from '../style/mastery.styles'
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { ImgHolder } from '../../../global/components/imgHolder'
import genArch from '../../../assets/images/architecture.png'
import { Grid } from '@mui/material'
import custCode from '../../../assets/images/custCode.png'
import lightning from '../../../assets/images/lightning.png'
import secureIcon from '../../../assets/images/secure.png'

export const GeneralArch = (props) => {
    return (
        <>
            <Container>
                <MasterySectionTitle>
                    General Architecture
                </MasterySectionTitle>
                <SpacerTopBottomXXXL />
                <FlexBoxSA>
                    <ImgHolder
                        img={genArch}
                    />
                </FlexBoxSA>
                <SandwichHalf />
                <Grid container spacing={props.width > 850 ? 2 : 8}>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <FlexBoxSA>
                            <div>
                                <FlexBoxSA>
                                    <ImgHolder
                                        img={custCode}
                                        width={props.width > 1100 ? 160 : 120}
                                    />
                                </FlexBoxSA>
                                <SpacerTopBottomLarge />
                                <AlignCenter>
                                    <MasterySectionMinititle>
                                        Custom Coding
                                    </MasterySectionMinititle>
                                </AlignCenter>
                                <SpacerTopBottomMedium />
                                <FlexBox style={{ justifyContent: props.width > 850 ? "normal" : "space-around" }}>
                                    <MasterySectionSubTitle
                                        style={{
                                            fontSize: props.width > 1100 ? 24 : 20,
                                            width: props.width > 850 ? "100%" : props.width > 650 ? "50%" : props.width > 450 ? "70%" : "100%",
                                            textAlign: "center"
                                        }}>
                                        Empowering businesses to develop tailored solutions precisely aligned with their unique requirements, optimizing functionality, efficiency, and competitive advantage in their industry
                                    </MasterySectionSubTitle>
                                </FlexBox>
                            </div>
                        </FlexBoxSA>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <FlexBoxSA>
                            <div>
                                <FlexBoxSA>
                                    <ImgHolder
                                        img={lightning}
                                        width={props.width > 1100 ? 160 : 120}
                                    />
                                </FlexBoxSA>
                                <SpacerTopBottomLarge />
                                <AlignCenter>
                                    <MasterySectionMinititle>
                                        Lightning Fast
                                    </MasterySectionMinititle>
                                </AlignCenter>
                                <SpacerTopBottomMedium />
                                <FlexBox style={{ justifyContent: props.width > 850 ? "normal" : "space-around" }}>
                                    <MasterySectionSubTitle
                                        style={{
                                            fontSize: props.width > 1100 ? 24 : 20,
                                            width: props.width > 850 ? "100%" : props.width > 650 ? "50%" : props.width > 450 ? "70%" : "100%",
                                            textAlign: "center"
                                        }}>
                                        Accelerates time-to-market, enabling rapid iteration, quick response to user feedback, and efficient adaptation to evolving market demands, ultimately enhancing competitiveness and user satisfaction
                                    </MasterySectionSubTitle>
                                </FlexBox>
                            </div>
                        </FlexBoxSA>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <FlexBoxSA>
                            <div>
                                <FlexBoxSA>
                                    <ImgHolder
                                        img={secureIcon}
                                        width={props.width > 1100 ? 160 : 120}
                                    />
                                </FlexBoxSA>
                                <SpacerTopBottomLarge />
                                <AlignCenter>
                                    <MasterySectionMinititle>
                                        Full Security
                                    </MasterySectionMinititle>
                                </AlignCenter>
                                <SpacerTopBottomMedium />
                                <FlexBox style={{ justifyContent: props.width > 850 ? "normal" : "space-around" }}>
                                    <MasterySectionSubTitle
                                        style={{
                                            fontSize: props.width > 1100 ? 24 : 20,
                                            width: props.width > 850 ? "100%" : props.width > 650 ? "50%" : props.width > 450 ? "70%" : "100%",
                                            textAlign: "center"
                                        }}>
                                        Ensuring robust protection of sensitive data, mitigating risks of breaches and unauthorized access, thereby safeguarding user trust, compliance with regulations, and overall organizational integrity
                                    </MasterySectionSubTitle>
                                </FlexBox>
                            </div>
                        </FlexBoxSA>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
