import React from 'react'
import { Container, FlexBoxSA } from '../../../global/style/global.styles'
import { ContactForm } from '../../contact/components/contactForm'

export const ServicesContact = (props) => {
    return (
        <>
            <Container>
                <FlexBoxSA>
                    <ContactForm
                        width={props.width}
                        location={props.location}
                        variant={props.variant}
                        color={props.color}
                        inputFieldEntered={props.inputFieldEntered}
                        inputFieldLeft={props.inputFieldLeft}
                    />
                </FlexBoxSA>
            </Container>
        </>
    )
}
