import Spline from '@splinetool/react-spline'
import React from 'react'

export const EarthSpline = (props) => {
    return (
        <div
            onMouseEnter={props.earthElementEntered}
            onMouseLeave={props.earthElementLeave}>
            {
                props.width > 850
                    ?
                    <Spline
                        scene="https://prod.spline.design/TFbF4oEljuLPeYaj/scene.splinecode"
                        style={{
                            position: "absolute",
                            left: 86,
                            transform: "translate(-50%,-50%)",
                            width: 300,
                            height: 300,
                            zIndex: 1,
                            cursor: "grab"
                        }}
                    />
                    :
                    <Spline
                        scene="https://prod.spline.design/TFbF4oEljuLPeYaj/scene.splinecode"
                        style={{
                            position: "absolute",
                            top: "15%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            width: 300,
                            height: 300,
                            zIndex: 2,
                            cursor: "grab"
                        }}
                    />
            }
            {/* <div
                style={{
                    backgroundColor: "red",
                    width: 300,
                    height: 300
                }}
            /> */}
        </div>
    )
}