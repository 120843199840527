import React from 'react'
import { ServicesHeroGraphBottomPosition, ServicesHeroGraphLeftPosition, ServicesHeroGraphRightPosition, ServicesHeroSectionWrapper } from '../style/services.styles'
import { ServicesHeroGraph } from './servicesHeroGraph'
import { ScrollIndicator } from '../../../global/components/scrollIndicator'

export const ServicesHeroSection = (props) => {
    return (
        <>
            <ServicesHeroSectionWrapper>
                <ServicesHeroGraphLeftPosition>
                    <ServicesHeroGraph
                        title="Design"
                    />
                </ServicesHeroGraphLeftPosition>
                <ServicesHeroGraphRightPosition>
                    <ServicesHeroGraph
                        title="Marketing"
                    />
                </ServicesHeroGraphRightPosition>
                <ServicesHeroGraphBottomPosition>
                    <ServicesHeroGraph
                        title="Technology"
                    />
                </ServicesHeroGraphBottomPosition>
                <ScrollIndicator />
            </ServicesHeroSectionWrapper>
        </>
    )
}
