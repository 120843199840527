import React from 'react'
import { Container, FlexBox } from '../../../global/style/global.styles'
import { Grid } from '@mui/material'
import { StoryProblem } from './storyProblem'
import { ClientInfo } from './clientInfo'
import { StoryHeading } from '../style/stories.styles'
import { SpacerTopBottomLarge, SpacerTopBottomXL } from '../../../design/spacer/spacer'

export const StoryContext = (props) => {
    return (
        <Container>
            <StoryHeading>
                CONTEXT
            </StoryHeading>
            <SpacerTopBottomXL />
            <Grid container spacing={props.width > 850 ? 20 : 4}>
                {
                    props.width < 850
                    &&
                    <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                        <FlexBox style={{ justifyContent: props.width > 850 ? "right" : "space-around" }}>
                            <ClientInfo
                                width={props.width}
                                data={props.client}
                            />
                        </FlexBox>
                    </Grid>
                }
                <Grid item xl={8} lg={8} md={7} sm={12} xs={12}>
                    <StoryProblem
                        width={props.width}
                        data={props.context}
                    />
                </Grid>
                {
                    props.width > 850
                    &&
                    <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                        <FlexBox style={{ justifyContent: props.width > 850 ? "right" : "space-around" }}>
                            <ClientInfo
                                width={props.width}
                                data={props.client}
                            />
                        </FlexBox>
                    </Grid>
                }
            </Grid>
        </Container>
    )
}
