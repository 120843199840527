import React from 'react'
import { BodyMediumWhite, BodyWhite, Container, FlexBox, FlexBoxSA, FlexBoxSB, FlexColumnSA, FlexColumnSB, SandwichHalf, SubTitleMediumWhite } from '../../../global/style/global.styles'
import { MasteryFeatureBlock, MasterySectionTitle } from '../style/mastery.styles'
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerLeftRightXXL, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL, SpacerTopBottomXXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { ImgHolder } from '../../../global/components/imgHolder'

import facebookIcon from '../../../assets/icons/facebook-o.png'
import instagramIcon from '../../../assets/icons/instagram-o.png'
import twitterIcon from '../../../assets/icons/x-o-w.png'
import youtubeIcon from '../../../assets/icons/youtube-o.png'

import chatGptIcon from '../../../assets/icons/chatGPT-o.webp'
import midjourneyIcon from '../../../assets/icons/midjourney-p.png'
import runwayIcon from '../../../assets/icons/runway-o.png'
import didIcon from '../../../assets/icons/d-id-o.webp'

import marinIcon from '../../../assets/icons/marin.png'
import gaIcon from '../../../assets/icons/googleAnalytics.png'

import helpJuiceIcon from '../../../assets/icons/helpjuice.png'
import intercomIcon from '../../../assets/icons/intercom.png'
import zendeskIcon from '../../../assets/icons/zendesk.png'

import stripeIcon from '../../../assets/icons/stripe.svg'
import razorpayIcon from '../../../assets/icons/razorpay.png'

import hubspotIcon from '../../../assets/icons/hubspot.svg'
import mailchimpIcon from '../../../assets/icons/mailchimp.png'

export const FeatureIntegrate = (props) => {
    return (
        <>
            <Container>
                <MasterySectionTitle>
                    Feature Integration
                </MasterySectionTitle>
                {
                    props.width > 850
                        ?
                        <SandwichHalf />
                        :
                        <SpacerTopBottomXXXL />
                }
                <FlexBoxSA>
                    <FlexBox style={{ alignItems: "normal", display: props.width > 1100 ? "flex" : "block" }}>
                        {
                            props.width > 1100
                            &&
                            <>
                                <MasteryFeatureBlock
                                    style={{
                                        width: 220,
                                        height: 510
                                    }}
                                >
                                    <FlexColumnSB style={{ height: "100%" }}>
                                        <div>
                                            <BodyWhite style={{ textAlign: "center", fontWeight: 500 }}>
                                                Social Media
                                            </BodyWhite>
                                            <SpacerTopBottomXL />
                                            <FlexBoxSA>
                                                <div>
                                                    <FlexBoxSA>
                                                        <ImgHolder
                                                            img={facebookIcon}
                                                            width={72}
                                                        />
                                                    </FlexBoxSA>
                                                    <SpacerTopBottomXXL />
                                                    <FlexBoxSA>
                                                        <ImgHolder
                                                            img={instagramIcon}
                                                            width={72}
                                                        />
                                                    </FlexBoxSA>
                                                    <SpacerTopBottomXXL />
                                                    <FlexBoxSA>
                                                        <ImgHolder
                                                            img={twitterIcon}
                                                            width={64}
                                                        />
                                                    </FlexBoxSA>
                                                    <SpacerTopBottomXXL />
                                                    <FlexBoxSA>
                                                        <ImgHolder
                                                            img={youtubeIcon}
                                                            width={72}
                                                        />
                                                    </FlexBoxSA>
                                                </div>
                                            </FlexBoxSA>
                                        </div>
                                        <div style={{
                                            textAlign: "right",
                                            color: "#FFFFFF"
                                        }}
                                            onMouseEnter={props.moreEntered}
                                            onMouseLeave={props.moreLeft}>
                                            (More...)
                                        </div>
                                    </FlexColumnSB>
                                </MasteryFeatureBlock>
                                <SpacerLeftRightMedium />
                            </>
                        }
                        {
                            props.width < 1100
                            &&
                            <>
                                <MasteryFeatureBlock
                                    style={{
                                        width: props.width > 850 ? 800 : "100%",
                                        height: 160
                                    }}
                                >
                                    <FlexBoxSB>
                                        <BodyWhite style={{ textAlign: "center", fontWeight: 500 }}>
                                            Social Media
                                        </BodyWhite>
                                        <div style={{
                                            textAlign: "right",
                                            color: "#FFFFFF"
                                        }}
                                            onMouseEnter={props.moreEntered}
                                            onMouseLeave={props.moreLeft}>
                                            (More...)
                                        </div>
                                    </FlexBoxSB>
                                    <SpacerTopBottomXL />
                                    <FlexBox>
                                        <FlexColumnSA style={{ height: 72 }}>
                                            <ImgHolder
                                                img={facebookIcon}
                                                width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                            />
                                        </FlexColumnSA>
                                        <SpacerLeftRightLarge />
                                        <FlexColumnSA style={{ height: 72 }}>
                                            <ImgHolder
                                                img={instagramIcon}
                                                width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                            />
                                        </FlexColumnSA>
                                        <SpacerLeftRightLarge />
                                        <FlexColumnSA style={{ height: 72 }}>
                                            <ImgHolder
                                                img={twitterIcon}
                                                width={props.width > 450 ? 72 : props.width > 370 ? 48 : 32}
                                            />
                                        </FlexColumnSA>
                                        <SpacerLeftRightLarge />
                                        <FlexColumnSA style={{ height: 72 }}>
                                            <ImgHolder
                                                img={youtubeIcon}
                                                width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                            />
                                        </FlexColumnSA>
                                    </FlexBox>
                                </MasteryFeatureBlock>
                                <SpacerTopBottomLarge />
                            </>
                        }
                        <div style={{
                            width: "100%"
                        }}>
                            <MasteryFeatureBlock
                                style={{
                                    width: props.width > 850 ? 800 : "100%",
                                    height: 160
                                }}
                            >
                                <FlexBoxSB>
                                    <BodyWhite style={{ textAlign: "center", fontWeight: 500 }}>
                                        Artificial Intelligence
                                    </BodyWhite>
                                    <div style={{
                                        textAlign: "right",
                                        color: "#FFFFFF"
                                    }}
                                        onMouseEnter={props.moreEntered}
                                        onMouseLeave={props.moreLeft}>
                                        (More...)
                                    </div>
                                </FlexBoxSB>
                                <SpacerTopBottomXL />
                                <FlexBox>
                                    <FlexColumnSA style={{ height: 72 }}>
                                        <ImgHolder
                                            img={chatGptIcon}
                                            width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                        />
                                    </FlexColumnSA>
                                    <SpacerLeftRightLarge />
                                    <FlexColumnSA style={{ height: 72 }}>
                                        <ImgHolder
                                            img={midjourneyIcon}
                                            width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                        />
                                    </FlexColumnSA>
                                    <SpacerLeftRightLarge />
                                    <FlexColumnSA style={{ height: 72 }}>
                                        <ImgHolder
                                            img={runwayIcon}
                                            width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                        />
                                    </FlexColumnSA>
                                    <SpacerLeftRightLarge />
                                    <FlexColumnSA style={{ height: 72 }}>
                                        <ImgHolder
                                            img={didIcon}
                                            width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                        />
                                    </FlexColumnSA>
                                </FlexBox>
                            </MasteryFeatureBlock>
                            <SpacerTopBottomLarge />
                            <FlexBox>
                                <div>
                                    <MasteryFeatureBlock
                                        style={{
                                            width: props.width > 850 ? 400 : "100%",
                                            height: 160
                                        }}
                                    >
                                        <FlexBoxSB>
                                            <BodyWhite style={{ textAlign: "center", fontWeight: 500 }}>
                                                Analytics
                                            </BodyWhite>
                                            <div style={{
                                                textAlign: "right",
                                                color: "#FFFFFF"
                                            }}
                                                onMouseEnter={props.moreEntered}
                                                onMouseLeave={props.moreLeft}>
                                                (More...)
                                            </div>
                                        </FlexBoxSB>
                                        <SpacerTopBottomXL />
                                        <FlexBox>
                                            <FlexColumnSA style={{ height: 72 }}>
                                                <ImgHolder
                                                    img={gaIcon}
                                                    width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                                />
                                            </FlexColumnSA>
                                            <SpacerLeftRightLarge />
                                            <FlexColumnSA style={{ height: 72 }}>
                                                <ImgHolder
                                                    img={marinIcon}
                                                    width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                                />
                                            </FlexColumnSA>
                                        </FlexBox>
                                    </MasteryFeatureBlock>
                                    <SpacerTopBottomLarge />
                                    <MasteryFeatureBlock
                                        style={{
                                            width: props.width > 850 ? 400 : "100%",
                                            height: 160
                                        }}
                                    >
                                        <FlexBoxSB>
                                            <BodyWhite style={{ textAlign: "center", fontWeight: 500 }}>
                                                CRM
                                            </BodyWhite>
                                            <div style={{
                                                textAlign: "right",
                                                color: "#FFFFFF"
                                            }}
                                                onMouseEnter={props.moreEntered}
                                                onMouseLeave={props.moreLeft}>
                                                (More...)
                                            </div>
                                        </FlexBoxSB>
                                        <SpacerTopBottomXL />
                                        <FlexBox>
                                            <FlexColumnSA style={{ height: 72 }}>
                                                <ImgHolder
                                                    img={hubspotIcon}
                                                    width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                                />
                                            </FlexColumnSA>
                                            <SpacerLeftRightLarge />
                                            <FlexColumnSA style={{ height: 72 }}>
                                                <ImgHolder
                                                    img={mailchimpIcon}
                                                    width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                                />
                                            </FlexColumnSA>
                                        </FlexBox>
                                    </MasteryFeatureBlock>
                                </div>
                                <SpacerLeftRightMedium />
                                {
                                    props.width > 850
                                    &&
                                    <FlexBox>
                                        <MasteryFeatureBlock
                                            style={{
                                                width: 184,
                                                height: 334
                                            }}
                                        >
                                            <FlexColumnSB style={{ height: "100%" }}>
                                                <div>
                                                    <BodyWhite style={{ textAlign: "center", fontWeight: 500 }}>
                                                        Payment
                                                    </BodyWhite>
                                                    <SpacerTopBottomXL />
                                                    <FlexBoxSA>
                                                        <div>
                                                            <FlexBoxSA>
                                                                <ImgHolder
                                                                    img={stripeIcon}
                                                                    width={72}
                                                                />
                                                            </FlexBoxSA>
                                                            <SpacerTopBottomXXL />
                                                            <FlexBoxSA>
                                                                <ImgHolder
                                                                    img={razorpayIcon}
                                                                    width={72}
                                                                />
                                                            </FlexBoxSA>
                                                        </div>
                                                    </FlexBoxSA>
                                                </div>
                                            </FlexColumnSB>
                                        </MasteryFeatureBlock>
                                        <SpacerLeftRightMedium />
                                        <MasteryFeatureBlock
                                            style={{
                                                width: 184,
                                                height: 334
                                            }}
                                        >
                                            <FlexColumnSB style={{ height: "100%" }}>
                                                <div>
                                                    <BodyWhite style={{ textAlign: "center", fontWeight: 500 }}>
                                                        Support
                                                    </BodyWhite>
                                                    <SpacerTopBottomXL />
                                                    <FlexBoxSA>
                                                        <div>
                                                            <FlexBoxSA>
                                                                <ImgHolder
                                                                    img={zendeskIcon}
                                                                    width={72}
                                                                />
                                                            </FlexBoxSA>
                                                            <SpacerTopBottomXXL />
                                                            <FlexBoxSA>
                                                                <ImgHolder
                                                                    img={intercomIcon}
                                                                    width={72}
                                                                />
                                                            </FlexBoxSA>
                                                        </div>
                                                    </FlexBoxSA>
                                                </div>
                                            </FlexColumnSB>
                                        </MasteryFeatureBlock>
                                    </FlexBox>
                                }
                                {
                                    props.width < 850
                                    &&
                                    <div>
                                        <MasteryFeatureBlock
                                            style={{
                                                width: props.width > 850 ? 400 : "100%",
                                                height: 160
                                            }}
                                        >
                                            <FlexBoxSB>
                                                <BodyWhite style={{ textAlign: "center", fontWeight: 500 }}>
                                                    Payment
                                                </BodyWhite>
                                                <div style={{
                                                    textAlign: "right",
                                                    color: "#FFFFFF"
                                                }}
                                                    onMouseEnter={props.moreEntered}
                                                    onMouseLeave={props.moreLeft}>
                                                    (More...)
                                                </div>
                                            </FlexBoxSB>
                                            <SpacerTopBottomXL />
                                            <FlexBox>
                                                <FlexColumnSA style={{ height: 72 }}>
                                                    <ImgHolder
                                                        img={stripeIcon}
                                                        width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                                    />
                                                </FlexColumnSA>
                                                <SpacerLeftRightLarge />
                                                <FlexColumnSA style={{ height: 72 }}>
                                                    <ImgHolder
                                                        img={razorpayIcon}
                                                        width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                                    />
                                                </FlexColumnSA>
                                            </FlexBox>
                                        </MasteryFeatureBlock>
                                        <SpacerTopBottomLarge />
                                        <MasteryFeatureBlock
                                            style={{
                                                width: props.width > 850 ? 400 : "100%",
                                                height: 160
                                            }}
                                        >
                                            <FlexBoxSB>
                                                <BodyWhite style={{ textAlign: "center", fontWeight: 500 }}>
                                                    Support
                                                </BodyWhite>
                                                <div style={{
                                                    textAlign: "right",
                                                    color: "#FFFFFF"
                                                }}
                                                    onMouseEnter={props.moreEntered}
                                                    onMouseLeave={props.moreLeft}>
                                                    (More...)
                                                </div>
                                            </FlexBoxSB>
                                            <SpacerTopBottomXL />
                                            <FlexBox>
                                                <FlexColumnSA style={{ height: 72 }}>
                                                    <ImgHolder
                                                        img={zendeskIcon}
                                                        width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                                    />
                                                </FlexColumnSA>
                                                <SpacerLeftRightLarge />
                                                <FlexColumnSA style={{ height: 72 }}>
                                                    <ImgHolder
                                                        img={intercomIcon}
                                                        width={props.width > 450 ? 72 : props.width > 370 ? 56 : 40}
                                                    />
                                                </FlexColumnSA>
                                            </FlexBox>
                                        </MasteryFeatureBlock>
                                    </div>
                                }
                            </FlexBox>
                        </div>
                    </FlexBox>
                </FlexBoxSA>
            </Container>
        </>
    )
}
