import React from 'react'
import { AbsoluteCenter, FlexBox, FlexBoxSA, FlexColumnSA } from '../../../global/style/global.styles'
import { ClientInfoBlockContainer, StoryHighlightsText } from '../style/stories.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer'

export const ClientInfo = (props) => {
    return (
        <>
            <div>
                <FlexBox>
                    <ClientInfoBlockContainer>
                        <FlexColumnSA style={{ height: "100%" }}>
                            <div>
                                <ImgHolder
                                    img={props.data?.countryFlag}
                                    width="100%"
                                />
                                <SpacerTopBottomLarge />
                                <StoryHighlightsText>
                                    {props.data?.country}
                                </StoryHighlightsText>
                            </div>
                        </FlexColumnSA>
                    </ClientInfoBlockContainer>
                    <SpacerLeftRightMedium />
                    <ClientInfoBlockContainer
                        href={props.data?.website && props.data?.website}
                        target='_blank'>
                        <AbsoluteCenter>
                            <FlexBoxSA>
                                <ImgHolder
                                    img={props.data?.logo}
                                />
                            </FlexBoxSA>
                            <SpacerTopBottomLarge />
                            <StoryHighlightsText style={{
                                fontSize: props.width > 450 ? 20 : 16
                            }}>
                                {props.data?.legalName}
                            </StoryHighlightsText>
                        </AbsoluteCenter>
                    </ClientInfoBlockContainer>
                </FlexBox>
                <SpacerTopBottomLarge />
                <FlexBox>
                    <ClientInfoBlockContainer>
                        <ImgHolder
                            img={props.data?.industryIcon}
                            width="100%"
                        />
                    </ClientInfoBlockContainer>
                    <SpacerLeftRightMedium />
                    <ClientInfoBlockContainer href={props.data?.partner && props.data?.partnerLink} target='_blank'>
                        <FlexColumnSA style={{ height: "100%" }}>
                            <div>
                                <ImgHolder
                                    img={props.data?.partner ? props.data?.partnerIcon : props.data?.sectorIcon}
                                    width="100%"
                                />
                                {
                                    props.data?.partner
                                    &&
                                    <>
                                        <SpacerTopBottomLarge />
                                        <StoryHighlightsText style={{
                                            fontSize: props.width > 450 ? 20 : 16
                                        }}>
                                            {props.data?.partner}
                                        </StoryHighlightsText>
                                    </>
                                }
                            </div>
                        </FlexColumnSA>
                    </ClientInfoBlockContainer>
                </FlexBox>
            </div>
        </>
    )
}
