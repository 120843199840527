import React from 'react'
import { AboutAbstractCoverContainer } from '../style/about.styles'
import { ImgHolder } from '../../../global/components/imgHolder'

export const AboutAbstractCover = (props) => {
  return (
    <>
        <AboutAbstractCoverContainer />
    </>
  )
}
