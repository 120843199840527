import React, { useEffect, useRef } from 'react'
import { BriefAboutContainer, BriefAboutText, BriefAboutVideo, BriefAboutWrapper } from '../style/landing.styles'
import creativeVideoThumb from "../../../assets/images/creativeVideoThumb.png"
import { FlexColumnSA } from '../../../global/style/global.styles'
import { useOnScreen } from '../../../global/components/useOnScreen'

export const BriefAbout = (props) => {

    const videoRef = useRef();
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

    const briefVideoSplineRef = useRef();
    const isOnScreen = useOnScreen(briefVideoSplineRef);

    return (
        <BriefAboutWrapper ref={briefVideoSplineRef}>
            <BriefAboutVideo
                ref={videoRef}
                loop
                autoPlay
                muted
                playsInline
                poster={creativeVideoThumb}>
                {
                    isOnScreen
                    &&
                    <source
                        src={"https://res.cloudinary.com/dz3yljetu/video/upload/v1707805365/creativeVideo_kwrarc.mp4"}
                        type="video/mp4"
                    />
                }
            </BriefAboutVideo>
            <BriefAboutContainer>
                <FlexColumnSA style={{ height: "100%" }}>
                    <div>
                        <BriefAboutText>
                            We bridge the gap
                        </BriefAboutText>
                        <BriefAboutText>
                            between
                        </BriefAboutText>
                        <BriefAboutText>
                            imagination and reality
                        </BriefAboutText>
                    </div>
                </FlexColumnSA>
            </BriefAboutContainer>
        </BriefAboutWrapper>
    )
}
