import React from 'react'
import styled from 'styled-components'

export const ImgHolder = (props) => {

    return (
        <>
            <ImgHolderContainer
                style={{ width: props.width, height: props.height }}
                onClick={props.clicked}>
                <Img src={props.img} style={{ borderRadius: props.borderRadius }} />
            </ImgHolderContainer>
        </>
    )
}

const ImgHolderContainer = styled.div`
    
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
`;




