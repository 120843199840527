import React from 'react'
import { Container } from '../../../global/style/global.styles'
import { Button, Grid } from '@mui/material'
import { MasterySectionSubTitle, MasterySectionTitle } from '../style/mastery.styles'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer'
import { ImgHolder } from '../../../global/components/imgHolder'
import exOrderImg from '../../../assets/images/fiscal-service-logo.svg'

export const EmvExOrder = (props) => {
    return (
        <>
            <Container>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <MasterySectionTitle>
                            Executive Order 13681 And EMV Terminal Deployment
                        </MasterySectionTitle>
                        <SpacerTopBottomLarge />
                        <MasterySectionSubTitle style={{ textAlign: "justify" }}>
                            With the signing of Executive Order 13681 in October 2014, payment card acceptance by agencies at the point of sale (POS) will need to convert from magnetic stripe-based terminals to EMV "chip and PIN" technology to strengthen the security of consumer data and better protect citizens doing business with the federal government.
                        </MasterySectionSubTitle>
                        <SpacerTopBottomLarge />
                        <a
                            href='https://fiscal.treasury.gov/cas/emv-deployment.html'
                            target='_blank'
                            style={{ textDecoration: "none" }}>
                            <Button
                                variant='contained'
                                size='large'
                                sx={{
                                    cursor: "none",
                                    backgroundColor: 'rgba(255, 245, 229, 0.9)',
                                    color: '#DF9912',
                                    ':hover': {
                                        bgcolor: 'rgba(255, 153, 0, 0.8)',
                                        color: 'white',
                                    }
                                }}
                                onMouseEnter={props.btnEnter}
                                onMouseLeave={props.btnLeft}>
                                About Executive Order 13681
                            </Button>
                        </a>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ImgHolder
                            img={exOrderImg}
                            width={"100%"}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
