import React from 'react'
import { AbsoluteCenterBottom, Caption, FlexBox } from '../style/global.styles'
import { SpacerRightSmall } from '../../design/spacer/spacer'
import MouseRoundedIcon from '@mui/icons-material/MouseRounded'; 

export const ScrollIndicator = (props) => {
    return (
        <>
            <AbsoluteCenterBottom style={{ paddingBottom: 40 }}>
                <FlexBox>
                    <MouseRoundedIcon
                        sx={{
                            fontSize: 12
                        }}
                    />
                    <SpacerRightSmall />
                    <Caption>
                        Scroll
                    </Caption>
                </FlexBox>
            </AbsoluteCenterBottom>
        </>
    )
}
