import React, { useEffect, useState } from 'react'
import { MasteryBgContainer, MasteryWrapper } from './style/mastery.styles'
import { ImgHolder } from '../../global/components/imgHolder'
import { motion } from 'framer-motion'
import ScrollToTop from '../../global/components/scrollToTop'
import { Navbar } from '../../global/components/navigation/navbar'
import { MasteryHeading } from './components/masteryHeading'
import devBg from '../../assets/images/developmentTitleBg.png'
import { MasteryRecommend } from './components/masteryRecommend'
import { Footer } from '../../global/components/footer'
import { GeneralArch } from './components/generalArch'
import { AppDeploy } from './components/appDeploy'
import { FeatureIntegrate } from './components/featureIntegrate'
import { ServicesContact } from '../services/components/servicesContact'
import masteryBg from "../../assets/images/masteryBg.png"
import masteryBgMob from "../../assets/images/masteryBgMob.png"
import { Helmet } from 'react-helmet'

export const Development = (props) => {

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorVariant, setCursorVariant] = useState("default")

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove)
    }

    setCursorVariant("default")
  }, [])

  const variants = {
    default: {
      x: mousePosition.x - 10,
      y: mousePosition.y - 10,
      mixBlendMode: "difference"
    },
    diffCursor: {
      width: 100,
      height: 100,
      x: mousePosition.x - 50,
      y: mousePosition.y - 50,
      backgroundColor: "white",
      mixBlendMode: "difference"
    },
    normalCursor: {
      width: 0,
      height: 0,
    }
  }

  const compEntered = () => setCursorVariant("diffCursor")
  const compLeave = () => setCursorVariant("default")

  return (
    <>
      <Helmet>
        <meta name="description" content="Elevate your enterprise with cutting-edge software and mobile app development solutions. Our seasoned team delivers scalable, secure, and innovative solutions tailored to your business needs, ensuring seamless integration and unparalleled performance in today's dynamic digital landscape." />
        <title>Inspirise | Systematic  Design</title>
      </Helmet>
      <MasteryWrapper>
        <ScrollToTop />
        <MasteryBgContainer>
          <ImgHolder
            img={props.width > 850 ? masteryBg : masteryBgMob}
            width="100%"
            height="100%"
          />
        </MasteryBgContainer>
        <Navbar
          color="white"
          logoEntered={compEntered}
          logoLeave={compLeave}
          navBarEntered={compEntered}
          navBarLeave={compLeave}
        />
        <MasteryHeading
          heading="DEVELOPMENT"
          background={`url(${devBg})`}
          right={props.width < 450 && "5%"}
        />
        <GeneralArch
          width={props.width}
        />
        <AppDeploy
          width={props.width}
        />
        <FeatureIntegrate
          width={props.width}
          moreEntered={compEntered}
          moreLeft={compLeave}
        />
        <ServicesContact
          width={props.width}
          location={props.location}
          variant="filled"
          color="white"
          inputFieldEntered={compEntered}
          inputFieldLeft={compLeave}
        />
        <MasteryRecommend
          location={props.location}
          title="You may also be interested in"
          blockEntered={compEntered}
          blockLeave={compLeave}
        />
        <Footer
          variant="flipWhite"
          footerEntered={compEntered}
          footerLeave={compLeave}
          backToTopBtnEntered={compEntered}
          backToTopBtnLeave={compLeave}
          inputFieldEntered={compEntered}
          inputFieldLeft={compLeave}
        />
        {
          props.width > 850
          &&
          <motion.div
            style={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "white",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 2,
              pointerEvents: "none"
            }}
            variants={variants}
            animate={cursorVariant}
          />
        }
      </MasteryWrapper>
      <MasteryWrapper>
        <ScrollToTop />
        <MasteryBgContainer>
          <ImgHolder
            img={props.width > 850 ? masteryBg : masteryBgMob}
            width="100%"
            height="100%"
          />
        </MasteryBgContainer>
        <Navbar
          color="white"
          logoEntered={compEntered}
          logoLeave={compLeave}
          navBarEntered={compEntered}
          navBarLeave={compLeave}
        />
        <MasteryHeading
          heading="DEVELOPMENT"
          background={`url(${devBg})`}
          right={props.width < 450 && "5%"}
        />
        <GeneralArch
          width={props.width}
        />
        <AppDeploy
          width={props.width}
        />
        <FeatureIntegrate
          width={props.width}
          moreEntered={compEntered}
          moreLeft={compLeave}
        />
        <ServicesContact
          width={props.width}
          location={props.location}
          variant="filled"
          color="white"
          inputFieldEntered={compEntered}
          inputFieldLeft={compLeave}
        />
        <MasteryRecommend
          location={props.location}
          title="You may also be interested in"
          blockEntered={compEntered}
          blockLeave={compLeave}
        />
        <Footer
          variant="flipWhite"
          footerEntered={compEntered}
          footerLeave={compLeave}
          backToTopBtnEntered={compEntered}
          backToTopBtnLeave={compLeave}
          inputFieldEntered={compEntered}
          inputFieldLeft={compLeave}
        />
        {
          props.width > 850
          &&
          <motion.div
            style={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "white",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 2,
              pointerEvents: "none"
            }}
            variants={variants}
            animate={cursorVariant}
          />
        }
      </MasteryWrapper>
    </>
  )
}
