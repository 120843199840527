import styled from 'styled-components'

export const StoryDetailsPosition = styled.div`
    position: absolute;
    top: 100vh;
`;

export const StoryDetailsContainer = styled.div`
    width: 100vw;

    @media only screen and (max-width: 850px) {
        width: inherit;
    }
`;

export const StoryDetailsHeading = styled.div`
    font-family: 'Roboto Condensed', sans-serif;
    color: white;
    font-size: 32px;
    font-weight: 100;
`;

export const StoryDetailsInfoWrapper = styled.div`
    width: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.1);
`;

export const StoryLandingLinkWrapper = styled.a`
    text-decoration: none;
`;

export const StoryLandingLinkContainer = styled.div`
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;

    &:hover {
        transition: all 0.3s ease;
        background-color: rgba(255, 255, 255, 0.6);
    }
    &:not(:hover) {
        transition: all 0.5s ease;
    }

    @media only screen and (max-width: 650px) {
        width: 100%;
        height: 80px;
        border-radius: 0;
    }
`;

export const StoryCardTitlePosition = styled.div`
    position: absolute;
    bottom: 48px;
    left: 48px;

    @media only screen and (max-width: 850px) {
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
    @media only screen and (max-width: 650px) {
       
    }
    @media only screen and (max-width: 450px) {
        
    }
`;

export const StoryCardTitle = styled.div`
    color: white;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes.subChildHeadline};
    letter-spacing: 0.02em;

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.childHeadline};
    }
    @media only screen and (max-width: 1100px) {
        font-size: 56px;
    }
    @media only screen and (max-width: 850px) {
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.heading};
    }
    @media only screen and (max-width: 370px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const StoryCardLogoPosition = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
`;

export const StoryCardLogoContainer = styled.div`
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
`;

export const StoriesVideo = styled.video`
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
`;

export const StoryServiceBlockTitle = styled.div`
    font-family: 'Roboto Condensed', sans-serif;    
    font-size: 24px;
    font-weight: ${(props) => props.theme.fontWeights.light};
    color: ${(props) => props.theme.colors.text.white};
    text-align: center;
`;

export const StoryHighlightsContainer = styled.div`
    background-color: rgba(255, 255, 255, 0.5);
`;

export const StoryHighlightsBlock = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[6]};
    padding-right: ${(props) => props.theme.space[6]};

    @media only screen and (max-width: 850px) {
        padding-bottom: ${(props) => props.theme.space[6]};
    }
`;

export const StoryHighlightsNum = styled.div`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 104px;
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const StoryHighlightsText = styled.div`
    font-size: 20px;
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    text-align: center;
`;

export const StoryHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.light};

    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.subHeading};
    }
`;

export const StoryPara = styled.div`
    font-size: 24px;
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.02em;
    line-height: 40px;
    text-align: justify;

    @media only screen and (max-width: 850px) {
        width: 100%;
        text-align: left;
        font-size: 20px;
        line-height: 38px;
    }
`;

export const ClientInfoBlockContainer = styled.a`
    width: 200px;
    height: 200px;
    background-color: rgba(138, 138, 146, 0.25);
    border-radius: 4px;
    position: relative;
    text-decoration: none;

    @media only screen and (max-width: 450px) {
        width: 160px;
        height: 160px;
    }

    @media only screen and (max-width: 370px) {
        width: 130px;
        height: 130px;
    }
`;

export const WorkWrapper = styled.div`
    position: relative;
    overflow: hidden;
`;

export const WorkMobBannerContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const WorkMobBlockContainer = styled.a`
    border-radius: 8px;
    width: 320px;
    height: 500px;
    position: relative;

    @media only screen and (max-width: 370px) {
        width: 280px;
    }
`;

export const WorkMobBlockFocusContainer = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[4]};
    padding-right: ${(props) => props.theme.space[4]};
    width: 320px;
    height: 500px;
    border-radius: 8px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.3086484593837535) 30%, rgba(0,0,0,0) 100%);

    @media only screen and (max-width: 370px) {
        width: 280px;
    }
`;