import Spline from '@splinetool/react-spline'
import React from 'react'
import { Cube3DText, ExpertiseSplineContainer } from '../style/landing.styles'
import { AbsoluteBottomRight, AbsoluteCenter, Container, RelativeContainer } from '../../../global/style/global.styles'
// import interactAnim from "../../../assets/animations/Interact.json"
// import Lottie from 'react-lottie'

const ExpertiseSpline = (props) => {

    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: interactAnim,
    //     rendererSettings: {
    //         preserveAspectRatio: "xMidYMid slice"
    //     }
    // };

    return (
        <ExpertiseSplineContainer>
            {
                props.width < 370
                    ?
                    <Spline
                        scene="https://prod.spline.design/3kpiuLOQ-rW5Xh1B/scene.splinecode"
                        style={{
                            width: "100%",
                            height: "140vh",
                            zIndex: 1,
                            cursor: "grab"
                        }}
                    />
                    :
                    props.width < 850
                        ?
                        <Spline
                            scene="https://prod.spline.design/3kpiuLOQ-rW5Xh1B/scene.splinecode"
                            style={{
                                width: "100%",
                                height: "120vh",
                                zIndex: 1,
                                cursor: "grab"
                            }}
                        />
                        :
                        props.width < 1100
                            ?
                            <Spline
                                scene="https://prod.spline.design/Fbbe1LIUIoYgY1Wg/scene.splinecode"
                                style={{
                                    width: "100%",
                                    height: "120vh",
                                    zIndex: 1,
                                    cursor: "grab"
                                }}
                            /> :
                            <Spline
                                scene="https://prod.spline.design/EvltrSz2ybYYpjoM/scene.splinecode"
                                style={{
                                    width: "100%",
                                    height: "120vh",
                                    zIndex: 1,
                                    cursor: "grab"
                                }}
                            />
            }
            {/* <AbsoluteBottomRight style={{ padding: 80 }}>
                <RelativeContainer>
                    <Lottie
                        options={defaultOptions}
                        width={64}
                        height={64}
                        speed={0.8}
                        isClickToPauseDisabled={true}
                    />
                </RelativeContainer>
            </AbsoluteBottomRight> */}
        </ExpertiseSplineContainer>
    )
}

export default ExpertiseSpline
