import React from 'react'
import { FeaturedBlogBlockContainer, FeaturedBlogFocusContainer, FeaturedBlogTitle } from '../style/landing.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { AbsoluteCenter, FlexColumn } from '../../../global/style/global.styles'

export const FeaturedBlogBlock = (props) => {
    return (
        <FeaturedBlogBlockContainer
            style={{ flexShrink: 0 }}
            onMouseEnter={props.blogPostEntered}
            onMouseLeave={props.blogPostLeave}>
            <a href={`https://blog.inspirise.com/${props.id}`} target='_blank' style={{ textDecoration: "none" }}>
                <ImgHolder
                    img={props.thumbnail}
                    width="100%"
                    height="100%"
                />
                <AbsoluteCenter>
                    <FeaturedBlogFocusContainer>
                        <FlexColumn style={{ height: "100%", justifyContent: "flex-end" }}>
                            <FeaturedBlogTitle>
                                {props.title}
                            </FeaturedBlogTitle>
                        </FlexColumn>
                    </FeaturedBlogFocusContainer>
                </AbsoluteCenter>
            </a>
        </FeaturedBlogBlockContainer>
    )
}
