import React from 'react'

export const NavIconBuild = (props) => {
    return (
        <>
            <div style={{ position: "relative", width: 96, height: 16 }}>
                <div
                    style={{
                        width: 64,
                        height: 3,
                        backgroundColor: props.color,
                        borderRadius: 3,
                        position: "absolute"
                    }}
                />
                <div
                    style={{
                        width: 64,
                        height: 3,
                        backgroundColor: props.color,
                        borderRadius: 3,
                        position: "absolute",
                        bottom: 0,
                        right: 0
                    }}
                />
            </div>
        </>
    )
}
