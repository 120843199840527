import React, { useEffect, useRef } from 'react'
import { Container, FlexBox, Sandwich, SandwichHalf } from '../../../global/style/global.styles'
import { Grid } from '@mui/material'
import { MasterySectionSubTitle, MasterySectionTitle, MasteryVideo } from '../style/mastery.styles'
import { SpacerLeftRightSmall, SpacerTopBottomLarge, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { ImgHolder } from '../../../global/components/imgHolder'
import socialSorting from '../../../assets/images/socialSorting.png'
import custHappy from '../../../assets/images/custHappy.png'

export const UserExperienceSection = (props) => {

  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  })

  return (
    <>
      <Container>
        <Grid container alignItems="center" spacing={8}>
          {
            props.width < 850
            &&
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {
                props.width < 850
                &&
                <>
                  <MasterySectionTitle>
                    User-Centric,
                    <br />
                    Crafting Digital Experiences with Purpose
                  </MasterySectionTitle>
                  <SpacerTopBottomLarge />
                </>
              }
              <FlexBox style={{ justifyContent: props.width > 650 ? "normal" : "center" }}>
                <ImgHolder
                  img={socialSorting}
                  width={300}
                  borderRadius={4}
                />
              </FlexBox>
              {
                props.width > 850
                  ?
                  <SandwichHalf />
                  :
                  <SpacerTopBottomXXXL />
              }
              <FlexBox style={{ justifyContent: props.width > 650 ? "right" : "center" }}>
                <MasteryVideo
                  ref={videoRef}
                  loop
                  autoPlay
                  muted
                  playsInline
                  style={{ width: 310 }}>
                  <source
                    src={"https://res.cloudinary.com/dz3yljetu/video/upload/v1707805308/humanMind2_zfcjen.mp4"}
                    type="video/mp4"
                  />
                </MasteryVideo>
                {/* <div
                  style={{
                    backgroundColor: "red",
                    width: 300,
                    height: 300
                  }}
                /> */}
              </FlexBox>
              {
                props.width > 850
                  ?
                  <SandwichHalf />
                  :
                  <SpacerTopBottomXXXL />
              }
              <FlexBox style={{ justifyContent: props.width > 650 ? "normal" : "center" }}>
                <ImgHolder
                  img={custHappy}
                  width={340}
                />
                {/* <SpacerLeftRightSmall />
              <ImgHolder
                img={blackManImg}
                width={200}
                height={200}
                borderRadius={4}
              />
              <SpacerLeftRightSmall />
              <ImgHolder
                img={indianGirlImg}
                width={200}
                height={200}
                borderRadius={4}
              />
              <SpacerLeftRightSmall />
              <ImgHolder
                img={chineseManImg}
                width={200}
                height={200}
                borderRadius={4}
              /> */}
              </FlexBox>
            </Grid>
          }
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div>
              {
                props.width > 850
                &&
                <>
                  <MasterySectionTitle>
                    User-Centric,
                    <br />
                    Crafting Digital Experiences with Purpose
                  </MasterySectionTitle>
                  <SpacerTopBottomLarge />
                </>
              }
              <MasterySectionSubTitle style={{ textAlign: props.width > 850 ? "justify" : "left" }}>
                Guided by user research, we navigate unexplored realms in the pursuit of user discovery. Much akin to adept cartographers, we meticulously map each touchpoint, comprehending the nuanced dynamics that mold the user experience.
                <br />
                Our exploration extends into the profound depths of the human psyche, delving into cognitive intricacies, emotional resonances, and the delicate interplay between user thoughts and digital interfaces. The outcome is the creation of experiences characterized by intuitiveness, efficiency, and a seamless joy to navigate.
                <br />
                As we secure our position in the harbor of design philosophy, a profound realization dawns upon us. The essence of design extends beyond aesthetics—it lies in the artful orchestration of interactions that resonate with and elevate the user satisfaction and delight.
              </MasterySectionSubTitle>
            </div>
          </Grid>
          {
            props.width > 850
            &&
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <FlexBox>
                <ImgHolder
                  img={socialSorting}
                  width={300}
                  borderRadius={4}
                />
              </FlexBox>
              <SandwichHalf />
              <FlexBox style={{ justifyContent: "right" }}>
                <MasteryVideo
                  ref={videoRef}
                  loop
                  autoPlay
                  muted
                  playsInline
                  style={{ width: 310 }}>
                  <source
                    src={"https://res.cloudinary.com/dz3yljetu/video/upload/v1707805308/humanMind2_zfcjen.mp4"}
                    type="video/mp4"
                  />
                </MasteryVideo>
                {/* <div
                  style={{
                    backgroundColor: "red",
                    width: 300,
                    height: 300
                  }}
                /> */}
              </FlexBox>
              <SandwichHalf />
              <FlexBox>
                <ImgHolder
                  img={custHappy}
                  width={340}
                />
                {/* <SpacerLeftRightSmall />
              <ImgHolder
                img={blackManImg}
                width={200}
                height={200}
                borderRadius={4}
              />
              <SpacerLeftRightSmall />
              <ImgHolder
                img={indianGirlImg}
                width={200}
                height={200}
                borderRadius={4}
              />
              <SpacerLeftRightSmall />
              <ImgHolder
                img={chineseManImg}
                width={200}
                height={200}
                borderRadius={4}
              /> */}
              </FlexBox>
            </Grid>
          }
        </Grid>
      </Container>
    </>
  )
}
