import React, { useContext, useEffect, useRef, useState } from 'react'
import ScrollToTop from '../../global/components/scrollToTop'
import { HeroSpline } from './components/heroSpline'
import { HeroSection } from './components/heroSection'
import { Navbar } from '../../global/components/navigation/navbar'
import { BriefAbout } from './components/briefAbout'
import { BriefAboutWindow, LandingWrapper } from './style/landing.styles'
import { SuccessStoryPreview } from './components/successStoryPreview'
import { ExpertiseSection } from './components/expertiseSection'
import { BlogSection } from './components/blogSection'
import "./style/landing.css"
import { Footer } from '../../global/components/footer'
import { BackToTop } from '../../global/components/backToTop'
import { motion } from 'framer-motion'
import { LoadingScreen } from '../../global/components/loadingScreen'
import { CustomCursor } from '../../global/components/customCursor'
import { Stories } from '../../assets/data/stories'
import { SuccessStoryPreviewNormal } from './components/successStoryPreviewNormal'
import { ApiContext } from '../../context/api/api.provider'
import { AbsoluteCenter } from '../../global/style/global.styles'
import ControlCameraRoundedIcon from '@mui/icons-material/ControlCameraRounded';

export const Landing = (props) => {

    const { blogs } = useContext(ApiContext)

    const [loading, setLoading] = useState(true)
    const [splineEntered, setSplineEntered] = useState(false)

    setTimeout(() => {
        setLoading(false)
    }, 3000);

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    })
    const [cursorVariant, setCursorVariant] = useState("default")

    useEffect(() => {
        const mouseMove = (e) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }

        window.addEventListener("mousemove", mouseMove);
        return () => {
            window.removeEventListener("mousemove", mouseMove)
        }

        setCursorVariant("default")
    }, [])

    const variants = {
        default: {
            x: mousePosition.x - 10,
            y: mousePosition.y - 10
        },
        diffCursor: {
            width: 100,
            height: 100,
            x: mousePosition.x - 50,
            y: mousePosition.y - 50,
            backgroundColor: "white",
            mixBlendMode: "difference"
        },
        normalCursor: {
            width: 0,
            height: 0,
        }
    }

    const compEntered = () => setCursorVariant("diffCursor")
    const compLeave = () => setCursorVariant("default")

    const handleSplineEntered = () => {
        compEntered()
        setSplineEntered(true)
    }

    const handleSplineLeave = () => {
        compLeave()
        setSplineEntered(false)
    }

    return (
        <>
            {
                loading
                &&
                <LoadingScreen />
            }
            <ScrollToTop />
            {/* <CustomCursor /> */}
            <BriefAbout
                width={props.width}
            />
            <HeroSection
                width={props.width}
                height={props.height}
                navigate={props.navigate}
                heroSplineLoading={props.heroSplineLoading}
                heroSplineEntered={handleSplineEntered}
                heroSplineLeave={handleSplineLeave}
                navBarEntered={compEntered}
                navBarLeave={compLeave}
                logoEntered={compEntered}
                logoLeave={compLeave}
                ctaBtnEntered={compEntered}
                ctaBtnLeave={compLeave}
                infoMenuEntered={compEntered}
                infoMenuLeave={compLeave}
            />
            <BriefAboutWindow />
            {/* <SuccessStoryPreview
                width={props.width}
                stories={Stories}
                readMoreStoryEntered={compEntered}
                readMoreStoryLeave={compLeave}
                viewAllStoryEntered={compEntered}
                viewAllStoryLeave={compLeave}
            /> */}
            <SuccessStoryPreviewNormal
                width={props.width}
                stories={Stories}
                readMoreStoryEntered={compEntered}
                readMoreStoryLeave={compLeave}
                viewAllStoryEntered={compEntered}
                viewAllStoryLeave={compLeave}
            />
            <ExpertiseSection
                width={props.width}
                expertiseSplineEntered={handleSplineEntered}
                expertiseSplineLeave={handleSplineLeave}
            />
            <BlogSection
                width={props.width}
                location={props.location}
                content={blogs?.filter((x) => x.status === "active" && x.feature > 0).sort((a, b) => a.feature - b.feature).slice(0, 4)}
                blogPostEntered={compEntered}
                blogPostLeave={compLeave}
            />
            <Footer
                footerEntered={compEntered}
                footerLeave={compLeave}
                backToTopBtnEntered={compEntered}
                backToTopBtnLeave={compLeave}
                inputFieldEntered={compEntered}
                inputFieldLeft={compLeave}
            />
            {
                props.width > 850
                &&
                <motion.div
                    style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        backgroundColor: "black",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        zIndex: 2,
                        pointerEvents: "none"
                    }}
                    variants={variants}
                    animate={cursorVariant}
                >
                    {
                        splineEntered
                        &&
                        <AbsoluteCenter>
                            <ControlCameraRoundedIcon
                                fontSize='large'
                            />
                        </AbsoluteCenter>
                    }
                </motion.div>
            }
        </>
    )
}

