import Spline from '@splinetool/react-spline'
import React from 'react'

export const CenterGlowSpline = (props) => {
    return (
        <>
            {
                props.width > 850
                    ?
                    <Spline
                        scene="https://prod.spline.design/nDhtdhdyqGuNvoNz/scene.splinecode"
                        style={{
                            position: "absolute",
                            right: 0,
                            bottom: -500,
                            width: 300,
                            height: 300,
                            zIndex: 1
                        }}
                    />
                    :
                    <Spline
                        scene="https://prod.spline.design/nDhtdhdyqGuNvoNz/scene.splinecode"
                        style={{
                            position: "absolute",
                            bottom: 0,
                            left: "50%",
                            transform: "translate(-50%,0)",
                            width: 300,
                            height: 300,
                            zIndex: 1
                        }}
                    />
            }
            {/* <div
                style={{
                    backgroundColor: "red",
                    width: 300,
                    height: 300
                }}
            /> */}
        </>
    )
}