import Spline from '@splinetool/react-spline'
import React from 'react'

export const GhostSpline = (props) => {
    return (
        <>
            {
                props.width > 850
                    ?
                    <Spline
                        scene="https://prod.spline.design/ZaKs8NmUQOtJdSZt/scene.splinecode"
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            width: "100%",
                            height: "100%",
                            zIndex: 1,
                            cursor: "crosshair"
                        }}
                    />
                    :
                    <Spline
                        scene="https://prod.spline.design/zg1xoHTZuaaJUGqJ/scene.splinecode"
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            width: "100%",
                            height: 900,
                            zIndex: 1,
                            cursor: "crosshair"
                        }}
                    />
            }
            {/* <div
                style={{
                    backgroundColor: "red",
                    width: 300,
                    height: 300
                }}
            /> */}
        </>
    )
}
