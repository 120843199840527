import React from 'react'
import { AboutUsContainer, AboutUsPara, AboutUsTitle, CityOutlinedText } from '../style/about.styles'
import { Container } from '../../../global/style/global.styles'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer'

export const AboutUs = (props) => {
    return (
        <AboutUsContainer>
            <Container>
                <AboutUsTitle>
                    About Us
                </AboutUsTitle>
                <SpacerTopBottomLarge />
                <AboutUsPara style={{ width: props.width > 850 ? "90%" : "100%" }}>
                    Inspirise Innovations Pvt. Ltd. (Inspirise) stands as a pioneering technology company dedicated to forging a digitally interconnected world. Our mission revolves around empowering both entrepreneurs and enterprises by simplifying and automating their operations, thus enabling a steadfast focus on sustainable growth.
                    <br />
                    Founded in Bangalore in 2022, Inspirise is a startup that excels in nurturing brands from their inception, providing them with indispensable digital tools to navigate and thrive in the modern business landscape. Renowned for our avant-garde UX designs, we adhere to a rigorous regimen in software development and quality assurance, ensuring that our end products stand out as paragons of excellence on a global scale. Our team comprises state-of-the-art professionals adept at leveraging the latest marketing strategies to precisely position and promote your brand to the right audience.
                    <br />
                    As a global entity, our reach extends across the Americas and Asia-Pacific, attesting to our commitment to delivering top-tier solutions internationally. Our expertise spans diverse sectors, and we steadfastly adhere to industry-grade best practices, ensuring that our clients receive innovative and reliable services that propel their businesses forward. At Inspirise, we are not just a technology company; we are architects of digital transformation, facilitating the journey towards a connected and prosperous future.
                </AboutUsPara>
            </Container>
            <div style={
                props.width > 850
                    ?
                    { position: "absolute", right: 40, bottom: 0 }
                    :
                    { textAlign: "right" }
            }>
                <CityOutlinedText>
                    BANGALORE
                </CityOutlinedText>
            </div>
        </AboutUsContainer>
    )
}
