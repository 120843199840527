import { collection, getDocs } from 'firebase/firestore';
import React, { createContext, useState, useEffect, useContext } from 'react'
import { db } from '../../firebase/firebase';

export const ApiContext = createContext()

export const ApiProvider = ({ children }) => {

    const [blogs, setBlogs] = useState([]);
    const [attribution, setAttribution] = useState([]);

    const [loading, setLoading] = useState(true)
    const [loadingBlog, setLoadingBlog] = useState(true);
    const [loadingAttribution, setLoadingAttribution] = useState(true);

    const storiesCollectionRef = collection(db, "stories");
    const leadsCollectionRef = collection(db, "leads");
    const newsSubscribersCollectionRef = collection(db, "newsletter-subscribers");
    const blogsCollectionRef = collection(db, "blogs");
    const attributionCollectionRef = collection(db, "attribution");

    useEffect(() => {
        try {
            const getBlogs = async () => {
                const data = await getDocs(blogsCollectionRef);
                setBlogs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
            };
            getBlogs()

            const getAttribution = async () => {
                const data = await getDocs(attributionCollectionRef);
                setAttribution(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
            };
            getAttribution()

        } catch (error) {
            const { code, message } = error;
            console.log(error);
        }
    }, [])

    return (
        <ApiContext.Provider
            value={{
                loading,
                setLoading,
                blogs,
                blogsCollectionRef,
                newsSubscribersCollectionRef,
                leadsCollectionRef,
                attribution,
                attributionCollectionRef
            }}
        >
            {children}
        </ApiContext.Provider>
    )
}