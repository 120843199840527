import React from 'react'
import { EcommerceServiceTitle, ServiceWrapper, ServicesWrapper, TestingServiceTitle, WebsiteServiceTitle, WebsiteServiceWrapper } from '../style/services.styles'
import { Sandwich } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { Layer } from '../../../global/components/layer'
import { ServiceLinkBtn } from './serviceLinkBtn'
import stServiceImg from '../../../assets/images/stService.png'
import stServiceMobImg from '../../../assets/images/stServiceMob.png'

export const TestingService = (props) => {
    return (
        <Sandwich>
            <ServiceWrapper style={{ height: props.width > 850 ? 1030 : props.width > 650 ? 800 : props.width > 370 ? 1300 : 1170 }}>
                <TestingServiceTitle
                    style={{
                        position: "absolute",
                        left: "50%",
                        bottom: props.width > 1100 ? 160 : props.width > 850 ? 240 : props.width > 650 ? 220 : props.width > 450 ? 190 : props.width > 370 ? 170 : 150,
                        transform: "translate(-50%, 0)",
                        width: props.width > 850 ? 695 : props.width > 650 ? 610 : props.width > 450 ? 440 : props.width > 370 ? 315 : 280,
                        zIndex: 3
                    }}>
                    SOFTWARE
                </TestingServiceTitle>
                <TestingServiceTitle
                    style={{
                        position: "absolute",
                        left: "50%",
                        bottom: props.width > 1100 ? 0 : 88,
                        transform: "translate(-50%, 0)",
                        width: props.width > 850 ? 540 : props.width > 650 ? 475 : props.width > 450 ? 340 : props.width > 370 ? 244 : 220
                    }}>
                    TESTING
                </TestingServiceTitle>
                {
                    props.width > 650
                        ?
                        <div style={{
                            position: "absolute",
                            left: "50%",
                            top: 0,
                            transform: "translate(-50%, 0)",
                            zIndex: 2
                        }}>
                            <ImgHolder
                                img={stServiceImg}
                                width={props.wdith > 1100 ? 1220 : props.width > 850 ? 1000 : props.width > 650 ? 800 : 600}
                            />
                        </div>
                        :
                        <div style={{
                            position: "absolute",
                            left: "50%",
                            top: 0,
                            transform: "translate(-50%, 0)",
                            zIndex: 2
                        }}>
                            <ImgHolder
                                img={stServiceMobImg}
                                width={props.width > 370 ? 400 : 360}
                            />
                        </div>
                }
                <a
                    href='/mastery/testing'
                    target='_blank'
                    style={{
                        position: "absolute",
                        right: props.width > 450 ? 112 : 40,
                        bottom: 0
                    }}
                    onMouseEnter={props.serviceLinkEntered}
                    onMouseLeave={props.serviceLinkLeave}>
                    <ServiceLinkBtn />
                </a>
            </ServiceWrapper>
        </Sandwich>
    )
}
