import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall } from '../../design/spacer/spacer'
import { AttributionWrapper, BodyG80, Container, SubTitleBold } from '../../global/style/global.styles'
import { Helmet } from "react-helmet";
import { ApiContext } from '../../context/api/api.provider';
import { Navbar } from '../../global/components/navigation/navbar';

const Attribution = () => {

  const { attribution } = useContext(ApiContext)

  return (
    <AttributionWrapper>
      <Navbar
        color="black"
      />
      <Container>
        {/* <Helmet>
        <title>Inspirise | Attribution</title>
      </Helmet> */}
        <h1>Attribution</h1>
        <SpacerTopBottomLarge />
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container sx={{ marginBottom: '16px' }}>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '2px solid black' }}>
                <div style={{ fontSize: 20, fontWeight: 700 }}>
                  Particular
                </div>
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '2px solid black' }}>
                <div style={{ fontSize: 20, fontWeight: 700 }}>
                  Source / Author
                </div>
              </Grid>
            </Grid>
            {
              attribution?.map((item, index) => (
                <Grid container sx={{ marginBottom: '8px' }} key={index}>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
                    <div>
                      {item.particular}
                    </div>
                  </Grid>
                  <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
                    <a href={item.link} target='_blank'>
                      <div>
                        {item.source}
                      </div>
                    </a>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
        <SpacerTopBottomLarge />
        <h2>For any request or grievance, contact below</h2>
        <SpacerTopBottomMedium />
        <ul>
          <li style={{ padding: "4px 0" }}>
            <p>By email: office@inspirise.com</p>
          </li>
          <li style={{ padding: "4px 0" }}>
            <p>By visiting this page on our website: <a href="https://www.inspirise.com/contact" rel="external nofollow noopener" target="_blank">https://www.inspirise.com/contact</a></p>
          </li>
          <li style={{ padding: "4px 0" }}>
            <p>By phone number: +91 9380450912</p>
          </li>
          <li style={{ padding: "4px 0" }}>
            <p>By mail: #74, Phoenix, Hennur main road, Bangalore 560043</p>
          </li>
        </ul>
      </Container>
    </AttributionWrapper>
  )
}

export default Attribution