import React, { useEffect, useState } from 'react'
import { MasteryBgContainer, MasteryWrapper } from './style/mastery.styles'
import { ImgHolder } from '../../global/components/imgHolder'
import { motion } from 'framer-motion'
import ScrollToTop from '../../global/components/scrollToTop'
import { Navbar } from '../../global/components/navigation/navbar'
import { MasteryHeading } from './components/masteryHeading'
import testBg from '../../assets/images/testingTitleBg.png'
import { MasteryRecommend } from './components/masteryRecommend'
import { Footer } from '../../global/components/footer'
import { ContTesting } from './components/contTesting'
import { EmvTesting } from './components/emvTesting.jsx'
import { Sandwich } from '../../global/style/global.styles.js'
import { EmvExOrder } from './components/emvExOrder.jsx'
import { SuccessStoryPreviewNormal } from '../landing/components/successStoryPreviewNormal.jsx'
import { Stories } from '../../assets/data/stories.js'
import { ServicesContact } from '../services/components/servicesContact.jsx'
import masteryBg from "../../assets/images/masteryBg.png"
import masteryBgMob from "../../assets/images/masteryBgMob.png"
import { Helmet } from 'react-helmet'

export const Testing = (props) => {

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorVariant, setCursorVariant] = useState("default")

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove)
    }

    setCursorVariant("default")
  }, [])

  const variants = {
    default: {
      x: mousePosition.x - 10,
      y: mousePosition.y - 10,
      mixBlendMode: "difference"
    },
    diffCursor: {
      width: 100,
      height: 100,
      x: mousePosition.x - 50,
      y: mousePosition.y - 50,
      backgroundColor: "white",
      mixBlendMode: "difference"
    },
    normalCursor: {
      width: 0,
      height: 0,
    }
  }

  const compEntered = () => setCursorVariant("diffCursor")
  const compLeave = () => setCursorVariant("default")

  return (
    <>
      <Helmet>
        <meta name="description" content="Ensure seamless transactions and robust security with our specialized software testing solutions tailored for the payments sector. From rigorous EMV testing to PCI compliance checks, our expert team ensures flawless performance, reliability, and regulatory adherence, safeguarding your payment systems and enhancing customer" />
        <title>Inspirise | Meticulous Software Testing</title>
      </Helmet>
      <MasteryWrapper>
        <ScrollToTop />
        <MasteryBgContainer>
          <ImgHolder
            img={props.width > 850 ? masteryBg : masteryBgMob}
            width="100%"
            height="100%"
          />
        </MasteryBgContainer>
        <Navbar
          color="white"
          logoEntered={compEntered}
          logoLeave={compLeave}
          navBarEntered={compEntered}
          navBarLeave={compLeave}
        />
        <MasteryHeading
          heading="TESTING"
          background={`url(${testBg})`}
        />
        <EmvTesting />
        <EmvExOrder
          btnEnter={compEntered}
          btnLeft={compLeave}
        />
        {/* <ContTesting /> */}
        <Sandwich>
          <SuccessStoryPreviewNormal
            width={props.width}
            stories={Stories}
            readMoreStoryEntered={compEntered}
            readMoreStoryLeave={compLeave}
            viewAllStoryEntered={compEntered}
            viewAllStoryLeave={compLeave}
          />
        </Sandwich>
        <ServicesContact
          width={props.width}
          location={props.location}
          variant="filled"
          color="white"
          inputFieldEntered={compEntered}
          inputFieldLeft={compLeave}
        />
        <MasteryRecommend
          location={props.location}
          title="You may also be interested in"
          blockEntered={compEntered}
          blockLeave={compLeave}
        />
        <Footer
          variant="flipWhite"
          footerEntered={compEntered}
          footerLeave={compLeave}
          backToTopBtnEntered={compEntered}
          backToTopBtnLeave={compLeave}
          inputFieldEntered={compEntered}
          inputFieldLeft={compLeave}
        />
        {
          props.width > 850
          &&
          <motion.div
            style={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "white",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 2,
              pointerEvents: "none"
            }}
            variants={variants}
            animate={cursorVariant}
          />
        }
      </MasteryWrapper>
    </>
  )
}
