import styled from 'styled-components'

export const ServicesWrapper = styled.div`
    position: relative;
    overflow-x: hidden;
`;

export const ServicesBgContainer = styled.div`
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
`;

export const ServicesHeroSectionWrapper = styled.div`
    position: relative;
    height: 100vh;
`;

export const ServicesHeroGraphLeftPosition = styled.div`
    position: absolute;
    left: 40%;
    top: 30%;
    transform: translate(-36%,-30%);

    @media only screen and (min-width: 1800px) {
        left: 39%;
    }
    @media only screen and (max-width: 1600px) {
        top: 31%;
    }
    @media only screen and (max-width: 1300px) {
        left: 38%;
        top: 31%;
    }
    @media only screen and (max-width: 1100px) {
        left: 35%;
    }
    @media only screen and (max-width: 850px) {
        left: 31%;
        top: 37%;
    }
    @media only screen and (max-width: 650px) {
        left: 25%;
    }
    @media only screen and (max-width: 450px) {
        left: 24%;
        top: 38%;
    }
    @media only screen and (max-width: 350px) {
        left: 24%;
        top: 36%;
    }
`;

export const ServicesHeroGraphRightPosition = styled.div`
   position: absolute;
    left: 60%;
    top: 30%;
    transform: translate(-64%,-30%);

    @media only screen and (min-width: 1800px) {
        left: 61%;
    }
    @media only screen and (max-width: 1600px) {
        top: 31%;
    }
    @media only screen and (max-width: 1300px) {
        left: 62%;
        top: 31%;
    }
    @media only screen and (max-width: 1100px) {
        left: 65%;
    }
    @media only screen and (max-width: 850px) {
        left: 69%;
        top: 37%;
    }
    @media only screen and (max-width: 650px) {
        left: 75%;
    }
    @media only screen and (max-width: 450px) {
        left: 76%;
        top: 38%;
    }
    @media only screen and (max-width: 350px) {
        left: 76%;
        top: 36%;
    }
`;

export const ServicesHeroGraphBottomPosition = styled.div`
    position: absolute;
    left: 50%;
    top: 72%;
    transform: translate(-50%,-72%);

    @media only screen and (max-width: 850px) {
        top: 65%;
    }
    @media only screen and (max-width: 650px) {
        top: 70%;
    }
    @media only screen and (max-width: 450px) {
        top: 66%;
    }
    @media only screen and (max-width: 350px) {
        top: 70%;
    }
`;

export const ServicesHeroGraphContainer = styled.div`
    position: relative;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    border: 1px solid black;

    @media only screen and (min-width: 1800px) {
        width: 400px;
        height: 400px;
    }
    @media only screen and (max-width: 450px) {
        width: 200px;
        height: 200px;
    }
    @media only screen and (max-width: 370px) {
        width: 180px;
        height: 180px;
    }
`;

export const ServicesHeroGraphTitle = styled.div`
    font-family: 'Pacifico';
    font-size: 28px;
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.02rem;

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.heading};
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
    @media only screen and (max-width: 370px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
    }
`;

export const ServicesTaglineTextNormal = styled.div`
    font-size: 64px;
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    @media only screen and (min-width: 1800px) {
        font-size: 80px;
    }
    @media only screen and (max-width: 1100px) {
        font-size: 48px;
    }
    @media only screen and (max-width: 850px) {
        font-size: 40px;
    }
    @media only screen and (max-width: 650px) {
        font-size: 32px;
        line-height: 48px;
    }
    @media only screen and (max-width: 450px) {
        
    }
    @media only screen and (max-width: 350px) {
        font-size: 24px;
        line-height: 40px;
    }
`;

export const ServicesTaglineTextCurvy = styled.div`
    font-family: 'Pacifico';
    font-size: 67px;
    color: ${(props) => props.theme.colors.text.grey80};
    margin: 0 20px;

    @media only screen and (min-width: 1800px) {
        font-size: 83px;
    }
    @media only screen and (max-width: 1100px) {
        font-size: 51px;
        margin: 0 16px;
    }
    @media only screen and (max-width: 850px) {
        font-size: 43px;
        margin: 0 14px;
    }
`;

export const ServiceWrapper = styled.div`
    position: relative;
`;

export const WebsiteServiceTitle = styled.div`
    font-family: 'Roboto Condensed', sans-serif;    
    font-size: 160px;
    font-weight: ${(props) => props.theme.fontWeights.extraBold};
    font-style: italic;
    letter-spacing: -0.02em;
    background: linear-gradient(180deg, #0009EB 0%, rgba(203, 55, 255, 0.37) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;   

    @media only screen and (max-width: 850px) {
        font-size: 140px;
    }
    @media only screen and (max-width: 650px) {
        font-size: 100px;
    }
    @media only screen and (max-width: 450px) {
        font-size: 72px;
    }
    @media only screen and (max-width: 370px) {
        font-size: 64px;
    }
`;

export const EcommerceServiceTitle = styled.div`
    font-family: 'Roboto Condensed', sans-serif;    
    font-size: 160px;
    font-weight: ${(props) => props.theme.fontWeights.extraBold};
    font-style: italic;
    letter-spacing: -0.02em;
    background: linear-gradient(180deg, #00E1D3 0%, rgba(37, 85, 255, 0.37) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media only screen and (max-width: 850px) {
        font-size: 140px;
    }
    @media only screen and (max-width: 650px) {
        font-size: 100px;
    }
    @media only screen and (max-width: 450px) {
        font-size: 72px;
    }
    @media only screen and (max-width: 370px) {
        font-size: 64px;
    }
`;

export const WebAppServiceTitle = styled.div`
    font-family: 'Roboto Condensed', sans-serif;    
    font-size: 160px;
    font-weight: ${(props) => props.theme.fontWeights.extraBold};
    font-style: italic;
    letter-spacing: -0.02em;   
    background: linear-gradient(180deg, #0C9709 0%, rgba(235, 255, 0, 0.37) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media only screen and (max-width: 850px) {
        font-size: 140px;
    }
    @media only screen and (max-width: 650px) {
        font-size: 100px;
    }
    @media only screen and (max-width: 450px) {
        font-size: 72px;
    }
    @media only screen and (max-width: 370px) {
        font-size: 64px;
    }
`;

export const MobileAppServiceTitle = styled.div`
    font-family: 'Roboto Condensed', sans-serif;    
    font-size: 160px;
    font-weight: ${(props) => props.theme.fontWeights.extraBold};
    font-style: italic;
    letter-spacing: -0.02em;
    background: linear-gradient(180deg, #E10000 0%, rgba(255, 128, 37, 0.37) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media only screen and (max-width: 850px) {
        font-size: 140px;
    }
    @media only screen and (max-width: 650px) {
        font-size: 100px;
    }
    @media only screen and (max-width: 450px) {
        font-size: 72px;
    }
    @media only screen and (max-width: 370px) {
        font-size: 64px;
    }
`;

export const MarketingServiceTitle = styled.div`
    font-family: 'Roboto Condensed', sans-serif;    
    font-size: 160px;
    font-weight: ${(props) => props.theme.fontWeights.extraBold};
    font-style: italic;
    letter-spacing: -0.02em;
    background: linear-gradient(180deg, #E10079 0%, rgba(238, 37, 255, 0.37) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media only screen and (max-width: 850px) {
        font-size: 140px;
    }
    @media only screen and (max-width: 650px) {
        font-size: 100px;
    }
    @media only screen and (max-width: 450px) {
        font-size: 72px;
    }
    @media only screen and (max-width: 370px) {
        font-size: 64px;
    }
`;

export const TestingServiceTitle = styled.div`
    font-family: 'Roboto Condensed', sans-serif;    
    font-size: 160px;
    font-weight: ${(props) => props.theme.fontWeights.extraBold};
    font-style: italic;
    letter-spacing: -0.02em;
    background: linear-gradient(180deg, #7D7D7D 0%, rgba(0, 220, 207, 0.37) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media only screen and (max-width: 850px) {
        font-size: 140px;
    }
    @media only screen and (max-width: 650px) {
        font-size: 100px;
    }
    @media only screen and (max-width: 450px) {
        font-size: 72px;
    }
    @media only screen and (max-width: 370px) {
        font-size: 64px;
    }
`;

export const ServiceLinkBtnContainer = styled.div`
    width: 96px;
    height: 96px;
    position: relative;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 2px 5px 0px rgba(124,124,124,0.75);
-webkit-box-shadow: 0px 2px 5px 0px rgba(124,124,124,0.75);
-moz-box-shadow: 0px 2px 5px 0px rgba(124,124,124,0.75);
    &:hover {
        background-color: black;
        transition: all 0.3s ease;
    }
    &:not(:hover) {
        background-color: white;
        transition: all 0.5s ease;
    }
`;

