import React from 'react'
import { MarkHeadingContainer, MarkHeadingWrapper, MasterySectionMartTitle } from '../style/mastery.styles'
import { TitleBold, TitleSemiBold, TitleSemiBoldG40 } from '../../../global/style/global.styles'

export const MarkHeading = (props) => {
    return (
        <MarkHeadingWrapper>
            <MarkHeadingContainer>
                <MasterySectionMartTitle>{props.heading}</MasterySectionMartTitle>
            </MarkHeadingContainer>
        </MarkHeadingWrapper>
    )
}
