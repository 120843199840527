import React from 'react'
import { HeroCTABtnContainer, HeroCTABtnText } from '../style/landing.styles'
import { BodyMediumWhite, FlexBox } from '../../../global/style/global.styles'

export const HeroCTABtn = (props) => {
    return (
        <a href={props.link} style={{ textDecoration: "none" }}>
            <FlexBox>
                <HeroCTABtnContainer
                    onMouseEnter={props.ctaBtnEntered}
                    onMouseLeave={props.ctaBtnLeave}
                    onClick={props.clicked}>
                    <HeroCTABtnText>
                        Get in touch.
                    </HeroCTABtnText>
                </HeroCTABtnContainer>
            </FlexBox>
        </a>
    )
}
