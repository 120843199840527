import React from 'react'
import { Container, FlexColumnSA } from '../../../global/style/global.styles'
import { Grid } from '@mui/material'
import { ContactFormV2 } from './contactFormV2'
import { ContactHeading, ContactSubHeading } from '../style/contact.styles'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer'

export const TalkToUs = (props) => {
    return (
        <>
            <Container>
                <Grid container alignItems="center">
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <ContactHeading style={{
                                fontSize: props.width > 1100 ? 96 : props.width > 450 ? 80 : props.width > 370 ? 56 : 48,
                                lineHeight: props.width>450?"88px":props.width>370?"64px":"56px"
                            }}>
                                Start a
                                <br />
                                conversation
                            </ContactHeading>
                            <SpacerTopBottomLarge />
                            <ContactSubHeading>
                                Let us know what you are looking for and we would love to help!
                            </ContactSubHeading>
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ContactFormV2
                            width={props.width}
                            location={props.location}
                            compEntered={props.compEntered}
                            compLeave={props.compLeave}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
