import React, { useEffect, useRef, useState } from 'react'
import { AbsoluteCenter, AbsoluteCenterTop, Container, FlexBoxSA, RelativeContainer } from '../../../global/style/global.styles'
import { CircularProgress, Grid } from '@mui/material'
import { MasterySectionSubTitle, MasterySectionTitle } from '../style/mastery.styles'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer'
import { EarthSpline } from './earthSpline'
import { GhostSpline } from './ghostSpline'
import { GastlySpline } from './gastlySpline'
import { CenterGlowSpline } from './centerGlowSpline'
import useIsVisible from '../../../global/components/useIsVisible'
import { Alert, AlertTitle, Button, IconButton, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useOnScreen } from '../../../global/components/useOnScreen'
import { LoadingScreen } from '../../../global/components/loadingScreen'

export const ThreeDModelling = (props) => {

    const [loading, setLoading] = useState(true)

    const ThreeDModellingRef = useRef();
    const isVisible = useIsVisible(ThreeDModellingRef);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 4000);
    }, [])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>

            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const threeDModSplineRef = useRef();
    const isOnScreen = useOnScreen(threeDModSplineRef);

    useEffect(() => {
        if (isOnScreen) {
            setTimeout(() => {
                setLoading(false)
            }, 3000);
        }
    }, [isOnScreen])

    return (
        <>
            <div style={{ position: "relative" }} ref={threeDModSplineRef}>
                {
                    props.width > 850
                    &&
                    isOnScreen
                    &&
                    <GhostSpline
                        width={props.width}
                        isOnScreen={isOnScreen}
                    />
                }
                {/* <GastlySpline /> */}
                <div style={{ margin: props.width > 850 ? "80px 0" : 0 }}>
                    <Container>
                        <Grid container alignItems="center" spacing={8}>
                            {
                                props.width > 850
                                &&
                                isOnScreen
                                &&
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ position: "relative" }}>
                                    {
                                        loading
                                        &&
                                        <AbsoluteCenterTop>
                                            <CircularProgress />
                                        </AbsoluteCenterTop>
                                    }
                                    <EarthSpline
                                        width={props.width}
                                        earthElementEntered={props.earthElementEntered}
                                        earthElementLeave={props.earthElementLeave}
                                    />
                                    <CenterGlowSpline
                                        width={props.width}
                                    />
                                </Grid>
                            }
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div>
                                    <RelativeContainer>
                                        <MasterySectionTitle>
                                            Interactive 3D Modeling,
                                            <br />
                                            Imagination in the 3rd Dimension
                                        </MasterySectionTitle>
                                        <SpacerTopBottomLarge />
                                        {
                                            props.width < 850
                                            &&
                                            loading
                                            &&
                                            <FlexBoxSA>
                                                <CircularProgress />
                                            </FlexBoxSA>
                                        }
                                        <AbsoluteCenter>
                                            <div ref={ThreeDModellingRef} />
                                        </AbsoluteCenter>
                                        {
                                            props.width < 850
                                            &&
                                            isOnScreen
                                            &&
                                            <div style={{ position: "relative", height: 900 }}>
                                                <EarthSpline
                                                    width={props.width}
                                                />
                                                <GhostSpline
                                                    width={props.width}
                                                />
                                                <CenterGlowSpline
                                                    width={props.width}
                                                />
                                            </div>
                                        }
                                    </RelativeContainer>
                                    <MasterySectionSubTitle style={{ textAlign: props.width > 850 ? "justify" : "left" }}>
                                        In the dynamic realm of design and innovation, the evolution of interactive 3D modeling stands as a powerful catalyst for transforming imaginative concepts into tangible realities.
                                        <br />
                                        Unlike conventional design practices, it transforms static representations into dynamic, immersive experiences, fostering a profound connection between the designer, the users, and the virtual space. This interactive paradigm empowers designers to manipulate, iterate, and refine their creations in real-time, breathing life into their ideas with unprecedented fluidity.
                                        <br />
                                        By breaking down barriers and engaging designers in an immersive creative dialogue, this methodology paves the way for a new era of design where ideas flourish, innovation thrives, and the boundaries of imagination extend beyond the limits of the conventional.
                                    </MasterySectionSubTitle>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                {
                    props.width < 850
                    &&
                    isVisible
                    &&
                    <Snackbar
                        open={open}
                        autoHideDuration={3000}
                        onClose={handleClose}
                        action={action}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}>
                        <Alert severity="info" onClose={handleClose}>
                            <AlertTitle>3D Element</AlertTitle>
                            Explore interactions
                        </Alert>
                    </Snackbar>
                }
            </div>
        </>
    )
}
