import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { Navbar } from '../../global/components/navigation/navbar';
import { SpacerTopBottomLarge } from '../../design/spacer/spacer';
import { ServicesHeroSection } from './components/servicesHeroSection';
import { ServicesBgContainer, ServicesWrapper } from './style/services.styles';
import { ImgHolder } from '../../global/components/imgHolder';
import { ServicesTagline } from './components/servicesTagline';
import { WebsiteService } from './components/websiteService';
import { EcommerceService } from './components/ecommerceService';
import { WebAppService } from './components/webAppService';
import { MobileAppService } from './components/mobileAppService';
import { Footer } from '../../global/components/footer';
import { ServicesContact } from './components/servicesContact';
import { MarketingService } from './components/marketingService';
import { TestingService } from './components/testingService';
import ScrollToTop from '../../global/components/scrollToTop';
import servicesBg from '../../assets/images/servicesBg.png'
import servicesBgMob from '../../assets/images/servicesBgMob.png'
import { Helmet } from 'react-helmet';

export const Services = (props) => {

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    })
    const [cursorVariant, setCursorVariant] = useState("default")

    useEffect(() => {
        const mouseMove = (e) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }

        window.addEventListener("mousemove", mouseMove);
        return () => {
            window.removeEventListener("mousemove", mouseMove)
        }

        setCursorVariant("default")
    }, [])

    const variants = {
        default: {
            x: mousePosition.x - 10,
            y: mousePosition.y - 10
        },
        diffCursor: {
            width: 100,
            height: 100,
            x: mousePosition.x - 50,
            y: mousePosition.y - 50,
            backgroundColor: "white",
            mixBlendMode: "difference"
        },
        normalCursor: {
            width: 0,
            height: 0,
        }
    }

    const compEntered = () => setCursorVariant("diffCursor")
    const compLeave = () => setCursorVariant("default")

    return (
        <>
            <Helmet>
                <meta name="description" content="Elevate your brand with our comprehensive digital solutions. From bespoke website creation and enterprise/mobile app development to end-to-end marketing strategies encompassing SEO, SEM, and social media management, our expert team drives your online success. Contact us today for tailored digital excellence!" />
                <title>Inspirise | Services</title>
            </Helmet>
            <ScrollToTop />
            <ServicesWrapper>
                <ServicesBgContainer>
                    <ImgHolder
                        img={props.width > 650 ? servicesBg : servicesBgMob}
                        width="100%"
                        height="100%"
                    />
                </ServicesBgContainer>
                <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
                    <Navbar
                        color="black"
                        logoEntered={compEntered}
                        logoLeave={compLeave}
                        navBarEntered={compEntered}
                        navBarLeave={compLeave}
                    />
                </div>
                <ServicesHeroSection
                    width={props.width}
                />
                <ServicesTagline
                    width={props.width}
                />
                <WebsiteService
                    width={props.width}
                    serviceLinkEntered={compEntered}
                    serviceLinkLeave={compLeave}
                />
                <EcommerceService
                    width={props.width}
                    serviceLinkEntered={compEntered}
                    serviceLinkLeave={compLeave}
                />
                <WebAppService
                    width={props.width}
                    serviceLinkEntered={compEntered}
                    serviceLinkLeave={compLeave}
                />
                <MobileAppService
                    width={props.width}
                    serviceLinkEntered={compEntered}
                    serviceLinkLeave={compLeave}
                />
                <MarketingService
                    width={props.width}
                    serviceLinkEntered={compEntered}
                    serviceLinkLeave={compLeave}
                />
                <TestingService
                    width={props.width}
                    serviceLinkEntered={compEntered}
                    serviceLinkLeave={compLeave}
                />
                <ServicesContact
                    width={props.width}
                    variant="filled"
                    inputFieldEntered={compEntered}
                    inputFieldLeft={compLeave}
                />
                <Footer
                    variant="transparent"
                    footerEntered={compEntered}
                    footerLeave={compLeave}
                    backToTopBtnEntered={compEntered}
                    backToTopBtnLeave={compLeave}
                    inputFieldEntered={compEntered}
                    inputFieldLeft={compLeave}
                />
                {
                    props.width > 850
                    &&
                    <motion.div
                        style={{
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                            backgroundColor: "black",
                            position: "fixed",
                            top: 0,
                            left: 0,
                            zIndex: 2,
                            pointerEvents: "none"
                        }}
                        variants={variants}
                        animate={cursorVariant}
                    />
                }
            </ServicesWrapper>
        </>
    )
}
