import React from 'react'
import { StoryHighlightsBlock, StoryHighlightsContainer, StoryHighlightsNum, StoryHighlightsText } from '../style/stories.styles'
import { Grid } from '@mui/material'
import { AlignCenter, Container, FlexBox } from '../../../global/style/global.styles'
import { Divider } from '../../../global/components/divider'

export const StoryHighlights = (props) => {
    return (
        <Container>
            <StoryHighlightsContainer>
                {
                    props.width > 850
                        ?
                        <FlexBox>
                            {
                                props.data?.map((item, index) => (
                                    <div>
                                        <FlexBox style={{ justifyContent: props.width > 850 ? "normal" : "space-around" }}>
                                            <FlexBox>
                                                <StoryHighlightsBlock>
                                                    <AlignCenter>
                                                        <StoryHighlightsNum>
                                                            {item.num}
                                                        </StoryHighlightsNum>
                                                        <StoryHighlightsText>
                                                            {item.text}
                                                        </StoryHighlightsText>
                                                    </AlignCenter>
                                                </StoryHighlightsBlock>
                                                {

                                                    index !== props.data.length - 1
                                                    &&
                                                    props.width > 850
                                                    &&
                                                    <Divider
                                                        width={4}
                                                        height={120}
                                                        color="white"
                                                        borderRadius={4}
                                                    />
                                                }
                                            </FlexBox>
                                        </FlexBox>
                                    </div>
                                ))
                            }
                        </FlexBox>
                        :
                        <Grid container>
                            {
                                props.data?.map((item, index) => (
                                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}>
                                        <FlexBox style={{ justifyContent: props.width > 850 ? "normal" : "space-around" }}>
                                            <FlexBox>
                                                <StoryHighlightsBlock>
                                                    <AlignCenter>
                                                        <StoryHighlightsNum>
                                                            {item.num}
                                                        </StoryHighlightsNum>
                                                        <StoryHighlightsText>
                                                            {item.text}
                                                        </StoryHighlightsText>
                                                    </AlignCenter>
                                                </StoryHighlightsBlock>
                                                {

                                                    index !== props.data.length - 1
                                                    &&
                                                    props.width > 850
                                                    &&
                                                    <Divider
                                                        width={4}
                                                        height={120}
                                                        color="white"
                                                        borderRadius={4}
                                                    />
                                                }
                                            </FlexBox>
                                        </FlexBox>
                                    </Grid>
                                ))
                            }
                        </Grid>
                }
            </StoryHighlightsContainer>
        </Container>
    )
}
