import React from 'react'
import { AbsoluteCenter, FlexBoxSA, FlexColumnSE } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import testingIcon from '../../../assets/icons/testingIcon.png'
import { StoryServiceBlockTitle } from '../style/stories.styles'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer'

export const StoryServiceBlock = (props) => {

    return (
        <>
            <div className={
                props.mastery === "Design"
                    ?
                    "storyDesignBlockContainer"
                    :
                    props.mastery === "Development"
                        ?
                        "storyDevBlockContainer"
                        :
                        props.mastery === "Marketing"
                            ?
                            "storyMarkBlockContainer"
                            :
                            props.mastery === "Testing"
                            &&
                            "storyTestBlockContainer"
            }>
                <span />
                <div style={{ zIndex: 2 }}>
                    <FlexBoxSA>
                        <ImgHolder
                            img={props.mastery === "Testing" ? testingIcon : null}
                            width={64}
                        />
                    </FlexBoxSA>
                    <SpacerTopBottomLarge />
                    <StoryServiceBlockTitle>
                        {props.serviceName}
                    </StoryServiceBlockTitle>
                </div>
            </div>
        </>
    )
}
