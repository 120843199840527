import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Landing } from "./pages/landing/landing";

import Cookies from "./pages/others/cookies";
import NotFound from "./pages/others/notFound";
import Attribution from "./pages/others/attribution";
import PrivacyPolicy from "./pages/others/privacyPolicy";
import Terms from "./pages/others/terms";

import useWindowDimensions from "./global/components/screenSize";
import { useEffect, useState } from "react";
import { HeroSplineDragComp } from "./global/style/global.styles";
import { ImgHolder } from "./global/components/imgHolder";
import { Contact } from "./pages/contact/contact";
import { Story } from "./pages/ourWork/components/story";
import { Services } from "./pages/services/services";
import { About } from "./pages/about/about";
import { Design } from "./pages/mastery/design";
import { Development } from "./pages/mastery/development";
import { Marketing } from "./pages/mastery/marketing";
import { Testing } from "./pages/mastery/testing";
import { OurWork } from "./pages/ourWork/ourWork";
import { Work } from "./pages/ourWork/screens/work";
import { DigiFlair } from "./pages/digiFlair/digiFlair";

function App() {

  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();

  return (
    <>
      <Routes>
        <Route path="/" element={<Landing
          width={width}
          height={height}
          navigate={navigate}
          location={location}
        />} />
        <Route path="/about" element={<About
          width={width}
        />} />
        <Route path="/services" element={<Services
          width={width}
        />} />
        <Route path="/mastery/design" element={<Design
          width={width}
          height={height}
          navigate={navigate}
          location={location}
        />} />
        <Route path="/mastery/development" element={<Development
          width={width}
          height={height}
          navigate={navigate}
          location={location}
        />} />
        <Route path="/mastery/marketing" element={<Marketing
          width={width}
          height={height}
          navigate={navigate}
          location={location}
        />} />
        <Route path="/mastery/testing" element={<Testing
          width={width}
          height={height}
          navigate={navigate}
          location={location}
        />} />
        <Route path="/contact" element={<Contact
          width={width}
          location={location}
        />} />
        <Route path="/work" element={<OurWork
          width={width}
          height={height}
          navigate={navigate}
          location={location}
        />} />
        <Route path="/work/:workId" element={<Work
          width={width}
          location={location}
        />} />
        {/* <Route path="/stories/:storyId" element={<Story />} /> */}
        {/* <Route path="/digiFlair/references" element={<DigiFlair
          width={width}
          navigate={navigate}
          location={location}
        />} /> */}
        <Route path="/privacyPolicy" element={<PrivacyPolicy
          width={width}
          navigate={navigate}
          location={location}
        />} />
        <Route path="/cookies" element={<Cookies
          width={width}
          navigate={navigate}
          location={location}
        />} />
        <Route path="/terms" element={<Terms
          width={width}
          navigate={navigate}
          location={location}
        />} />
        <Route path="/attribution" element={<Attribution
          width={width}
          navigate={navigate}
          location={location}
        />} />
        <Route path="*" element={<NotFound
          width={width}
          navigate={navigate}
          location={location}
        />} />
      </Routes>
      {/* <motion.div
        style={{
          width: 20,
          height: 20,
          borderRadius: "50%",
          backgroundColor: location.pathname.includes("/stories") ? "white" : "black",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 2,
          pointerEvents: "none"
        }}
        variants={variants}
        animate={cursorVariant}
        transition={{
          delay: -3,
        }}
      >
        {
          cursorVariant === "heroSpline"
          &&
          <HeroSplineDragComp>
            <ImgHolder
              img={interactIcon}
              width={24}
              height={24}
            />
          </HeroSplineDragComp>
        }
      </motion.div> */}
    </>
  );
}

export default App;
