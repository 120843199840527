import React from 'react'
import { AbsoluteCenter } from '../../../global/style/global.styles'
import { ServicesHeroGraphContainer, ServicesHeroGraphTitle } from '../style/services.styles'

export const ServicesHeroGraph = (props) => {
  return (
    <>
        <ServicesHeroGraphContainer>
            <AbsoluteCenter>
                <ServicesHeroGraphTitle>
                    {props.title}
                </ServicesHeroGraphTitle>
            </AbsoluteCenter>
        </ServicesHeroGraphContainer>
    </>
  )
}
