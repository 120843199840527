import React, { useEffect, useRef, useState } from 'react'
import { AboutSpline } from './components/aboutSpline'
import { AboutFooterContainer, AboutHScrollContainer, AboutHScrollWrapper, AboutTagline, AboutTaglinesPosition, AboutWrapper, LogoPosition, NavContactPosition, NavHomePosition } from './style/about.styles'
import { AboutHero } from './components/aboutHero'
import ScrollToTop from '../../global/components/scrollToTop'
import { motion, useScroll, useTransform } from 'framer-motion'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { Tooltip } from '@mui/material'
import NavDrawer from '../../global/components/navigation/navDrawer'
import { FlexBox } from '../../global/style/global.styles'
import { SpacerLeftRightLarge, SpacerLeftRightMedium } from '../../design/spacer/spacer'
import LOGOWHITE from "../../assets/images/logoWhite.png"
import { IconHolder } from '../../global/components/iconHolder'
import { Navbar } from '../../global/components/navigation/navbar'
import { Footer } from '../../global/components/footer'
import { AboutHeroHScroll } from './components/aboutHeroHScroll'
import { AboutUs } from './components/aboutUs'
import { AboutAbstractCover } from './components/aboutAbstractCover'
import { AboutVision } from './components/aboutVision'
import { Helmet } from 'react-helmet'

export const About = (props) => {

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorVariant, setCursorVariant] = useState("default")

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove)
    }

    setCursorVariant("default")
  }, [])

  const variants = {
    default: {
      x: mousePosition.x - 10,
      y: mousePosition.y - 10,
      mixBlendMode: "difference"
    },
    diffCursor: {
      width: 100,
      height: 100,
      x: mousePosition.x - 50,
      y: mousePosition.y - 50,
      backgroundColor: "white",
      mixBlendMode: "difference"
    },
    normalCursor: {
      width: 0,
      height: 0,
    }
  }

  const compEntered = () => setCursorVariant("diffCursor")
  const compLeave = () => setCursorVariant("default")

  const targetRef = useRef()
  const { scrollYProgress } = useScroll({
    target: targetRef
  })
  const x = useTransform(scrollYProgress, [0, 1], ["0%", "-80%"])

  return (
    <>
      <Helmet>
        <meta name="description" content="Inspirise Innovations Pvt. Ltd. (Inspirise) stands as a pioneering technology company dedicated to forging a digitally interconnected world. Founded in Bangalore in 2022, Inspirise excels in nurturing brands from their inception, providing them with indispensable digital tools to navigate and thrive in the modern business landscape." />
        <title>Inspirise | About Us</title>
      </Helmet>
      <ScrollToTop />
      {
        props.width > 850
          ?
          <AboutHScrollWrapper ref={targetRef}>
            <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
              <Navbar
                variant="altWB"
                color="black"
                logoEntered={compEntered}
                logoLeave={compLeave}
                navBarEntered={compEntered}
                navBarLeave={compLeave}
              />
            </div>
            <AboutHScrollContainer>
              <motion.div style={{ x }}>
                <FlexBox>
                  <AboutHeroHScroll
                    width={props.width}
                  />
                  <AboutUs
                    width={props.width}
                  />
                  <AboutAbstractCover
                    width={props.width}
                  />
                  <AboutVision
                    width={props.width}
                  />
                  <AboutFooterContainer>
                    <Footer
                      variant="flipWhiteHScroll"
                      footerEntered={compEntered}
                      footerLeave={compLeave}
                      backToTopBtnEntered={compEntered}
                      backToTopBtnLeave={compLeave}
                      inputFieldEntered={compEntered}
                      inputFieldLeft={compLeave}
                    />
                  </AboutFooterContainer>
                </FlexBox>
              </motion.div>
            </AboutHScrollContainer>
          </AboutHScrollWrapper>
          :
          <>
            <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
              <Navbar
                color="white"
                logoEntered={compEntered}
                logoLeave={compLeave}
                navBarEntered={compEntered}
                navBarLeave={compLeave}
              />
            </div>
            <AboutHeroHScroll
              width={props.width}
            />
            <AboutUs
              width={props.width}
            />
            <AboutAbstractCover
              width={props.width}
            />
            <AboutVision
              width={props.width}
            />
            <Footer
              footerEntered={compEntered}
              footerLeave={compLeave}
              backToTopBtnEntered={compEntered}
              backToTopBtnLeave={compLeave}
              inputFieldEntered={compEntered}
              inputFieldLeft={compLeave}
            />
          </>
      }
      {
        props.width > 850
        &&
        <motion.div
          style={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: "white",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 2,
            pointerEvents: "none"
          }}
          variants={variants}
          animate={cursorVariant}
        />
      }
    </>
  )
}
