import React, { useEffect, useRef, useState } from 'react'
import './style/stories.css'

import { Navbar } from '../../global/components/navigation/navbar';
import ScrollToTop from '../../global/components/scrollToTop';
import { Carousel } from './components/carousel';
import { Story } from './components/story';
import { Stories } from '../../assets/data/stories'
import { motion } from 'framer-motion'
import { Gradient } from "whatamesh";
import { Footer } from '../../global/components/footer';
import { Button } from '@mui/material';
import { Container, FlexBoxSA } from '../../global/style/global.styles';
import { WorkMobBlock } from './components/workMobBlock';
import { Helmet } from 'react-helmet';

const gradient = new Gradient();

export const OurWork = (props) => {

  const [active, setActive] = useState(0);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      gradient.initGradient("#gradient-canvas");
    }
  }, [ref]);

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorVariant, setCursorVariant] = useState("default")

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove)
    }

    setCursorVariant("default")
  }, [])

  const variants = {
    default: {
      x: mousePosition.x - 10,
      y: mousePosition.y - 10,
      mixBlendMode: "difference"
    },
    diffCursor: {
      width: 100,
      height: 100,
      x: mousePosition.x - 50,
      y: mousePosition.y - 50,
      backgroundColor: "white",
      mixBlendMode: "difference"
    },
    normalCursor: {
      width: 0,
      height: 0,
    }
  }

  const compEntered = () => setCursorVariant("diffCursor")
  const compLeave = () => setCursorVariant("default")

  // function addEvent(obj, evt, fn) {
  //   if (obj.addEventListener) {
  //     obj.addEventListener(evt, fn, false);
  //   }
  //   else if (obj.attachEvent) {
  //     obj.attachEvent("on" + evt, fn);
  //   }
  // }
  // addEvent(window, "load", function (e) {
  //   addEvent(document, "mouseout", function (e) {
  //     e = e ? e : window.event;
  //     var from = e.relatedTarget || e.toElement;
  //     if (!from || from.nodeName == "HTML") {
  //       // stop your drag event here
  //       // for now we can just use an alert
  //       alert("left window");
  //     }
  //   });
  // });

  return (
    <>
      <Helmet>
        <meta name="description" content="Get an understanding of our standout work/projects. Understand how we can cater to your business needs and why you can trust us." />
        <title>Inspirise | Client Stories</title>
      </Helmet>
      <ScrollToTop />
      {
        props.width > 850
          ?
          <div className='storyContainer' ref={ref}>
            <div style={{
              position: 'fixed',
              top: 0,
              zIndex: -1
            }}>
              <canvas id="gradient-canvas" style={{ height: props.height }}></canvas>
            </div>
            <Navbar
              color="white"
              logoEntered={compEntered}
              logoLeave={compLeave}
              navBarEntered={compEntered}
              navBarLeave={compLeave}
            />
            <div className='storySlider'>
              <Carousel
                active={active}
                setActive={setActive}
                arrowEntered={compEntered}
                arrowLeave={compLeave}>
                {
                  Stories.map((item, index) => (
                    <Story
                      key={index}
                      width={props.width}
                      height={props.height}
                      navigate={props.navigate}
                      location={props.location}
                      storyLinkEnter={compEntered}
                      storyLinkLeave={compLeave}
                      active={active}
                      index={index}
                      title={item.name}
                      logo={item.client.logo}
                      dp={item.dp}
                      poster={item.dp.poster}
                      storySeq={item.storySeq}
                      highlights={item.highlights}
                      landingPage={item.landingPage}
                      services={item.services}
                      highlights={item.story.highlights}
                      context={item.story.context}
                      solution={item.story.solution}
                      outcome={item.story.outcome}
                      usp={item.story.usp}
                      client={item.client}
                      compEntered={compEntered}
                      compLeave={compLeave}
                    />
                  ))
                }
              </Carousel>
            </div>
          </div>
          :
          <div ref={ref}>
            <div style={{
              position: 'fixed',
              top: 0,
              zIndex: -1
            }}>
              <canvas id="gradient-canvas" style={{ height: props.height }}></canvas>
            </div>
            <Navbar
              color="white"
              logoEntered={compEntered}
              logoLeave={compLeave}
              navBarEntered={compEntered}
              navBarLeave={compLeave}
            />
            <Container>
              {/* <a href="/work/emv-quilt-p5" style={{ textDecoration: "none" }}>
                <Button variant='contained'>
                  Go to EMV-Quilt-P5
                </Button>
              </a> */}
              {
                Stories.map((item, index) => (
                  <FlexBoxSA key={index}>
                    <WorkMobBlock
                      data={item}
                    />
                  </FlexBoxSA>
                ))
              }
            </Container>
          </div>
      }
      {
        props.width > 850
        &&
        <motion.div
          style={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: "white",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 2,
            pointerEvents: "none"
          }}
          variants={variants}
          animate={cursorVariant}
        />
      }
    </>
  )
}
