import React, { useContext, useEffect, useState } from 'react'
import { AbsoluteBottomRight, FlexBox, FlexBoxSB, FooterContainer, FooterItem, FooterOthersText, FooterOutlinedText, FooterRotatedContainer, FooterRotatedContainerPosition, FooterRotatedText, FooterWrapper, FooterYear, NewsletterText, NewsletterTextA, OutlinedText, PacificoText } from '../style/global.styles'
import { LogoBuild } from './logoBuild'
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerLeftRightXXXL, SpacerRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL, SpacerTopBottomXXL, SpacerTopBottomXXXL } from '../../design/spacer/spacer'
import Grid from '@mui/material/Grid';
import { IconHolder } from './iconHolder';
import facebookIconB from "../../assets/icons/facebook-b.png"
import twitterIconB from "../../assets/icons/x-b.png"
import emailIconB from "../../assets/icons/email-b.png"
import instagramIconB from "../../assets/icons/instagram-b.png"
import whatsappIconB from "../../assets/icons/whatsapp-b.png"
import telegramIconB from "../../assets/icons/telegram-b.png"
import youtubeIconB from "../../assets/icons/youtube-b.png"
import linkedInIconB from "../../assets/icons/linkedIn-b.png"
import copyrightIconB from "../../assets/icons/copyright-b.png"
import facebookIconW from "../../assets/icons/facebook-w.png"
import twitterIconW from "../../assets/icons/x-w.png"
import emailIconW from "../../assets/icons/email-w.png"
import instagramIconW from "../../assets/icons/instagram-w.png"
import whatsappIconW from "../../assets/icons/whatsapp-w.png"
import telegramIconW from "../../assets/icons/telegram-w.png"
import youtubeIconW from "../../assets/icons/youtube-w.png"
import linkedInIconW from "../../assets/icons/linkedIn-w.png"
import copyrightIconW from "../../assets/icons/copyright-w.png"
import { ImgHolder } from './imgHolder';
import { NavLink, useLocation } from 'react-router-dom';
import { BackToTop } from './backToTop';
import useWindowDimensions from './screenSize';
import { ContactFormInput } from '../../pages/contact/style/contact.styles';
import { ApiContext } from '../../context/api/api.provider';
import { ArchiveContext } from '../../context/archive/archive.provider';
import { addDoc } from 'firebase/firestore';
import { Alert, Button, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import subscribeIconB from '../../assets/icons/subscribe-b.png'
import subscribeIconW from '../../assets/icons/subscribe-w.png'
import subscribeIconD from '../../assets/icons/subscribe-disabled.png'
import gmapsIconB from '../../assets/icons/gmap-b.png'
import gmapsIconW from '../../assets/icons/gmap-w.png'

export const Footer = (props) => {

    const location = useLocation();
    const { height, width } = useWindowDimensions();

    const { newsSubscribersCollectionRef, loading, setLoading } = useContext(ApiContext);
    const {
        email,
        setEmail,
        emailLink,
        googleMapsLink,
        youTubeLink,
        linkedInLink,
        facebookLink,
        twitterLink,
        whatsappLink,
        instagramLink,
        telegramLink,
    } = useContext(ArchiveContext);

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>

            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const handleSubscribe = async () => {
        setLoading(true);

        const data = {
            email: email,
            source: "inspirise.com",
            captured: location.pathname,
            createdAt: new Date()
        }

        await addDoc(newsSubscribersCollectionRef, data);

        setLoading(false);
        setEmail("");
        handleClick();
    }

    return (
        <>
            <FooterContainer style={
                props.variant === "flipWhite"
                    ?
                    {}
                    :
                    props.variant === "flipWhiteHScroll"
                        ?
                        { paddingTop: 20, backgroundColor: "white" }
                        :
                        props.variant === "transparent"
                            ?
                            {}
                            :
                            { backgroundColor: "white" }
            }>
                <Grid container spacing={width > 850 ? 2 : 8}>
                    {
                        width < 850
                        &&
                        <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                            <LogoBuild
                                width={56}
                                height={56}
                                logoEntered={props.footerEntered}
                                logoLeave={props.footerLeave}
                                color={props.variant === "flipWhite" && "white"}
                            />
                            <SpacerTopBottomXXXL />
                            <FlexBox style={{ justifyContent: width > 850 ? "end" : "normal" }}>
                                <div>
                                    <NewsletterText
                                        style={{
                                            color: props.variant === "flipWhite" ? "white" : "black",
                                            fontWeight: props.variant === "flipWhite" ? 400 : 600
                                        }}>
                                        Subscribe to our tabloid to get
                                    </NewsletterText>
                                    <NewsletterText
                                        style={{
                                            color: props.variant === "flipWhite" ? "white" : "black",
                                            fontWeight: props.variant === "flipWhite" ? 400 : 600
                                        }}>
                                        the latest on
                                    </NewsletterText>
                                    <PacificoText
                                        style={{
                                            color: props.variant === "flipWhite" ? "white" : "black"
                                        }}>
                                        design, marketing, & technology
                                    </PacificoText>
                                    <SpacerTopBottomLarge />
                                    <FlexBoxSB>
                                        <ContactFormInput
                                            style={{ width: "100%", height: 40 }}
                                            name='email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <SpacerRightSmall />
                                        <div>
                                            {
                                                email
                                                    ?
                                                    <IconHolder
                                                        icon={props.variant === "flipWhite" ? subscribeIconW : subscribeIconB}
                                                        width={40}
                                                        height={40}
                                                        clicked={handleSubscribe}
                                                        onMouseEnter={props.footerEntered}
                                                        onMouseLeave={props.footerLeave}
                                                    />
                                                    :
                                                    <ImgHolder
                                                        img={subscribeIconD}
                                                        width={40}
                                                        height={40}
                                                    />
                                            }
                                        </div>
                                    </FlexBoxSB>
                                    <SpacerTopBottomXXXL />
                                    <FlexBox>
                                        <FooterOthersText
                                            to="/privacyPolicy"
                                            style={{
                                                color: props.variant === "flipWhite" ? "white" : "black",
                                                fontWeight: props.variant === "flipWhite" ? 400 : 700
                                            }}
                                            target="_blank"
                                            onMouseEnter={props.footerEntered}
                                            onMouseLeave={props.footerLeave}>
                                            Privacy Policy.
                                        </FooterOthersText>
                                        <SpacerLeftRightMedium />
                                        <FooterOthersText
                                            to="/terms"
                                            style={{
                                                color: props.variant === "flipWhite" ? "white" : "black",
                                                fontWeight: props.variant === "flipWhite" ? 400 : 700
                                            }}
                                            target="_blank"
                                            onMouseEnter={props.footerEntered}
                                            onMouseLeave={props.footerLeave}>
                                            Terms.
                                        </FooterOthersText>
                                        <SpacerLeftRightMedium />
                                        <FooterOthersText
                                            to="/cookies"
                                            style={{
                                                color: props.variant === "flipWhite" ? "white" : "black",
                                                fontWeight: props.variant === "flipWhite" ? 400 : 700
                                            }}
                                            target="_blank"
                                            onMouseEnter={props.footerEntered}
                                            onMouseLeave={props.footerLeave}>
                                            Cookies.
                                        </FooterOthersText>
                                    </FlexBox>
                                    <SpacerTopBottomMedium />
                                    <FlexBox>
                                        <FooterOthersText
                                            to="/attribution"
                                            style={{
                                                color: props.variant === "flipWhite" ? "white" : "black",
                                                fontWeight: props.variant === "flipWhite" ? 400 : 700
                                            }}
                                            target="_blank"
                                            onMouseEnter={props.footerEntered}
                                            onMouseLeave={props.footerLeave}>
                                            Attribution.
                                        </FooterOthersText>
                                        <SpacerLeftRightMedium />
                                        <FooterOthersText
                                            to="https://www.inspirise.com/sitemap.xml"
                                            style={{
                                                color: props.variant === "flipWhite" ? "white" : "black",
                                                fontWeight: props.variant === "flipWhite" ? 400 : 700
                                            }}
                                            target="_blank"
                                            onMouseEnter={props.footerEntered}
                                            onMouseLeave={props.footerLeave}>
                                            Sitemap.
                                        </FooterOthersText>
                                    </FlexBox>
                                </div>
                            </FlexBox>
                        </Grid>
                    }
                    <Grid item xl={8} lg={8} md={7} sm={12} xs={12}>
                        {
                            width > 850
                            &&
                            <>
                                <LogoBuild
                                    width={56}
                                    height={56}
                                    logoEntered={props.footerEntered}
                                    logoLeave={props.footerLeave}
                                    color={props.variant === "flipWhite" && "white"}
                                />
                                <SpacerTopBottomXXXL />
                            </>
                        }
                        <Grid container spacing={6}>
                            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                                <FooterItem
                                    to="/"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    HOME
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/services"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    SERVICES
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/work"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    OUR WORK
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/contact"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    CONTACT
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/about"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    ABOUT
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="https://blog.inspirise.com/"
                                    target='_blank'
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    BLOG
                                </FooterItem>
                                {/* <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/blog"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    BLOG
                                </FooterItem> */}
                            </Grid>
                            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                                <FooterItem
                                    to="/mastery/design"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    WEBSITES
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/mastery/design"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    3D DESIGN
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/mastery/development"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    ECOMMERCE
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/mastery/development"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    WEB APPS
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/mastery/development"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    MOBILE APPS
                                </FooterItem>
                            </Grid>
                            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                                <FooterItem
                                    to="/mastery/marketing"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    MARKETING
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/mastery/marketing"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    BRANDING
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/mastery/marketing"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    SEO
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/mastery/marketing"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    AD CAMPAIGNS
                                </FooterItem>
                                <SpacerTopBottomLarge />
                                <FooterItem
                                    to="/mastery/marketing"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    SOCIAL MEDIA
                                </FooterItem>
                            </Grid>
                            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                                <FooterItem
                                    to="/mastery/testing"
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black",
                                        fontWeight: props.variant === "flipWhite" ? 400 : 700
                                    }}
                                    onMouseEnter={props.footerEntered}
                                    onMouseLeave={props.footerLeave}>
                                    EMV L3 CERTIFICATION
                                </FooterItem>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        width > 850
                        &&
                        <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                            <FlexBox style={{ justifyContent: width > 850 ? "end" : "normal" }}>
                                <div>
                                    <NewsletterText
                                        style={{
                                            color: props.variant === "flipWhite" ? "white" : "black",
                                            fontWeight: props.variant === "flipWhite" ? 400 : 600
                                        }}>
                                        Subscribe to our tabloid to get
                                    </NewsletterText>
                                    <NewsletterText
                                        style={{
                                            color: props.variant === "flipWhite" ? "white" : "black",
                                            fontWeight: props.variant === "flipWhite" ? 400 : 600
                                        }}>
                                        the latest on
                                    </NewsletterText>
                                    <PacificoText
                                        style={{
                                            color: props.variant === "flipWhite" ? "white" : "black"
                                        }}>
                                        design, marketing, & technology
                                    </PacificoText>
                                    <SpacerTopBottomLarge />
                                    <FlexBoxSB>
                                        <ContactFormInput
                                            style={{ width: "100%", height: 40 }}
                                            name='email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <SpacerRightSmall />
                                        <div>
                                            {
                                                email
                                                    ?
                                                    <IconHolder
                                                        icon={props.variant === "flipWhite" ? subscribeIconW : subscribeIconB}
                                                        width={40}
                                                        height={40}
                                                        clicked={handleSubscribe}
                                                        onMouseEnter={props.footerEntered}
                                                        onMouseLeave={props.footerLeave}
                                                    />
                                                    :
                                                    <ImgHolder
                                                        img={subscribeIconD}
                                                        width={40}
                                                        height={40}
                                                    />
                                            }
                                        </div>
                                    </FlexBoxSB>
                                    <SpacerTopBottomXXXL />
                                    <FlexBox>
                                        <FooterOthersText
                                            to="/privacyPolicy"
                                            style={{
                                                color: props.variant === "flipWhite" ? "white" : "black",
                                                fontWeight: props.variant === "flipWhite" ? 400 : 700
                                            }}
                                            target="_blank"
                                            onMouseEnter={props.footerEntered}
                                            onMouseLeave={props.footerLeave}>
                                            Privacy Policy.
                                        </FooterOthersText>
                                        <SpacerLeftRightMedium />
                                        <FooterOthersText
                                            to="/terms"
                                            style={{
                                                color: props.variant === "flipWhite" ? "white" : "black",
                                                fontWeight: props.variant === "flipWhite" ? 400 : 700
                                            }}
                                            target="_blank"
                                            onMouseEnter={props.footerEntered}
                                            onMouseLeave={props.footerLeave}>
                                            Terms.
                                        </FooterOthersText>
                                        <SpacerLeftRightMedium />
                                        <FooterOthersText
                                            to="/cookies"
                                            style={{
                                                color: props.variant === "flipWhite" ? "white" : "black",
                                                fontWeight: props.variant === "flipWhite" ? 400 : 700
                                            }}
                                            target="_blank"
                                            onMouseEnter={props.footerEntered}
                                            onMouseLeave={props.footerLeave}>
                                            Cookies.
                                        </FooterOthersText>
                                    </FlexBox>
                                    <SpacerTopBottomMedium />
                                    <FlexBox>
                                        <FooterOthersText
                                            to="/attribution"
                                            style={{
                                                color: props.variant === "flipWhite" ? "white" : "black",
                                                fontWeight: props.variant === "flipWhite" ? 400 : 700
                                            }}
                                            target="_blank"
                                            onMouseEnter={props.footerEntered}
                                            onMouseLeave={props.footerLeave}>
                                            Attribution.
                                        </FooterOthersText>
                                        <SpacerLeftRightMedium />
                                        <FooterOthersText
                                            to="https://www.inspirise.com/sitemap.xml"
                                            style={{
                                                color: props.variant === "flipWhite" ? "white" : "black",
                                                fontWeight: props.variant === "flipWhite" ? 400 : 700
                                            }}
                                            target="_blank"
                                            onMouseEnter={props.footerEntered}
                                            onMouseLeave={props.footerLeave}>
                                            Sitemap.
                                        </FooterOthersText>
                                    </FlexBox>
                                </div>
                            </FlexBox>
                        </Grid>
                    }
                </Grid>
                <SpacerTopBottomXXXL />
                <FlexBox style={{
                    display: width > 850 ? "flex" : "block"
                }}>
                    <FlexBox>
                        <NavLink style={{ textDecoration: "none" }} to={googleMapsLink} target="_blank" onMouseEnter={props.footerEntered} onMouseLeave={props.footerLeave}>
                            <IconHolder
                                icon={props.variant === "flipWhite" ? gmapsIconW : gmapsIconB}
                                width={48}
                                height={48}
                            />
                        </NavLink>
                        <SpacerLeftRightLarge />
                        <NavLink style={{ textDecoration: "none" }} to={emailLink} onMouseEnter={props.footerEntered} onMouseLeave={props.footerLeave}>
                            <IconHolder
                                icon={props.variant === "flipWhite" ? emailIconW : emailIconB}
                                width={48}
                                height={48}
                            />
                        </NavLink>
                        <SpacerLeftRightLarge />
                        <NavLink style={{ textDecoration: "none" }} to={whatsappLink} target="_blank" onMouseEnter={props.footerEntered} onMouseLeave={props.footerLeave}>
                            <IconHolder
                                icon={props.variant === "flipWhite" ? whatsappIconW : whatsappIconB}
                                width={48}
                                height={48}
                            />
                        </NavLink>
                        <SpacerLeftRightLarge />
                        <NavLink style={{ textDecoration: "none" }} to={telegramLink} target="_blank" onMouseEnter={props.footerEntered} onMouseLeave={props.footerLeave}>
                            <IconHolder
                                icon={props.variant === "flipWhite" ? telegramIconW : telegramIconB}
                                width={48}
                                height={48}
                            />
                        </NavLink>
                    </FlexBox>
                    {
                        width > 850 ? <SpacerLeftRightLarge /> : <SpacerTopBottomXL />
                    }
                    <FlexBox>
                        <NavLink style={{ textDecoration: "none" }} to={facebookLink} target="_blank" onMouseEnter={props.footerEntered} onMouseLeave={props.footerLeave}>
                            <IconHolder
                                icon={props.variant === "flipWhite" ? facebookIconW : facebookIconB}
                                width={48}
                                height={48}
                            />
                        </NavLink>
                        <SpacerLeftRightLarge />
                        <NavLink style={{ textDecoration: "none" }} to={instagramLink} target="_blank" onMouseEnter={props.footerEntered} onMouseLeave={props.footerLeave}>
                            <IconHolder
                                icon={props.variant === "flipWhite" ? instagramIconW : instagramIconB}
                                width={48}
                                height={48}
                            />
                        </NavLink>
                        <SpacerLeftRightLarge />
                        <NavLink style={{ textDecoration: "none" }} to={twitterLink} target="_blank" onMouseEnter={props.footerEntered} onMouseLeave={props.footerLeave}>
                            <IconHolder
                                icon={props.variant === "flipWhite" ? twitterIconW : twitterIconB}
                                width={48}
                                height={48}
                            />
                        </NavLink>
                        <SpacerLeftRightLarge />
                        <NavLink style={{ textDecoration: "none" }} to={linkedInLink} target="_blank" onMouseEnter={props.footerEntered} onMouseLeave={props.footerLeave}>
                            <IconHolder
                                icon={props.variant === "flipWhite" ? linkedInIconW : linkedInIconB}
                                width={48}
                                height={48}
                            />
                        </NavLink>
                    </FlexBox>
                </FlexBox>
                <SpacerTopBottomXL />
                <FooterOutlinedText style={
                    props.variant === "flipWhite"
                        ?
                        { WebkitTextStroke: "2px white", color: "black" }
                        :
                        { WebkitTextStroke: "2px black", color: "white" }
                }>
                    INSPIRISE
                </FooterOutlinedText>
                {
                    width > 650
                        ?
                        <FooterRotatedContainerPosition>
                            <FooterRotatedContainer>
                                <ImgHolder
                                    img={props.variant === "flipWhite" ? copyrightIconW : copyrightIconB}
                                    width={72}
                                    height={72}
                                />
                                <SpacerLeftRightMedium />
                                <FooterYear
                                    style={{
                                        color: props.variant === "flipWhite" ? "white" : "black"
                                    }}>
                                    {new Date().getFullYear()}
                                </FooterYear>
                            </FooterRotatedContainer>
                        </FooterRotatedContainerPosition>
                        :
                        <FlexBox>
                            <ImgHolder
                                img={props.variant === "flipWhite" ? copyrightIconW : copyrightIconB}
                                width={72}
                                height={72}
                            />
                            <SpacerLeftRightMedium />
                            <FooterYear
                                style={{
                                    color: props.variant === "flipWhite" ? "white" : "black"
                                }}>
                                {new Date().getFullYear()}
                            </FooterYear>
                        </FlexBox>
                }
            </FooterContainer>
            {/* <BackToTop
                backToTopBtnEntered={props.backToTopBtnEntered}
                backToTopBtnLeave={props.backToTopBtnLeave}
            /> */}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Welcome aboard, experience the world of innovation at it's finest!
                </Alert>
            </Snackbar>
        </>
    )
}
