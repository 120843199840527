import React, { useEffect, useState } from 'react'
import { ContactContainer, ContactV2Wrapper, ContactWrapper } from './style/contact.styles'
import { Navbar } from '../../global/components/navigation/navbar'
import { AbsoluteCenter, FlexBox, FlexColumnSB, SandwichHalf } from '../../global/style/global.styles'
import { ContactForm } from './components/contactForm'
import { ContactInfo } from './components/contactInfo'
import { Footer } from '../../global/components/footer'
import ScrollToTop from '../../global/components/scrollToTop'
import { motion } from 'framer-motion'
import { CustomCursor } from '../../global/components/customCursor'
import { GetInTouch } from './components/getInTouch'
import { SocialNetwork } from './components/socialNetwork'
import { TalkToUs } from './components/talkToUs'
import { BookAppointment } from './components/bookAppointment'
import { Helmet } from 'react-helmet'

export const Contact = (props) => {

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    })
    const [cursorVariant, setCursorVariant] = useState("default")

    useEffect(() => {
        const mouseMove = (e) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }

        window.addEventListener("mousemove", mouseMove);
        return () => {
            window.removeEventListener("mousemove", mouseMove)
        }

        setCursorVariant("default")
    }, [])

    const variants = {
        default: {
            x: mousePosition.x - 10,
            y: mousePosition.y - 10,
            mixBlendMode: "difference"
        },
        diffCursor: {
            width: 100,
            height: 100,
            x: mousePosition.x - 50,
            y: mousePosition.y - 50,
            backgroundColor: "white",
            mixBlendMode: "difference"
        },
        normalCursor: {
            width: 0,
            height: 0,
        }
    }

    const compEntered = () => setCursorVariant("diffCursor")
    const compLeave = () => setCursorVariant("default")
    const normalCompEntered = () => setCursorVariant("normalCursor")

    return (
        <>
            <Helmet>
                <meta name="description" content="Connect with our premier digital agency for innovative web solutions and transformative digital experiences. From web design and SEO to social media management and PPC campaigns, our expert team is here to elevate your online presence and drive business growth. Contact us today to unlock your digital potential!" />
                <title>Inspirise | Contact Us</title>
            </Helmet>
            <ScrollToTop />
            <ContactV2Wrapper>
                <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
                    <Navbar
                        color="white"
                        logoEntered={compEntered}
                        logoLeave={compLeave}
                        navBarEntered={compEntered}
                        navBarLeave={compLeave}
                    />
                </div>
                <GetInTouch
                    width={props.width}
                    compEntered={compEntered}
                    compLeave={compLeave}
                />
                <SocialNetwork
                    width={props.width}
                    compEntered={compEntered}
                    compLeave={compLeave}
                />
                <TalkToUs
                    width={props.width}
                    location={props.location}
                    compEntered={compEntered}
                    compLeave={compLeave}
                />
                {/* <BookAppointment 
                    width={props.width}
                />
                <SandwichHalf /> */}
                <Footer
                    variant="flipWhite"
                    footerEntered={compEntered}
                    footerLeave={compLeave}
                    backToTopBtnEntered={compEntered}
                    backToTopBtnLeave={compLeave}
                    inputFieldEntered={compEntered}
                    inputFieldLeft={compLeave}
                />
                {
                    props.width > 850
                    &&
                    <motion.div
                        style={{
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                            backgroundColor: "white",
                            position: "fixed",
                            top: 0,
                            left: 0,
                            zIndex: 2,
                            pointerEvents: "none"
                        }}
                        variants={variants}
                        animate={cursorVariant}
                    />
                }
            </ContactV2Wrapper>
            {/* <ContactWrapper>
                <Navbar
                    logoEntered={compEntered}
                    logoLeave={compLeave}
                    navBarEntered={compEntered}
                    navBarLeave={compLeave}
                />
                <AbsoluteCenter>
                    <ContactContainer>
                        <FlexBox>
                            <ContactForm
                                inputFieldEntered={compEntered}
                                inputFieldLeft={compLeave}
                            />
                            <ContactInfo
                                socialIconEntered={compEntered}
                                socialIconLeave={compLeave}
                                mapEntered={normalCompEntered}
                                mapLeave={compLeave}
                            />
                        </FlexBox>
                    </ContactContainer>
                </AbsoluteCenter>
            </ContactWrapper> */}
        </>
    )
}
