import React from 'react'
import { FlexBox, FlexBoxSB } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { StoryPara } from '../style/stories.styles'
import { SpacerTopBottomMedium, SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { Grid } from '@mui/material'

export const UspBlock = (props) => {
    return (
        <>
            <Grid container spacing={4} alignItems='center'>
                {
                    props.width < 850
                    &&
                    <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <FlexBox style={{ justifyContent: "space-around" }}>
                            <ImgHolder
                                img={props.icon}
                                width={220}
                            />
                        </FlexBox>
                    </Grid>
                }
                <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                    <StoryPara style={{ fontWeight: 600 }}>
                        {props.title}
                    </StoryPara>
                    <SpacerTopBottomSmall />
                    <StoryPara>
                        {props.content}
                    </StoryPara>
                </Grid>
                {
                    props.width > 850
                    &&
                    <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <FlexBox style={{ justifyContent: "end" }}>
                            <ImgHolder
                                img={props.icon}
                                width={200}
                            />
                        </FlexBox>
                    </Grid>
                }
            </Grid>
        </>
    )
}
