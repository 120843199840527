import React, { useEffect, useState } from 'react'
import { MasteryBgContainer, MasteryWrapper } from './style/mastery.styles'
import { ImgHolder } from '../../global/components/imgHolder'
import { motion } from 'framer-motion'
import ScrollToTop from '../../global/components/scrollToTop'
import { Navbar } from '../../global/components/navigation/navbar'
import { MasteryHeading } from './components/masteryHeading'
import designBg from '../../assets/images/designTitleBg.png'
import { MasteryRecommend } from './components/masteryRecommend'
import { Footer } from '../../global/components/footer'
import { InspirationSection } from './components/inspirationSection'
import { UserExperienceSection } from './components/userExperienceSection'
import { PrototypingSection } from './components/prototypingSection'
import { ThreeDModelling } from './components/threeDModelling'
import { ServicesContact } from '../services/components/servicesContact'
import { LoadingScreen } from '../../global/components/loadingScreen'
import { AbsoluteCenter } from '../../global/style/global.styles'
import ControlCameraRoundedIcon from '@mui/icons-material/ControlCameraRounded';
import masteryBg from "../../assets/images/masteryBg.png"
import masteryBgMob from "../../assets/images/masteryBgMob.png"
import { Helmet } from 'react-helmet'

export const Design = (props) => {

  const [splineEntered, setSplineEntered] = useState(false)

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorVariant, setCursorVariant] = useState("default")

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove)
    }

    setCursorVariant("default")
  }, [])

  const variants = {
    default: {
      x: mousePosition.x - 10,
      y: mousePosition.y - 10,
      mixBlendMode: "difference"
    },
    diffCursor: {
      width: 100,
      height: 100,
      x: mousePosition.x - 50,
      y: mousePosition.y - 50,
      backgroundColor: "white",
      mixBlendMode: "difference"
    },
    normalCursor: {
      width: 0,
      height: 0,
    }
  }

  const compEntered = () => setCursorVariant("diffCursor")
  const compLeave = () => setCursorVariant("default")

  const handleSplineEntered = () => {
    compEntered()
    setSplineEntered(true)
  }

  const handleSplineLeave = () => {
    compLeave()
    setSplineEntered(false)
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Empower your online presence with expert website creation services by our seasoned team. We specialize in responsive design, SEO optimization, and user-centric interfaces, ensuring top rankings and unparalleled user experiences on Google and beyond." />
        <title>Inspirise | Creative Design</title>
      </Helmet>
      <MasteryWrapper>
        <ScrollToTop />
        <MasteryBgContainer>
          <ImgHolder
            img={props.width > 850 ? masteryBg : masteryBgMob}
            width="100%"
            height="100%"
          />
        </MasteryBgContainer>
        <Navbar
          color="white"
          logoEntered={compEntered}
          logoLeave={compLeave}
          navBarEntered={compEntered}
          navBarLeave={compLeave}
        />
        <MasteryHeading
          heading="DESIGN"
          background={`url(${designBg})`}
        />
        <InspirationSection
          width={props.width}
        />
        <UserExperienceSection
          width={props.width}
        />
        <ThreeDModelling
          width={props.width}
          earthElementEntered={handleSplineEntered}
          earthElementLeave={handleSplineLeave}
        />
        <PrototypingSection
          width={props.width}
        />
        <ServicesContact
          width={props.width}
          location={props.location}
          variant="filled"
          color="white"
          inputFieldEntered={compEntered}
          inputFieldLeft={compLeave}
        />
        <MasteryRecommend
          location={props.location}
          title="You may also be interested in"
          blockEntered={compEntered}
          blockLeave={compLeave}
        />
        <Footer
          variant="flipWhite"
          footerEntered={compEntered}
          footerLeave={compLeave}
          backToTopBtnEntered={compEntered}
          backToTopBtnLeave={compLeave}
          inputFieldEntered={compEntered}
          inputFieldLeft={compLeave}
        />
        {
          props.width > 850
          &&
          <motion.div
            style={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "white",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 2,
              pointerEvents: "none"
            }}
            variants={variants}
            animate={cursorVariant}
          >
            {
              splineEntered
              &&
              <AbsoluteCenter>
                <ControlCameraRoundedIcon
                  fontSize='large'
                />
              </AbsoluteCenter>
            }
          </motion.div>
        }
      </MasteryWrapper>
    </>
  )
}
