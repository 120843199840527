import styled from 'styled-components'
import { NavLink } from 'react-router-dom';

export const HeroVideo = styled.video`
    position: absolute;
    object-fit: cover;
    z-index: -1;
    width: 100%;
    height: 100vh;
`;

export const NavbarContainer = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};

    @media only screen and (min-width: 1800px) {
        padding-top: ${(props) => props.theme.space[6]};
        padding-bottom: ${(props) => props.theme.space[6]};
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const NavSectionContainer = styled.div`
    position: relative;
    width: 96vw;
    height: 100dvh;
    
    ${'' /* display: flex;
    background: #222; */}
    ${'' /* background-image: url(${navBg});
    background-size: cover; */}
    
`;

export const NavTitle = styled(NavLink)`
    font-size: 88px;
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.extraLight};
    text-decoration: none;
    letter-spacing: -0.02em;

    ${'' /* &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    } */}

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.subHeadline};
    }
    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.subChildHeadline};
    }
    @media only screen and (max-width: 850px) {
    }
    @media only screen and (max-width: 650px) {
        font-size: 56px;
    }
    @media only screen and (max-width: 450px) {
        font-size: 56px;
    }
    @media only screen and (max-width: 370px) {
        font-size: ${(props) => props.theme.fontSizes.heading};
    }
`;

export const NavItemPosition = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    height: 100%;
    padding: 80px 0 80px 160px;

    @media only screen and (max-width: 850px) {
        padding: 80px 0 80px 80px;
    }
    @media only screen and (max-width: 650px) {
        padding: 80px 0 80px 40px;
    }
`;