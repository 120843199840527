import React, { useContext } from 'react'
import { ContactInfoContainer, ContactInfoTileText, ContactInfoV2Container, ContactInfoWrapper, ContactMapPosition } from '../style/contact.styles'
import { FlexBox, FlexBoxSA, FlexBoxSB, FlexColumnSA, FlexColumnSB, FlexColumnSE } from '../../../global/style/global.styles'
import { BrandName } from '../../../global/components/brandName'
import { ContactInfoTile } from './contactInfoTile'
import facebookIconB from "../../../assets/icons/facebook-b.png"
import twitterIconB from "../../../assets/icons/twitter-b.png"
import emailIconB from "../../../assets/icons/email-b.png"
import instagramIconB from "../../../assets/icons/instagram-b.png"
import whatsappIconB from "../../../assets/icons/whatsapp-b.png"
import telegramIconB from "../../../assets/icons/telegram-b.png"
import youtubeIconB from "../../../assets/icons/youtube-b.png"
import pinterestIconB from "../../../assets/icons/pinterest-b.png"
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { NavLink } from 'react-router-dom'
import { IconHolder } from '../../../global/components/iconHolder'
import { ContactMap } from './contactMap'
import { ImgHolder } from '../../../global/components/imgHolder'
import emailIcon from '../../../assets/icons/ic_round-email.png'
import callIcon from '../../../assets/icons/ic_round-call.png'
import locationIcon from '../../../assets/icons/mdi_location.png'
import { ArchiveContext } from '../../../context/archive/archive.provider'

export const ContactInfoV2 = (props) => {

    const {
        emailLink
    } = useContext(ArchiveContext)

    return (
        <ContactInfoV2Container>
            <FlexColumnSA style={{ height: "100%" }}>
                <a href={emailLink} style={{ textDecoration: "none" }}>
                    <FlexBox onMouseEnter={props.compEntered} onMouseLeave={props.compLeave}>
                        <div>
                            <ImgHolder
                                img={emailIcon}
                                width={props.width > 1800 ? 64 : props.width > 450 ? 40 : 32}
                            />
                        </div>
                        {
                            props.width > 450 ? <SpacerLeftRightLarge /> : <SpacerLeftRightMedium />
                        }
                        <ContactInfoTileText>
                            hello@inspirise.com
                        </ContactInfoTileText>
                    </FlexBox>
                </a>
                {
                    props.width < 850
                    &&
                    <SpacerTopBottomLarge />
                }
                <FlexBox>
                    <div>
                        <ImgHolder
                            img={callIcon}
                            width={props.width > 1800 ? 64 : props.width > 450 ? 40 : 32}
                        />
                    </div>
                    {
                        props.width > 450 ? <SpacerLeftRightLarge /> : <SpacerLeftRightMedium />
                    }
                    <ContactInfoTileText>
                        +91 9380450912
                    </ContactInfoTileText>
                </FlexBox>
                {
                    props.width < 850
                    &&
                    <SpacerTopBottomLarge />
                }
                <div>
                    <FlexBox style={{ alignItems: "normal" }}>
                        <div>
                            <ImgHolder
                                img={locationIcon}
                                width={props.width > 1800 ? 64 : props.width > 450 ? 40 : 32}
                            />
                        </div>
                        {
                            props.width > 450 ? <SpacerLeftRightLarge /> : <SpacerLeftRightMedium />
                        }
                        <div>
                            <ContactInfoTileText style={{
                                fontSize: props.width > 1800 ? 32 : props.width > 450 ? 24 : 16,
                                fontWeight: 300,
                                letterSpacing: "0.04em",
                                textTransform: "uppercase"
                            }}>
                                Inspirise Innovations Pvt. Ltd.
                            </ContactInfoTileText>
                            {
                                props.width > 1800
                                    ?
                                    <SpacerTopBottomMedium />
                                    :
                                    <SpacerTopBottomSmall />
                            }
                            <ContactInfoTileText>
                                #74, 2nd floor, Phoenix, Hennur main road, Bengaluru- 560043
                            </ContactInfoTileText>
                        </div>
                    </FlexBox>
                    {
                        props.width > 1800
                            ?
                            <SpacerTopBottomXL />
                            :
                            <SpacerTopBottomLarge />
                    }
                    <FlexBoxSB>
                        <div
                            style={{ width: props.width > 1800 ? 80 : props.width > 450 ? 40 : 32 }}
                        />
                        {
                            props.width > 450 ? <SpacerLeftRightLarge /> : <SpacerLeftRightMedium />
                        }
                        <ContactMap />
                    </FlexBoxSB>
                </div>
            </FlexColumnSA>
        </ContactInfoV2Container>
    )
}
