import React from 'react'
import { ContactMapContainer } from '../style/contact.styles'

export const ContactMap = (props) => {
    return (
        <>
            <ContactMapContainer
                onMouseEnter={props.mapEntered}
                onMouseLeave={props.mapLeave}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12980.51647790573!2d77.6292563116523!3d13.02959251881846!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe9150ab243b6198!2sInspirise%20Innovations%20Private%20Limited!5e0!3m2!1sen!2sin!4v1661608108515!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0, borderRadius: 8 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade" />
            </ContactMapContainer>
        </>
    )
}
