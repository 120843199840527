import styled from 'styled-components'
import aboutAbstractImgv2 from '../../../assets/images/aboutAbstractCoverv2.jpg'
import aboutAbstractImgv2Mob from '../../../assets/images/aboutAbstractCoverv2Mob.jpg'

export const AboutWrapper = styled.div`
    position: relative;
`;

export const AboutBgContainer = styled.div`
    
`;

export const AboutHeroContainer = styled.div`
    height: 100dvh;
    position: relative;
    ${'' /* overflow: hidden; */}
`;

export const AboutHeroVideo = styled.video`
    position: absolute;
    object-fit: cover;
    z-index: -1;
    width: 100%;
    height: 100vh;
`;

export const LogoPosition = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-bottom: ${(props) => props.theme.space[8]};
    margin-left: ${(props) => props.theme.space[20]};
`;

export const NavContactPosition = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    margin-top: ${(props) => props.theme.space[8]};
    margin-right: ${(props) => props.theme.space[20]};
`;

export const AboutHScrollWrapper = styled.div`
    height: 900vh;
    position: relative;
`;

export const AboutHScrollContainer = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    height: 100dvh;
    overflow: hidden;
`;

export const AboutHeroHScrollContainer = styled.div`
    height: 100vh;
    width: 100vw;

    @media only screen and (max-width: 850px) {
        height: 100%;
        width: 100%;
    }
`;

export const AboutMobTaglinePosition = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
`;

export const AboutTaglineContainer = styled.div`
    padding-top: ${(props) => props.theme.space[12]};
    padding-bottom: ${(props) => props.theme.space[12]};
    padding-left: ${(props) => props.theme.space[10]};
    padding-right: ${(props) => props.theme.space[10]};

    @media only screen and (max-width: 850px) {
        padding-top: ${(props) => props.theme.space[8]};
        padding-bottom: ${(props) => props.theme.space[8]};
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {
    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const AboutTaglinesPosition = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-bottom: ${(props) => props.theme.space[8]};
    margin-right: ${(props) => props.theme.space[20]};
`;

export const AboutTagline = styled.div`
    font-size: 56px;
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.thin};
`;

export const AboutUsContainer = styled.div`
    background-color: white;
    height: 100vh;
    width: 100vw;
    position: relative;
    @media only screen and (max-width: 850px) {
        height: 100%;
        width: 100%;
    }
`;

export const AboutUsTitle = styled.div`
    font-size: 32px;
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.light};
`;

export const AboutUsPara = styled.div`
    font-size: 20px;
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.light};
    line-height: 38px;
    letter-spacing: 0.02em;
    position: relative;
    z-index: 2;
    text-align: justify;

    @media only screen and (max-width: 850px) {
        text-align: left;
    }
`;

export const CityOutlinedText = styled.div`
    -webkit-text-stroke: 4px #E2E2E3;
    color: white;
    font-size: 180px;
    font-weight: 900;
    letter-spacing: 0.02em;
    font-style: italic;

    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.headline};
    }
    @media only screen and (max-width: 850px) {
        font-size: 104px;
    }
    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.childHeadline};
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.subChildHeadline};
    }
    @media only screen and (max-width: 370px) {
        font-size: 56px;
    }
`;

export const AboutAbstractCoverContainer = styled.div`
    height: 100vh;
    width: 100vw;
    background-image: url(${aboutAbstractImgv2});
    background-size: cover;
    background-repeat: repeat;

    @media only screen and (max-width: 850px) {
        width: 100%;
        background-image: url(${aboutAbstractImgv2Mob});
    }
`;

export const AboutFooterContainer = styled.div`
    height: 100vh;
    width: 100vw;
`;

export const AboutVisionContainer = styled.div`
    height: 100vh;
    width: 100vw;
    @media only screen and (max-width: 850px) {
        height: 100%;
        width: 100%;
    }
`;

export const AboutVisionLeftContainer = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};
    padding-left: ${(props) => props.theme.space[15]};
    padding-right: ${(props) => props.theme.space[15]};

    @media only screen and (max-width: 850px) {
        padding-top: ${(props) => props.theme.space[8]};
        padding-bottom: ${(props) => props.theme.space[8]};
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {
    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const AboutVisionContainerPosition = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
`;

export const AboutVisionTitle = styled.div`
    font-size: 64px;
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.thin};

    @media only screen and (max-width: 370px) {
        font-size: 48px;
    }
`;

export const AboutVisionSubTitle = styled.div`
    font-size: 24px;
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 40px;
    letter-spacing: 0.02em;

    @media only screen and (max-width: 370px) {
        font-size: 20px;
    }
`;
