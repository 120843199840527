import React from 'react'
import { MasteryHeadingContainer, MasteryHeadingText, MasteryHeadingTextPosition } from '../style/mastery.styles'

export const MasteryHeading = (props) => {
    return (
        <MasteryHeadingContainer style={{
            backgroundImage: props.background
        }}>
            <MasteryHeadingTextPosition style={{ right: props.right }}>
                <MasteryHeadingText>
                    {props.heading}
                </MasteryHeadingText>
            </MasteryHeadingTextPosition>
        </MasteryHeadingContainer>
    )
}
