// EMV-WISEASY-P5
import quiltLogo from '../stories/emv-quilt-p5/logo/quilt_software_logo.png'
import wiseasyLogo from '../stories/emv-quilt-p5/logo/wiseasyLogo.png'
import wiseasyBanner from '../stories/emv-quilt-p5/images/bannerv1.png'
import wiseasyBannerV2 from '../stories/emv-quilt-p5/images/bannerv2.png'
import wiseasyBannerMob from '../stories/emv-quilt-p5/images/banner450.png'
import wiseasyPoster from '../stories/emv-quilt-p5/images/poster.png'

import unitedStatesFlag from '../../assets/icons/flags/united_states_flag.png'
import fintechIcon from '../../assets/icons/industry/fintech.png'
import paymentsIcon from '../../assets/icons/sector/payments.png'

import expertiseIco from '../../assets/icons/usp/expertiseIco.png'
import onTimeIco from '../../assets/icons/usp/onTimeIco.png'
import reosurcefulIco from '../../assets/icons/usp/reosurcefulIco.png'

export const Stories = [
    {
        id: "emv-quilt-p5",
        name: "EMV L3 Certification Testing",
        description: "Inspirise are currently engaged with Quilt Software to support them in their last phase of product launch. The American payment technology provider is in the process of realising their newest product...",
        client: {
            name: "Quilt Software",
            legalName: "Quilt Holdco, Inc.",
            website: "https://quiltsoftware.com/",
            logo: quiltLogo,
            accent: "#081E4F",
            banner: {
                banner1500: wiseasyBanner,
                banner1500V2: wiseasyBannerV2,
                banner450: wiseasyBannerMob,
            },
            country: "United States",
            countryFlag: unitedStatesFlag,
            industry: "Fintech",
            industryIcon: fintechIcon,
            sector: "Payments",
            sectorIcon: paymentsIcon,
            partner: "Wiseasy Technology PTE LTD",
            partnerIcon: wiseasyLogo,
            partnerLink: "https://www.wiseasy.com/P-series/P5"
        },
        dp: {
            img: "",
            vid: "https://res.cloudinary.com/dz3yljetu/video/upload/v1707805337/markVid_jiwykp.mp4",
            poster: wiseasyPoster
        },
        services: [
            {
                order: 1,
                mastery: "Testing",
                serviceName: "EMV L3 Certification"
            }
        ],
        story: {
            storyName: "The last mile of product launch. Introducing the Wiseasy-P5",
            storyDetails: "",
            storySeq: 2,
            storyLink: "",
            highlights: [
                {
                    serial: 1,
                    text: "Payment Brands",
                    num: 10
                },
                {
                    serial: 2,
                    text: "L3 Tools Expertise",
                    num: 6
                },
                {
                    serial: 3,
                    text: "Service Hours",
                    num: "850+"
                }
            ],
            context: {
                statement: [
                    "Quilt Software specializes in acquiring specialized point-of-sale software companies tailored to meet the unique inventory management needs and targeted POS requirements of small businesses. They seamlessly integrate these companies into their proprietary QuiltOS system, providing them with the necessary tools, resources, and consultative expertise to accelerate their business growth and expand their customer base while preserving the customer-facing brands they have diligently cultivated.",
                    "In pursuit of this mission, Quilt Software are actively involved in partnership with Wiseasy, a Singapore-based POS company, to procure hardware solutions and bundle them with their customized software suite for the American market. Inspirise, a key component of this strategy, is currently performing final EMV testing to ensuring robust interoperability and compliance, guaranteeing seamless integration of chip terminals across diverse payment environments for enhanced security and reliability."
                ],
                imgs: []
            },
            solution: {
                statement: [
                    "EMV Level 3 (EMV L3) testing serves as a pivotal phase in the deployment of EMV-compliant payment systems, ensuring that chip-enabled terminals adhere to industry standards and specifications. The primary requirement for EMV L3 testing stems from the necessity to validate the interoperability and functionality of these terminals across a wide array of payment networks, card issuers, and payment processing platforms.",
                    "One of the foremost benefits of EMV L3 testing is its role in enhancing payment security. By rigorously evaluating chip terminals against EMV standards, including transaction processing, card authentication, and cryptographic protocols, EMV L3 testing helps thwart fraudulent activities such as counterfeit card fraud and skimming.",
                    "Furthermore, EMV L3 testing facilitates smooth and reliable payment transactions for merchants and consumers alike. It ensures that chip terminals seamlessly communicate with payment networks and banking systems, reducing the likelihood of transaction errors or disruptions during the payment process.",
                    "Moreover, compliance with EMV L3 testing requirements instills trust among stakeholders, including merchants, financial institutions, and consumers, fostering broader acceptance and adoption of chip-based payment technologies. Ultimately, EMV L3 testing plays a pivotal role in fortifying the integrity and security of the global payment ecosystem, paving the way for safer, more resilient payment experiences in an increasingly digitized world."
                ],
                imgs: []
            },
            // outcome: {
            //     statement: "User research is our compass as we map down the unchartered territories of user discovery. Like skilled cartographers, we sketch each touchpoint",
            //     imgs: [],
            //     testimonials: {
            //         statement: "",
            //         imgs: [],
            //         vids: []
            //     }
            // },
            usp: [
                {
                    serial: 1,
                    icon: expertiseIco,
                    title: "Expertise & Experience",
                    content: "We  possess the know-how to navigate complex EMV standards, interpret test results accurately, and troubleshoot issues effectively. Their expertise ensures thorough testing, compliance, and the seamless integration of chip terminals into payment ecosystems."
                },
                {
                    serial: 2,
                    icon: reosurcefulIco,
                    title: "Specialized Tools",
                    content: "These tools are indispensable for EMV Level 3 testing as they streamline the validation process and ensure accuracy. These tools enable comprehensive assessment of chip terminals against EMV standards, identify potential vulnerabilities, and facilitate efficient troubleshooting. They enhance testing efficiency, accuracy, and ultimately contribute to robust payment system security."
                },
                {
                    serial: 3,
                    icon: onTimeIco,
                    title: "Prompt Delivery",
                    content: "Timely delivery of EMV Level 3 testing services is critical for seamless integration of chip terminals into payment ecosystems. It enables merchants to meet compliance deadlines, launch products promptly, and enhance customer experiences. Timely delivery fosters trust among stakeholders and ensures smooth transitions to EMV-compliant payment solutions, avoiding disruptions and financial penalties."
                }
            ]

        },
        landingPage: "https://quiltsoftware.com/",
        featured: true,
        featuredSeq: 1
    }
]