import React from 'react'
import { Container } from '../../../global/style/global.styles'
import { StoryHeading, StoryPara } from '../style/stories.styles'
import { SpacerTopBottomLarge, SpacerTopBottomXL } from '../../../design/spacer/spacer'

export const StoryOutcome = (props) => {
  return (
    <>
      <Container>
        <StoryHeading>
          OUTCOME
        </StoryHeading>
        <SpacerTopBottomXL />
        <StoryPara>
          {props.data?.statement}
        </StoryPara>
      </Container>
    </>
  )
}
