import React, { useRef } from 'react'
import { SuccessStoryPreviewBlock } from './successStoryPreviewBlock'
import { SuccessStoryPreviewContainer, SuccessStoryPreviewNormalContainer, SuccessStoryPreviewWrapper } from '../style/landing.styles'

export const SuccessStoryPreviewNormal = (props) => {

    return (
        <>
            <SuccessStoryPreviewNormalContainer>
                {
                    props.stories?.map((item, index) => (
                            <SuccessStoryPreviewBlock
                                key={index}
                                width={props.width}
                                id={item.id}
                                name={item.name}
                                description={item.description}
                                client={item.client}
                                storyLink={item.storyLink}
                                landingPage={item.landingPage}
                                readMoreStoryEntered={props.readMoreStoryEntered}
                                readMoreStoryLeave={props.readMoreStoryLeave}
                                viewAllStoryEntered={props.viewAllStoryEntered}
                                viewAllStoryLeave={props.viewAllStoryLeave}
                            />
                    ))
                }
            </SuccessStoryPreviewNormalContainer>
        </>
    )
}
