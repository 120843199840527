import React from 'react'
import { ServiceWrapper, ServicesWrapper, WebAppServiceTitle, WebsiteServiceTitle, WebsiteServiceWrapper } from '../style/services.styles'
import { Sandwich } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import webAppImg from '../../../assets/images/Dashboard.png'
import webAppImg1 from '../../../assets/images/Dashboard1.png'
import webAppImg2 from '../../../assets/images/Dashboard2.png'
import { Layer } from '../../../global/components/layer'
import { ServiceLinkBtn } from './serviceLinkBtn'

export const WebAppService = (props) => {
    return (
        <Sandwich>
            <ServiceWrapper style={{ height: props.width > 1100 ? 1145 : props.width > 850 ? 1000 : props.width > 650 ? 1150 : props.width > 450 ? 900 : 740 }}>
                <WebAppServiceTitle
                    style={{
                        textAlign: "center",
                        position: "absolute",
                        right: props.width > 1100 ? 112 : 0,
                        width: props.width > 850 ? 280 : props.width > 650 ? 240 : props.width > 450 ? 180 : props.width > 370 ? 140 : 120
                    }}>
                    WEB
                </WebAppServiceTitle>
                <WebAppServiceTitle
                    style={{
                        textAlign: "center",
                        position: "absolute",
                        right: props.width > 1100 ? 112 : 0,
                        top: props.width > 850 ? 152 : props.width > 650 ? 136 : props.width > 450 ? 96 : props.width > 370 ? 77 : 62,
                        width: props.width > 850 ? 940 : props.width > 650 ? 320 : props.width > 450 ? 240 : props.width > 370 ? 180 : 150,
                    }}>
                    {props.width > 850 ? "APPLICATIONS" : "APPS"}
                </WebAppServiceTitle>
                <div style={{
                    position: "absolute",
                    left: "50%",
                    top: props.wdith > 850 ? 320 : props.width > 650 ? 305 : props.width > 450 ? 217 : props.width > 370 ? 175 : 140,
                    transform: "translate(-50%, 0)",
                }}>
                    {
                        props.width > 850
                        &&
                        <ImgHolder
                            img={webAppImg}
                            width={props.width > 1100 ? 1271 : props.width > 850 ? 1000 : props.width > 650 ? 740 : props.width > 450 ? 600 : props.wdith > 370 ? 450 : 300}
                        />
                    }
                </div>
                {
                    props.width < 850
                    &&
                    <div style={{
                        position: "absolute",
                        left: props.width > 450 ? -150 : -140,
                        top: props.width > 650 ? 305 : props.width > 450 ? 220 : props.width > 370 ? 175 : 150,
                        zIndex: 2
                    }}>
                        <ImgHolder
                            img={webAppImg2}
                            width={props.width > 650 ? 500 : props.width > 450 ? 400 : props.wdith > 370 ? 300 : 260}
                        />
                    </div>
                }
                {
                    props.width < 850
                    &&
                    <div style={{
                        position: "absolute",
                        right: -60,
                        top: props.width > 650 ? 480 : props.width > 370 ? 300 : 340
                    }}>
                        <ImgHolder
                            img={webAppImg1}
                            width={props.width > 650 ? 500 : props.width > 450 ? 400 : props.width > 370 ? 300 : 260}
                        />
                    </div>
                }
                {/* <div style={{
                    position: "absolute",
                    left: 112,
                    top: 500
                }}>
                    <Layer
                        shade="rgba(255,255,255,0.2)"
                    />
                    <ImgHolder
                        img={webMock1}
                        width={931}
                        height={535}
                    />
                </div> */}
                <a
                    href='/mastery/development'
                    target='_blank'
                    style={{
                        position: "absolute",
                        right: props.width > 450 ? 112 : 40,
                        bottom: 0
                    }}
                    onMouseEnter={props.serviceLinkEntered}
                    onMouseLeave={props.serviceLinkLeave}>
                    <ServiceLinkBtn />
                </a>
            </ServiceWrapper>
        </Sandwich>
    )
}
