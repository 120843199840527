import React from 'react'
import { Container, FlexBoxSA, SandwichRealHalf } from '../../../global/style/global.styles'
import { StorySubHeading } from '../../landing/style/landing.styles'
import { SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { Grid } from '@mui/material'
import { MasteryBlock } from './masteryBlock'
import designThumb from '../../../assets/images/recommDesignBg.png'
import devThumb from '../../../assets/images/recommDevelopmentBg.png'
import markThumb from '../../../assets/images/recommMarketingBg.png'
import testThumb from '../../../assets/images/recommTestingBg.png'

export const MasteryRecommend = (props) => {
    return (
        <>
            <Container>
                <StorySubHeading>
                    {
                        props.title
                    }
                </StorySubHeading>
                <SandwichRealHalf />
                <Grid container spacing={8}>
                    {
                        MASTERIES.filter((x) => x.link !== props.location.pathname).map((item, index) => (
                            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} key={index}>
                                <FlexBoxSA>
                                    <MasteryBlock
                                        id={item.id}
                                        mastery={item.mastery}
                                        link={item.link}
                                        background={item.background}
                                        blockEntered={props.blockEntered}
                                        blockLeave={props.blockLeave}
                                    />
                                </FlexBoxSA>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </>
    )
}

const MASTERIES = [
    {
        id: 1,
        mastery: "Design",
        link: "/mastery/design",
        background: designThumb
    },
    {
        id: 2,
        mastery: "Development",
        link: "/mastery/development",
        background: devThumb
    },
    {
        id: 3,
        mastery: "Marketing",
        link: "/mastery/marketing",
        background: markThumb
    },
    {
        id: 4,
        mastery: "Testing",
        link: "/mastery/testing",
        background: testThumb
    },
]
