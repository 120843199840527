import React from 'react'
import { Container, FlexBox, FlexBoxSA } from '../../../global/style/global.styles'
import { ServicesTaglineTextCurvy, ServicesTaglineTextNormal } from '../style/services.styles'
import { SpacerLeftRightLarge, SpacerLeftRightXL } from '../../../design/spacer/spacer'

export const ServicesTagline = (props) => {
    return (
        <FlexBoxSA>
            {
                props.width > 650
                    ?
                    <Container>
                        <FlexBox>
                            <ServicesTaglineTextNormal>
                                We lie at the
                            </ServicesTaglineTextNormal>
                            <ServicesTaglineTextCurvy>
                                perfect intersection
                            </ServicesTaglineTextCurvy>
                            <ServicesTaglineTextNormal>
                                to
                            </ServicesTaglineTextNormal>
                        </FlexBox>
                        <ServicesTaglineTextNormal>
                            scale your business to new heights.
                        </ServicesTaglineTextNormal>
                    </Container>
                    :
                    <Container>
                        <ServicesTaglineTextNormal>
                            We lie at the  perfect intersection to  scale your business to new heights.
                        </ServicesTaglineTextNormal>
                    </Container>
            }
        </FlexBoxSA>
    )
}
