import React from 'react'
import { FlexColumnSA } from '../style/global.styles'


export const Divider = (props) => {
    return (
        <>
            <FlexColumnSA>
                <div style={{
                    width: props.width,
                    height: props.height,
                    backgroundColor: props.color,
                    marginTop: props.marginTop,
                    marginBottom: props.marginBottom,
                    borderRadius: props.borderRadius
                }}
                />
            </FlexColumnSA>
        </>
    )
}