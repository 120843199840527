import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Stories } from '../../../assets/data/stories';
import { Navbar } from '../../../global/components/navigation/navbar';
import { Gradient } from "whatamesh";
import { StoryCardTitle, StoryCardTitlePosition, StoryDetailsContainer, StoryDetailsInfoWrapper, WorkMobBannerContainer, WorkWrapper } from '../style/stories.styles';
import '../style/stories.css'
import { ImgHolder } from '../../../global/components/imgHolder';
import { Layer } from '../../../global/components/layer';
import { Container, FlexBox, FlexBoxSA, FlexBoxSB } from '../../../global/style/global.styles';
import { StoryServiceBlock } from '../components/storyServiceBlock';
import { StoryLandingLink } from '../components/storyLandingLink';
import { StoryHighlights } from '../components/storyHighlights';
import { StoryContext } from '../components/storyContext';
import { StorySolution } from '../components/storySolution';
import { StoryOutcome } from '../components/storyOutcome';
import { StoryUSP } from '../components/storyUSP';
import { MasteryRecommend } from '../../mastery/components/masteryRecommend';
import { Footer } from '../../../global/components/footer';
import { motion } from 'framer-motion'
import { Grid } from '@mui/material';
import { SpacerTopBottomXXXL } from '../../../design/spacer/spacer';

const gradient = new Gradient();

export const Work = (props) => {

    const { workId } = useParams();

    const [workData, setWorkData] = useState([])

    useEffect(() => {
        setWorkData(Stories?.filter((item) => item.id === workId)[0])
    }, [Stories]);

    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            console.log(ref);
            gradient.initGradient("#gradient-canvas");
        }
    }, [ref]);

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    })
    const [cursorVariant, setCursorVariant] = useState("default")

    useEffect(() => {

        setWorkData(Stories?.filter((item) => item.id === workId)[0])

        const mouseMove = (e) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }

        window.addEventListener("mousemove", mouseMove);
        return () => {
            window.removeEventListener("mousemove", mouseMove)
        }

        setCursorVariant("default")
    }, [])

    const variants = {
        default: {
            x: mousePosition.x - 10,
            y: mousePosition.y - 10,
            mixBlendMode: "difference"
        },
        diffCursor: {
            width: 100,
            height: 100,
            x: mousePosition.x - 50,
            y: mousePosition.y - 50,
            backgroundColor: "white",
            mixBlendMode: "difference"
        },
        normalCursor: {
            width: 0,
            height: 0,
        }
    }

    const compEntered = () => setCursorVariant("diffCursor")
    const compLeave = () => setCursorVariant("default")

    return (
        <WorkWrapper ref={ref}>
            <div style={{
                position: 'fixed',
                top: 0,
                zIndex: -1,
                height: "100vh"
            }}>
                <canvas id="gradient-canvas" style={{ height: props.height }}></canvas>
            </div>
            <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
                <Navbar
                    color="black"
                    logoEntered={compEntered}
                    logoLeave={compLeave}
                    navBarEntered={compEntered}
                    navBarLeave={compLeave}
                />
            </div>
            <WorkMobBannerContainer>
                <Layer
                    shade={"rgba(0, 0, 0, 0.3)"}
                />
                <ImgHolder
                    img={props.width > 850 ? workData?.client?.banner.banner1500V2 : workData?.client?.banner.banner450}
                    width="100%"
                    height="100%"
                />
                <StoryCardTitlePosition>
                    <StoryCardTitle style={{ textAlign: "center" }}>
                        {workData?.name}
                    </StoryCardTitle>
                </StoryCardTitlePosition>
            </WorkMobBannerContainer>
            <StoryDetailsContainer>
                <StoryDetailsInfoWrapper>
                    <Container>
                        <FlexBoxSB style={{ display: props.width > 650 ? "flex" : "block" }}>
                            <Grid container>
                                {
                                    workData?.services?.map((item, index) => (
                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} key={index}>
                                            <FlexBox
                                                style={{ justifyContent: props.width > 650 ? "normal" : "space-around" }}>
                                                <StoryServiceBlock
                                                    mastery={item.mastery}
                                                    serviceName={item.serviceName}
                                                />
                                            </FlexBox>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            {
                                props.width < 650
                                &&
                                <SpacerTopBottomXXXL />
                            }
                            <StoryLandingLink
                                width={props.width}
                                href={workData?.landingPage}
                                target='_blank'
                                logo={workData?.client?.logo}
                                storyLinkEnter={compEntered}
                                storyLinkLeave={compLeave}
                            />
                        </FlexBoxSB>
                    </Container>
                    <FlexBoxSA>
                        <StoryHighlights
                            width={props.width}
                            data={workData?.story?.highlights}
                        />
                    </FlexBoxSA>
                    <StoryContext
                        width={props.width}
                        context={workData?.story?.context}
                        client={workData?.client}
                    />
                    <StorySolution
                        width={props.width}
                        data={workData?.story?.solution}
                    />
                    {
                        workData?.story?.outcome
                        &&
                        <StoryOutcome
                            width={props.width}
                            data={workData?.story?.outcome}
                        />
                    }
                    <StoryUSP
                        width={props.width}
                        data={workData?.story?.usp}
                    />
                    <MasteryRecommend
                        location={props.location}
                        title="Get to know how we do what we do!"
                        blockEntered={compEntered}
                        blockLeave={compLeave}
                    />
                    <Footer
                        variant="flipWhite"
                        footerEntered={compEntered}
                        footerLeave={compLeave}
                        backToTopBtnEntered={compEntered}
                        backToTopBtnLeave={compLeave}
                        inputFieldEntered={compEntered}
                        inputFieldLeft={compLeave}
                    />
                </StoryDetailsInfoWrapper>
            </StoryDetailsContainer>
            {
                props.width > 850
                &&
                <motion.div
                    style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        zIndex: 2,
                        pointerEvents: "none"
                    }}
                    variants={variants}
                    animate={cursorVariant}
                />
            }
        </WorkWrapper>
    )
}
