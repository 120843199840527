import { NavLink } from 'react-router-dom';
import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const Container = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const Squeezer = styled.div`
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const Sandwich = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};

    @media only screen and (min-width: 1800px) {
    }
`;

export const SandwichHalf = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
`;

export const SandwichRealHalf = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
`;

// ----------------------------------------TEXT----------------------------------------//


export const HeadlineSemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.headline};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};;
`;

export const HeadlineSemiBoldWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.headline};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubHeadlineSemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeadline};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};;
`;

export const Heading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const HeadingMedium = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const HeadingSemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const HeadingWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const HeadingMediumWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubHeadingSemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubHeadingSemiBoldWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubHeadingPrimary = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.primary.blue};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const Title = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const TitleMedium = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const TitleMediumCenter = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    text-align: center;
`;

export const TitleBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const HeadingBoldG20 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.grey20};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const TitleBoldG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const TitleSemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const TitleSemiBoldG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const TitleSemiBoldG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const TitleItalics = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-style: italic;
`;

export const SubTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const SubTitleItalics = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-style: italic;
`;

export const SubTitleWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const SubTitleBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const SubTitleMedium = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubTitleSemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubTitleSemiBoldG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubTitleMediumG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubTitleBoldG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const SubTitleMediumWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    letter-spacing: 0.02em;
`;

export const SubTitleG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const Body = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BodyG80 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BodySemiBoldG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const BodySemiBoldG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const BodyMediumG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BodyMedium = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BodyBoldG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const BodyBoldG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const BodyBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const BodyWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BodyMediumWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BodyMediumG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BodyG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const SubBody = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const SubBodyMediumG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubBodyMediumWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;


export const SubBodySemiBoldG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubBodySemiBoldG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubBodyBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const SubBodyBoldG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const SubBodyG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const SubBodyWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BodySemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const Caption = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const CaptionG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const CaptionWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const CaptionWhiteBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const CaptionWhiteMedium = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

// ----------------------------------------FLEX----------------------------------------//

export const FlexBoxSB = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FlexBoxSA = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const FlexBoxSE = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const FlexBox = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColumnSA = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const FlexColumnSB = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FlexColumnSE = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

// ----------------------------------------ALIGNMENT----------------------------------------//

export const AlignCenter = styled.div`
    text-align: center;
`;

// ----------------------------------------POSITION----------------------------------------//


export const AbsoluteCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

export const AbsoluteCenterBottom = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
`;

export const AbsoluteCenterTop = styled.div`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
`;

export const AbsoluteCenterLeft = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
`;

export const AbsoluteBottomRight = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
`;

export const AbsoluteTopRight = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const AbsoluteLogo = styled.div`
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%,-50%);
`;

export const RelativeContainer = styled.div`
    position: relative;
`;

// ----------------------------------------FORM----------------------------------------//

export const FormLabel = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 154%;
    letter-spacing: 0.04em;
`;

export const FormLabelInfo = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 154%;
    letter-spacing: 0.02em;
    color: #8B8B8B;
    font-style: italic;
`;

// export const FormInput = styled(Field)`
//     height: 32px;
//     border: 1px solid #D8D9D9;
//     padding: 2px 4px;
// `;

// export const FormError = styled(ErrorMessage)`
//     color: red;
//     font-size: ${(props) => props.theme.fontSizes.caption};
// `;

// ----------------------------------------LAYER----------------------------------------//

export const LayerWrapper = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
`;

// ----------------------------------------SCROLL----------------------------------------//

export const HorizontalScrollContainer = styled.div`
    overflow: auto;
    white-space: nowrap;
`;

// ----------------------------------------MAGNIFY----------------------------------------//

export const MagnifyIcon = styled.div`
    &:hover {
        transform: scale(1.08);
        transition: all 0.3s ease;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;

// ----------------------------------------FOOTER----------------------------------------//

export const FooterWrapper = styled.div`
    position: relative;
`;

export const FooterContainer = styled.div`
    padding-top: ${(props) => props.theme.space[8]};
    padding-bottom: ${(props) => props.theme.space[8]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    position: relative;
    overflow: hidden;
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[32]};
        padding-right: ${(props) => props.theme.space[32]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[6]};
        padding-right: ${(props) => props.theme.space[6]};
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const FooterItem = styled(NavLink)`
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes.title};
    text-decoration: none;

    &:hover {
        transition: all 0.3s ease;
        color: #099B78;
    }
    &:not(:hover) {
        transition: all 0.5s ease;
    }
`;

export const NewsletterText = styled.div`
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSizes.title};
    line-height: 149.5%;
`;

export const PacificoText = styled.div`
    font-family: 'Pacifico';
    font-size: ${(props) => props.theme.fontSizes.title};
    line-height: 149.5%;
`;

export const FooterOthersText = styled(NavLink)`
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes.subBody};
    line-height: 150%;
    letter-spacing: 0.04em;
    text-decoration: none;

    ${'' /* &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease;
        color: #9B096A;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    } */}

    @media only screen and (max-width: 850px) {
        font-size: ${(props) => props.theme.fontSizes.body};
    }
`;

export const FooterOutlinedText = styled.div`
    font-size: 180px;
    font-weight: 900;
    letter-spacing: 0.04em;

    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.headline};
    }
    @media only screen and (max-width: 850px) {
        font-size: 104px;
    }
    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.childHeadline};
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.subChildHeadline};
    }
    @media only screen and (max-width: 370px) {
        font-size: 56px;
    }
`;

export const FooterRotatedContainer = styled.div`
    transform: rotate(-0.25turn);
    display: flex;
    align-items: center;
`;

export const FooterRotatedContainerPosition = styled.div`
    position: absolute;
    bottom: 164px;
    right: 50px;

    @media only screen and (min-width: 1800px) {
        right: 135px;
    }
    @media only screen and (max-width: 1300px) {
        right: -20px;
    }
    @media only screen and (max-width: 1100px) {
        right: -50px;
    }
    @media only screen and (max-width: 850px) {
        right: -50px;
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 450px) {
        right: -65px;
    }
    @media only screen and (max-width: 370px) {
        right: -85px;
    }
`;

export const FooterYear = styled.div`
    font-size: 72px;
    font-weight: 700;
    letter-spacing: 0.04em;
`;

// ----------------------------------------Cursor----------------------------------------//

export const HeroSplineDragComp = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    padding: 16px;
`;

// ----------------------------------------BackToTop----------------------------------------//

export const BackToTopBtnContainer = styled.div`
    display: flex;
    justify-content: end;
    position: sticky;
    bottom: 40px;
    padding: 0 40px 0 0;
`;

// ----------------------------------------InfoEdge----------------------------------------//

export const InfoEdgePosition = styled.div`
    position: absolute;
`;

export const InfoEdgeWrapper = styled.div`
    position: relative;
`;

export const InfoEdgeContainer = styled.div`
    width: 300px;
    min-height: 120px;
    padding: 16px 20px 20px 64px;
    border-radius: 16px;
    background-color:  rgba(0, 0, 0, 0.3);
`;

export const SquarePosition = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
    height: 64px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
`;

export const InfoSquare = styled.div`
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

export const InfoMenuContainer = styled.div`
    position: absolute;
    left: 4px;
    top: 32%;
    transform: rotate(-0.25turn);
`;

// ----------------------------------------PerfectCirlce----------------------------------------//

export const PerfectCircleContainer = styled.div`
   border-radius: 50%;
   position: relative;
`;

// ----------------------------------------Loading Screen----------------------------------------//

export const LoadingScreenWrapper = styled.div`
   height: 100dvh;
   background: black;
   position: relative;
   z-index: 1;
`;

// ----------------------------------------Info3DElemets----------------------------------------//

export const Info3DElementsContainer = styled.div`
   width: 360px;
   height: 120px;
   background-color: rgba(194, 194, 194, 0.2);
   border-radius: 24px;
   padding: 24px;
`;

// ----------------------------------------Other pages----------------------------------------//

export const AttributionWrapper = styled.div`
    background-color: #e5e5f7;
    opacity: 0.8;
    background-image:  linear-gradient(135deg, #F0F0F0 25%, transparent 25%), linear-gradient(225deg, #F0F0F0 25%, transparent 25%), linear-gradient(45deg, #F0F0F0 25%, transparent 25%), linear-gradient(315deg, #F0F0F0 25%, #F5F5F9 25%);
    background-position:  10px 0, 10px 0, 0 0, 0 0;
    background-size: 10px 10px;
    background-repeat: repeat;
    min-height: 100vh;
`;



