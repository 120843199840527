import React from 'react'
import { MasteryBlockContainer, MasteryBlockTitle, MasteryBlockWrapper } from '../style/mastery.styles'
import { AbsoluteCenter } from '../../../global/style/global.styles'

export const MasteryBlock = (props) => {
  return (
    <MasteryBlockWrapper href={props.link} onMouseEnter={props.blockEntered} onMouseLeave={props.blockLeave}>
      <MasteryBlockContainer style={{
        backgroundImage: `url(${props.background})`
      }}>
        <AbsoluteCenter>
          <MasteryBlockTitle>
            {props.mastery}
          </MasteryBlockTitle>
        </AbsoluteCenter>
      </MasteryBlockContainer>
    </MasteryBlockWrapper>
  )
}
