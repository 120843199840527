import { Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Container, FlexBox, FlexColumn, RelativeContainer } from '../../../global/style/global.styles'
import { AboutHeroHScrollContainer, AboutHeroVideo, AboutMobTaglinePosition, AboutTagline, AboutTaglineContainer, AboutTaglinesPosition } from '../style/about.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { SpacerLeftRightMedium } from '../../../design/spacer/spacer'
import { Layer } from '../../../global/components/layer'
import aboutBgVideo from '../../../assets/videos/Ink in Water Background.mp4'
import TextTransition, { presets } from 'react-text-transition';

const TEXTS = ['Digital Studio', 'Technology Lab', 'Campaign Hub', 'Testing Facility'];

export const AboutHeroHScroll = (props) => {

    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            2500, // every 2.5 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <AboutHeroHScrollContainer>
            <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <RelativeContainer>
                        {/* <ImgHolder
                            img={aboutBulbBg}
                            width="100%"
                            height="100vh"
                        /> */}
                        <div style={{ width: "100%", height: "100vh" }}>
                            <AboutHeroVideo
                                ref={videoRef}
                                loop
                                autoPlay
                                muted
                                playsInline>
                                <source
                                    src={aboutBgVideo}
                                    type="video/mp4"
                                />
                            </AboutHeroVideo>
                        </div>
                        {
                            props.width < 850
                            &&
                            <>
                                <Layer
                                    shade="rgba(0, 0, 0, 0.3)"
                                />
                                <AboutMobTaglinePosition>
                                    <AboutTaglineContainer>
                                        <AboutTagline style={{ color: "white" }}>
                                            We are your
                                        </AboutTagline>
                                        <AboutTagline
                                            style={{
                                                fontSize: 64,
                                                fontWeight: 600,
                                                fontStyle: "italic",
                                                letterSpacing: "-0.04rem",
                                                color: "white"
                                            }}>
                                            <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
                                        </AboutTagline>
                                    </AboutTaglineContainer>
                                </AboutMobTaglinePosition>
                            </>
                        }
                    </RelativeContainer>
                </Grid>
                {
                    props.width > 850
                    &&
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{
                        backgroundColor: "#F0F0F0",
                        opacity: 0.8,
                        backgroundImage: "repeating-radial-gradient( circle at 0 0, transparent 0, #F0F0F0 10px ), repeating-linear-gradient( #FFFFFF, #F0F0F0 )"
                    }}>
                        <FlexColumn style={{ height: "100vh", justifyContent: "flex-end" }}>
                            <AboutTaglineContainer>
                                <AboutTagline>
                                    We are your
                                </AboutTagline>
                                <AboutTagline
                                    style={{
                                        fontSize: 80,
                                        fontWeight: 600,
                                        fontStyle: "italic",
                                        letterSpacing: "-0.04rem"
                                    }}>
                                    <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
                                </AboutTagline>
                            </AboutTaglineContainer>
                        </FlexColumn>
                    </Grid>
                }
            </Grid>
        </AboutHeroHScrollContainer>
    )
}
