import React from 'react'
import { StoryHeading, StoryPara } from '../style/stories.styles'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer'

export const StoryProblem = (props) => {
    return (
        <>
            {
                props.data?.statement?.map((item, index) => (
                    <StoryPara key={index}>
                        {item}
                    </StoryPara>
                ))
            }
        </>
    )
}
