import React from 'react'
import { Container, RelativeContainer, Sandwich, SandwichHalf, Squeezer } from '../../../global/style/global.styles'
import { MasterySectionTitle } from '../style/mastery.styles'
import { MarkMethodTile } from './markMethodTile'
import { SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import brandingAnim from '../../../assets/animations/branding.json'
import markResearchAnim from '../../../assets/animations/markResearch.json'
import optWebAnim from '../../../assets/animations/optWeb.json'
import digMarkAnim from '../../../assets/animations/DigMark.json'
import analysisAnim from '../../../assets/animations/analysis.json'
import contentStratAnim from '../../../assets/animations/contentStrat.json'
import { Grid } from '@mui/material'
import { MarkHeading } from './markHeading'

export const MarkMethod = (props) => {
    return (
        <>
            {/* <MasterySectionTitle>
                    Wondering what the ingredients are for our Marketing Methodology?
                </MasterySectionTitle> */}
            <RelativeContainer style={{ height: 89 }}>
                <MarkHeading
                    heading="Marketing Methodology"
                />
            </RelativeContainer>
            <SandwichHalf />
            <Squeezer>
                <Grid container>
                    <MarkMethodTile
                        tileAnim={brandingAnim}
                        animWidth={props.width > 450 ? 320 : "100%"}
                        bgColor={"rgba(208, 241, 255, 0.6)"}
                        titleColor={"#E0E5FF"}
                        title="BRANDING"
                    />
                    <MarkMethodTile
                        tileAnim={markResearchAnim}
                        animWidth={props.width > 450 ? 260 : "100%"}
                        bgColor={"rgba(255, 236, 208, 0.6)"}
                        titleColor={"#FFECD0"}
                        title="MARKET RESEARCH"
                    />
                    <MarkMethodTile
                        tileAnim={optWebAnim}
                        animWidth={props.width > 450 ? 355 : "100%"}
                        bgColor={"rgba(238, 239, 247, 0.6)"}
                        titleColor={"#EEEFF7"}
                        title="OPTIMIZE WEBSITE"
                    />
                    <MarkMethodTile
                        tileAnim={contentStratAnim}
                        animWidth={props.width > 450 ? 262 : "100%"}
                        bgColor={"rgba(209, 255, 208, 0.6)"}
                        titleColor={"#D1FFD0"}
                        title="CONTENT STRATEGY"
                    />
                    <MarkMethodTile
                        tileAnim={digMarkAnim}
                        animWidth={props.width > 450 ? 260 : "100%"}
                        bgColor={"rgba(240, 208, 255, 0.6)"}
                        titleColor={"#F8E9FF"}
                        title="DIGITAL MARKETING"
                    />
                    <MarkMethodTile
                        tileAnim={analysisAnim}
                        animWidth={props.width > 450 ? 260 : "100%"}
                        bgColor={"rgba(208, 255, 249, 0.6)"}
                        titleColor={"#D0FFF9"}
                        title="ANALYZE & ENHANCE"
                    />
                </Grid>
            </Squeezer>
        </>
    )
}
