import React from 'react'
import { LayerWrapper } from '../style/global.styles'

export const Layer = (props) => {
    return (
        <>
            <LayerWrapper
                style={{
                    backgroundColor: props.shade,
                    borderRadius: props.borderRadius,
                    zIndex: props.zIndex
                }}
            />
        </>
    )
}
