import React from 'react'
import { MobileAppServiceTitle, ServiceWrapper, ServicesWrapper, WebAppServiceTitle, WebsiteServiceTitle, WebsiteServiceWrapper } from '../style/services.styles'
import { Sandwich } from '../../../global/style/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import mobAppImg from '../../../assets/images/Component 57.png'
import { Layer } from '../../../global/components/layer'
import { ServiceLinkBtn } from './serviceLinkBtn'

export const MobileAppService = (props) => {
    return (
        <Sandwich>
            <ServiceWrapper style={{ height: props.width > 1100 ? 1330 : props.width > 650 ? 950 : 800 }}>
                <MobileAppServiceTitle
                    style={{
                        position: "absolute",
                        left: props.width > 1100 ? 112 : 40,
                        top: "50%",
                        transform: "translate(0, -50%)",
                        width: 488,
                        zIndex: 2
                    }}>
                    MOBILE
                </MobileAppServiceTitle>
                <MobileAppServiceTitle
                    style={{
                        position: "absolute",
                        left: props.width > 1100 ? 112 : 40,
                        top: props.width > 1100 ? "61.5%" : props.width > 850 ? "65%" : props.width > 650 ? "64%" : props.width > 450 ? "62%" : props.width > 370 ? "59.5%" : "58%",
                        transform: "translate(0, -50%)",
                        width: 350,
                        zIndex: props.width > 450 ? 0 : 2
                    }}>
                    APPS
                </MobileAppServiceTitle>
                <div style={{
                    position: "absolute",
                    right: props.width > 1300 ? -100 : props.width > 1100 ? -170 : props.width > 850 ? -240 : -320,
                    top: 0
                }}>
                    {/* <Layer
                        shade="rgba(255,255,255,0.2)"
                    /> */}
                    <ImgHolder
                        img={mobAppImg}
                        width={props.width > 1300 ? 1400 : props.width > 1100 ? 1200 : props.width > 650 ? 1000 : 850}
                    />
                </div>
                {/* <div style={{
                    position: "absolute",
                    left: 112,
                    top: 500
                }}>
                    <Layer
                        shade="rgba(255,255,255,0.2)"
                    />
                    <ImgHolder
                        img={webMock1}
                        width={931}
                        height={535}
                    />
                </div> */}
                <a
                    href='/mastery/development'
                    target='_blank'
                    style={{
                        position: "absolute",
                        right: props.width > 450 ? 112 : 40,
                        bottom: 0
                    }}
                    onMouseEnter={props.serviceLinkEntered}
                    onMouseLeave={props.serviceLinkLeave}>
                    <ServiceLinkBtn />
                </a>
            </ServiceWrapper>
        </Sandwich>
    )
}
