import { Grid } from '@mui/material'
import React from 'react'
import { MarkMethodTileContainer, MarkMethodTitle, MasterySectionSubTitle } from '../style/mastery.styles'
import Lottie from 'react-lottie'
import { FlexColumnSA, FlexColumnSB } from '../../../global/style/global.styles'

export const MarkMethodTile = (props) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: props.tileAnim,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <MarkMethodTileContainer style={{ backgroundColor: props.bgColor }}>
                <FlexColumnSB style={{ height: "100%" }}>
                    <div>
                        <Lottie
                            options={defaultOptions}
                            width={props.animWidth}
                        />
                    </div>
                    <FlexColumnSA style={{ height: "100%" }}>
                        <MarkMethodTitle style={{ color: props.titleColor }}>{props.title}</MarkMethodTitle>
                    </FlexColumnSA>
                </FlexColumnSB>
            </MarkMethodTileContainer>
            {/* <MarkMethodTileContainer style={{ backgroundColor: props.bgColor }}>
                <Grid container spacing={8} alignItems="center">
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Lottie
                            options={defaultOptions}
                            width={props.animWidth}
                        />
                    </Grid>
                    <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                        <MarkMethodTitle  style={{ color: props.titleColor }}>{props.title}</MarkMethodTitle>
                        <MasterySectionSubTitle style={{ lineHeight: "32px" }}>
                            {props.description}
                        </MasterySectionSubTitle>
                    </Grid>
                </Grid>
            </MarkMethodTileContainer> */}
        </Grid>
    )
}
