import styled from 'styled-components'
import markTitleBg from '../../../assets/images/markTitleBg.png'

export const MasteryWrapper = styled.div`
    position: relative;
    overflow-x: hidden;
`;

export const MasteryBgContainer = styled.div`
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
`;

export const MasteryHeadingContainer = styled.div`
    width: 1286px;
    height: 420px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 480px;
    left: -20%;
    margin-top: ${(props) => props.theme.space[8]};
    margin-bottom: ${(props) => props.theme.space[8]};

    @media only screen and (max-width: 1300px) {
        left: -35%;
    }
    @media only screen and (max-width: 1100px) {
        width: 900px;
        height: 300px;
        left: -20%;
    }
    @media only screen and (max-width: 850px) {
        left: -35%;
    }
    @media only screen and (max-width: 650px) {
        width: 620px;
        height: 200px;
        margin-bottom: 0;
    }
    @media only screen and (max-width: 450px) {
        left: -75%;
    }
    @media only screen and (max-width: 370px) {
        width: 520px;
        height: 160px;
    }
`;

export const MasteryHeadingTextPosition = styled.div`
    position: absolute;
    right: 14%;
    top: 50%;
    transform: translate(0, -50%);
`;

export const MasteryHeadingText = styled.div`
    font-size: 88px;
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.extraLight};
    letter-spacing: -0.02em;

    @media only screen and (max-width: 1100px) {
        font-size: 64px;
    }
    @media only screen and (max-width: 650px) {
        font-size: 48px;
    }
    @media only screen and (max-width: 450px) {
        font-size: 40px;
    }
    @media only screen and (max-width: 370px) {
        font-size: 32px;
    }
`;

export const MasteryBlockWrapper = styled.a`
    text-decoration: none;
`;

export const MasteryBlockContainer = styled.div`
    border-radius: 8px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    width: 320px;
    height: 180px;
`;

export const MasteryBlockTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: rgba(255, 255, 255, 0.8);
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const MasteryVideo = styled.video`
    position: relative;
    object-fit: cover;
    width: 300px;
    height: 300px;
    border-radius: 4px;
`;

export const MasterySectionTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    letter-spacing: 0.02em;
    line-Height: 48px;

    @media only screen and (max-width: 850px) {
        font-size: ${(props) => props.theme.fontSizes.title};
        line-height: 40px;
    }
`;

export const MasterySectionMinititle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    letter-spacing: 0.02em;
    line-Height: 40px;

    @media only screen and (max-width: 850px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
        line-height: 32px;
    }
   
`;

export const MasterySectionSubTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.light};
    letter-spacing: 0.02em;
    line-Height: 40px;

    @media only screen and (max-width: 850px) {
        font-size: 20px;
        line-height: 38px;
    }
`;

export const MasteryFeatureBlock = styled.div`
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.6); 
    padding: 16px;
`;

export const MarkMethodTileContainer = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[4]};
    padding-right: ${(props) => props.theme.space[4]};
    height: 500px;

    ${'' /* &:hover {
        transform: scale(1.04);
        transition: all 0.5s ease;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }  */}
`;

export const MarkMethodTitle = styled.div`
    font-family: 'Roboto Condensed', sans-serif;    
    font-size: 80px;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    letter-spacing: -0.02em;

    @media only screen and (max-width: 450px) {
        font-size: 56px;
    }
`;

export const MarkHeadingWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

export const MarkHeadingContainer = styled.div`
    padding: 30px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-image: url(${markTitleBg});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const MasterySectionMartTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.light};
    letter-spacing: 0.02em;
`;
