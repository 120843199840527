import React, { useEffect, useRef } from 'react'
import { AlignCenter, Container, FlexBox, FlexBoxSA, Sandwich, SandwichHalf } from '../../../global/style/global.styles'
import { MasterySectionSubTitle, MasterySectionTitle, MasteryVideo } from '../style/mastery.styles'
import { SpacerLeftRightLarge, SpacerTopBottomLarge, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { ImgHolder } from '../../../global/components/imgHolder'

export const PrototypingSection = (props) => {

  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  })

  return (
    <>
      <Container>
        <div style={{ textAlign: props.width > 850 ? "center" : "left" }}>
          <MasterySectionTitle>
            Prototyping,
            <br />
            A Tangible Preview of the Final Act
          </MasterySectionTitle>
          <Sandwich>
            <FlexBoxSA>
              <MasteryVideo
                ref={videoRef}
                loop
                autoPlay
                muted
                playsInline
                style={{ width: props.width > 850 ? "70%" : "100%", height: "100%" }}>
                <source
                  src={"https://res.cloudinary.com/dz3yljetu/video/upload/v1707805347/uxTrends_m8ehnd.mp4"}
                  type="video/mp4"
                />
              </MasteryVideo>
              {/* <div
                style={{
                  backgroundColor: "red",
                  width: 800,
                  height: 500
                }}
              /> */}
            </FlexBoxSA>
          </Sandwich>
          <FlexBox style={{ justifyContent: props.width > 850 ? "space-around" : "normal" }}>
            <MasterySectionSubTitle style={{ width: props.width > 850 ? "70%" : "100%", textAlign: props.width > 850 ? "justify" : "left" }}>

              Prototyping in UX design serves as the dynamic catalyst that metamorphoses conceptual ideas into vibrant, interactive digital experiences. Beyond a mere blueprint, a prototype emerges as a virtual arena where ideas materialize, and user interactions come to life. This transformative stage marks the evolution of wireframes into compelling narratives, with user experiences meticulously honed to perfection.
              <br />
              Contrary to a rigid monolith, a prototype acts as a flexible canvas for exploration and refinement. Stakeholders actively engage, providing real-time feedback grounded in a tangible experience. This iterative collaboration ensures that the final product aligns seamlessly with both user expectations and strategic objectives.
              <br />
              The prototyping phase becomes the locus where creativity harmoniously converges with functionality. Designers engage in a meticulous refinement process, molding their digital symphony until it strikes a resonant chord with the intended audience. In essence, prototyping becomes the strategic crucible where innovative ideas are fused with practical functionality, crafting a digital experience that seamlessly aligns with both user needs and overarching project goals.
            </MasterySectionSubTitle>
          </FlexBox>
        </div>
      </Container>
    </>
  )
}
