import React from 'react'
import { Container } from '../../../global/style/global.styles'
import { StoryHeading, StoryPara } from '../style/stories.styles'
import { SpacerTopBottomLarge, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { UspBlock } from './uspBlock'

export const StoryUSP = (props) => {

  return (
    <>
      <Container>
        <StoryHeading>
          OUR UNIQUE VALUE PROPOSITION
        </StoryHeading>
        <SpacerTopBottomXL />
        {
          props.data?.map((item, index) => (
            <div key={index}>
              <UspBlock
                width={props.width}
                title={item.title}
                content={item.content}
                icon={item.icon}
              />
              {
                props.width > 850
                  ?
                  props.data.length !== index + 1
                  &&
                  <SpacerTopBottomXL />
                  :
                  props.data.length !== index + 1
                  &&
                  <SpacerTopBottomXXXL />
              }
            </div>
          ))
        }
      </Container>
    </>
  )
}
