import React, { useEffect, useState } from 'react'
import { MasteryBgContainer, MasteryWrapper } from './style/mastery.styles'
import { ImgHolder } from '../../global/components/imgHolder'
import { motion } from 'framer-motion'
import ScrollToTop from '../../global/components/scrollToTop'
import { Navbar } from '../../global/components/navigation/navbar'
import { MasteryHeading } from './components/masteryHeading'
import markBg from '../../assets/images/marketingTitleBg.png'
import { MasteryRecommend } from './components/masteryRecommend'
import { Footer } from '../../global/components/footer'
import { MarkMethod } from './components/markMethod'
import { CustPersona } from './components/custPersona'
import { TargetAudience } from './components/targetAudience'
import { MarkAnalytics } from './components/markAnalytics'
import { Sandwich, SandwichHalf } from '../../global/style/global.styles'
import { ServicesContact } from '../services/components/servicesContact'
import masteryBg from "../../assets/images/masteryBg.png"
import masteryBgMob from "../../assets/images/masteryBgMob.png"
import { Helmet } from 'react-helmet'

export const Marketing = (props) => {

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorVariant, setCursorVariant] = useState("default")

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove)
    }

    setCursorVariant("default")
  }, [])

  const variants = {
    default: {
      x: mousePosition.x - 10,
      y: mousePosition.y - 10,
      mixBlendMode: "difference"
    },
    diffCursor: {
      width: 100,
      height: 100,
      x: mousePosition.x - 50,
      y: mousePosition.y - 50,
      backgroundColor: "white",
      mixBlendMode: "difference"
    },
    normalCursor: {
      width: 0,
      height: 0,
    }
  }

  const compEntered = () => setCursorVariant("diffCursor")
  const compLeave = () => setCursorVariant("default")

  return (
    <>
      <Helmet>
        <meta name="description" content="Transform your brand's digital presence with comprehensive marketing solutions. From strategic branding to targeted SEO, SEM, and social media marketing, our expert team crafts tailored strategies for maximum online visibility and engagement, driving your business to new heights in the digital realm." />
        <title>Inspirise | Impactful Marketing</title>
      </Helmet>
    <MasteryWrapper>
      <ScrollToTop />
      <MasteryBgContainer>
        <ImgHolder
          img={props.width > 850 ? masteryBg : masteryBgMob}
          width="100%"
          height="100%"
        />
      </MasteryBgContainer>
      <Navbar
        color="white"
        logoEntered={compEntered}
        logoLeave={compLeave}
        navBarEntered={compEntered}
        navBarLeave={compLeave}
      />
      <MasteryHeading
        heading="MARKETING"
        background={`url(${markBg})`}
      />
      <SandwichHalf />
      <MarkMethod
        width={props.width}
      />
      <Sandwich />
      <CustPersona
        width={props.width}
      />
      <Sandwich />
      <TargetAudience
        width={props.width}
      />
      <Sandwich />
      {/* <MarkAnalytics />
      <Sandwich /> */}
      <ServicesContact
        width={props.width}
        location={props.location}
        variant="filled"
        color="white"
        inputFieldEntered={compEntered}
        inputFieldLeft={compLeave}
      />
      <MasteryRecommend
        location={props.location}
        title="You may also be interested in"
        blockEntered={compEntered}
        blockLeave={compLeave}
      />
      <Footer
        variant="flipWhite"
        footerEntered={compEntered}
        footerLeave={compLeave}
        backToTopBtnEntered={compEntered}
        backToTopBtnLeave={compLeave}
        inputFieldEntered={compEntered}
        inputFieldLeft={compLeave}
      />
      {
        props.width > 850
        &&
        <motion.div
          style={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: "white",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 2,
            pointerEvents: "none"
          }}
          variants={variants}
          animate={cursorVariant}
        />
      }
    </MasteryWrapper>
    </>
  )
}
