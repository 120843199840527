import React from 'react'
import { Container } from '../../../global/style/global.styles'
import { BlogHeading, BlogSectionContainer, BlogSectionWrapper } from '../style/landing.styles'
import { FeaturedBlogSlider } from './featuredBlogSlider'

export const BlogSection = (props) => {
    return (
        <BlogSectionWrapper>
            <BlogSectionContainer>
                <BlogHeading>
                    BLOG
                </BlogHeading>
            </BlogSectionContainer>
            <FeaturedBlogSlider
                content={props.content}
                location={props.location}
                blogPostEntered={props.blogPostEntered}
                blogPostLeave={props.blogPostLeave}
            />
        </BlogSectionWrapper>
    )
}
